import React, { useState, useEffect } from "react";
import "./TemplateFields.css";
import Cookies from "js-cookie";
import { useDrop } from 'react-dnd';
import TextTemplate from './Fields-templates/TextTemplate';
import ParagraphTemplate from './Fields-templates/ParagraphTemplate';
import NumberTemplate from './Fields-templates/NumberTemplate';
import DateTemplate from './Fields-templates/DateTemplate';
import DateRangeTemplate from './Fields-templates/DateRangeTemplate';
import PersonTemplate from './Fields-templates/PersonTemplate';
import TeamTemplate from './Fields-templates/TeamTemplate';
import SingleSelectTemplate from './Fields-templates/SingleSelectTemplate';
import MultiSelectTemplate from './Fields-templates/MultiSelectTemplate';
import LinkTemplate from './Fields-templates/LinkTemplate';
import axios from "axios";
import { BASE_URL, ASSET_PREFIX_URL } from "../../constants/config";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import Select from 'react-select'
import { toast } from "react-toastify";
import TemplateItem from "./TemplateItem";
import DraggableItem from "./DraggableItem";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import TemplateConfirmPopUp from "./TemplateConfirm/TemplateConfirmPopUp";
import PoTableCustomFields from "./PoTableCustomFields";
import PoTableFields from "../PopUpsFolder/PoTableFields";
import UploadExcellSheet from "./UploadExcellSheet";
import { textLettersLimit } from "../../constants/helper";

function TemplatePage({ toastStyle, type }) {
  const [rotation, setRotation] = useState(0);
  const [mandatoryrotation, setMandatoryRotation] = useState(0);
  const [isDroppingMandatoryOpen, setIsDroppingMandatoryOpen] = useState(false);
  const [isDroppingCustomOpen, setIsDroppingCustomOpen] = useState(true);
  const [droppedElements, setDroppedElements] = useState([]);
  const [tableFields, setTableFields] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [close, setClose] = useState(false);
  const [selectClose, setSelectClose] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [fieldData, setFieldData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [elementDetails, setElementDetails] = useState([]);
  const [isTextTemplateVisible, setIsTextTemplateVisible] = useState(true);
  const [isDroppedActive, setIsDroppedActive] = useState(true);
  const [saveButton, setSaveButton] = useState(true);
  const [version, setVersion] = useState([]);
  const [versionNumber, setVersionNumber] = useState(false);
  const [templateEdit, setTemplateEdit] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const { templateId } = useParams();
  const [versionDefault, setVersionDefault] = useState(0);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [droppedCustom, setDroppedCustom] = useState("");
  const [openAlert, setOpenAlert] = useState(false)
  const [openTableFields, setOpenTableFields] = useState(false)
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedApprover, setSelectedApprover] = useState({});
  const [editorContent, setEditorContent] = useState('');
  const [isAdmin, setIsAdmin] = useState('')
  const [termsAndCondText, setTermsAndCondText] = useState('')
  const [approvarMember, setApproverMember] = useState({})

  const handleOpenTableFields = () => {
    setOpenTableFields(!openTableFields)
  }
  const handleSaveTableFields = () => {
    setSaveButton(false)
  }

  const nav = useNavigate();

  useEffect(() => {

    fetchTemplateFields();
  }, [excelData]);
  const fetchTemplateFields = async () => {
    let requestBody = {
      template_id: templateId,
      version: versionDefault, // Include versionDefault in the request body
    };
    // console.log(requestBody)
    try {
      setLoading(true);
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      let response
      if (type === 'template') {
        response = await axios.post(
          `${BASE_URL}/v1/template/get-templates`,
          requestBody,
          { headers }
        );
      } else if (type === 'ec-template') {
        response = await axios.post(
          `${BASE_URL}/v1/ec-template/get-ec-templates`,
          requestBody,
          { headers }
        );
      } else if (type === 'supply-template') {
        response = await axios.post(
          `${BASE_URL}/v1/supply-chain/get-supply-chain-details`,
          requestBody,
          { headers }
        );
      } else if (type === 'po-template') {
        response = await axios.post(
          `${BASE_URL}/v1/purchase-order/get-purchase-order-details`,
          requestBody,
          { headers }
        );
      } else if (type === 'assets-template') {
        response = await axios.post(
          `${BASE_URL}/v1/asset/get-asset-details`,
          requestBody,
          { headers }
        );
      }else if (type === 'pc-template') {
        response = await axios.post(
          `${BASE_URL}/v1/prod-change/get-prod-change-details`,
          requestBody,
          { headers }
        );
      }
      
      // const uniqueVersions = Array.from(new Set(response.data.data.template_fields.map(element => element.version)));
      const uniqueVersionsr = Array.from(
        new Set(
          response.data.data.template_fields
            .filter((element) => element.is_default_template === true)
            .map((element) => element.version)
        )
      );
      setIsAdmin(response.data.data.is_admin)
      const lastElement = uniqueVersionsr[uniqueVersionsr.length - 1];

      setVersionDefault(lastElement)
      // const customFilters = response.data.data.template_fields.filter(item => item.is_default === false)
      if(type === 'assets-template'){
        setTemplateName(response.data.data.template_details.asset_template_name);
      }else  if(type === 'pc-template'){
        setTemplateName(response.data.data.template_details.template_name);
      }else{
        setTemplateName(response.data.data.template_details.templateName);
      }
    
      const defaultsFields = [];
      const defaultTableFields = [];
      const customTableFields = [];
      const customFields = [];
      const approverDetails = [];
      const termsAndConditions = [];
      const formElement = response.data.data.form_elements;
      const elementsLength = response.data.data.template_fields
      const customCount = elementsLength.filter(element => element.is_default === false).length;

      setDroppedCustom(customCount)
      // setDroppedCustom(formElement)
      console.log(excelData)
      response.data.data.template_fields.map((element) => {
        if (element.is_default) {
          if (type !== 'po-template' ) {
            defaultsFields.push(element);
          } else {
            console.log('hi')
            // Check if the element's template_type is 'purchaseTemplate'
            if (element.template_type === 'purchaseTemplate') {
              defaultsFields.push(element);
            } else if (element.template_type === 'poItems') {
              defaultTableFields.push(element);
            }
          }
        } else if (element.is_default_template === true) {
          const foundElement = formElement.find(
            (item) => item.inputType === element.inputType
          );
          if (type !== 'po-template' ) {
            customFields.push({
              ...foundElement,
              ...element,
              displayType: element.fieldName,
            });
           
          } else {
           
            // Check if the element's template_type is 'purchaseTemplate'
            if (element.template_type === 'purchaseTemplate' && element.template_type !== 'approvermember' &&
              element.template_type !== 'poItems' && element.template_type !== 'tandc'
            ) {
              customFields.push({
                ...foundElement,
                ...element,
                displayType: element.fieldName,
              });
            } else if (element.template_type === 'poItems') {
              customTableFields.push({
                ...foundElement,
                ...element,
                displayType: element.fieldName,
              });
            } else if (element.template_type === 'approvermember') {
              approverDetails.push({
                ...foundElement,
                ...element,
                displayType: element.fieldName,
              })
            } else if (element.template_type === 'tandc') {
              termsAndConditions.push({
                ...foundElement,
                ...element,
                displayType: element.fieldName,
              })
            }
          }
        }
      });



      setDroppedElements(excelData?excelData:customFields);


      // setVersionDefault(Array.from(new Set(response.data.data.template_fields.map(element => element.version))));

      // Now 'lastElement' contains the last element of the array
      if (type === 'po-template' ) {
        
        console.log(lastElement);
        if(!lastElement || lastElement === 0){
          setTableFields(defaultTableFields);
        }
        if (lastElement) {
          setTableFields(customTableFields)
          setApproverMember(approverDetails[0]?.value ?approverDetails[0]?.value:{});
          setTermsAndCondText(termsAndConditions[0]?.value ? termsAndConditions[0].value : '');
        }
      }

      setElementDetails(defaultsFields);
      setFieldData(formElement);


      const formattedVersionsSet = new Set(response.data.data.uniqueVersions.map(version => version));

      // Filter out 'undefined', 'null', and 'versiondefault'
      const filteredVersionsSet = new Set(
        [...formattedVersionsSet].filter((version) => version !== undefined && version !== null)
      );

      const formattedVersions = Array.from(filteredVersionsSet).map(version => ({
        value: version,
        label: `Version ${version}`,
      }));



      setVersion(formattedVersions)

      setLoading(false);
      // setIsOpen(true)
      // setSteps(TourSteps)
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };
  useEffect(() => {
    fetchDepartments()
  }, [])

  const fetchDepartments = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/depart/get-departments", { headers });
      const departments = response.data.data.departments;
      console.log(response)
      setDepartmentList(departments);
      //   fillInputSetsWithDepartments(departments);
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };
  const handleVersionChange = async (selectedOption) => {
    setVersionNumber(true);
    setVersionDefault(selectedOption.value);
    try {
      setLoading(true)
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      let response
      if (type === 'template') {
        response = await axios.post(
          `${BASE_URL}/v1/template/get-templates`,
          { template_id: templateId, version: selectedOption.value },
          { headers }
        );
      } else if (type === 'ec-template') {
        response = await axios.post(
          `${BASE_URL}/v1/ec-template/get-ec-templates`,
          { template_id: templateId, version: selectedOption.value },
          { headers }
        );
      } else if (type === 'supply-template') {
        response = await axios.post(
          `${BASE_URL}/v1/supply-chain/get-supply-chain-details`,
          { template_id: templateId, version: selectedOption.value },
          { headers }
        );
      } else if (type === 'po-template') {
        response = await axios.post(
          `${BASE_URL}/v1/purchase-order/get-purchase-order-details`,
          { template_id: templateId, version: selectedOption.value },
          { headers }
        );
      } else if (type === 'assets-template') {
        response = await axios.post(
          `${BASE_URL}/v1/asset/get-asset-details`,
          { template_id: templateId, version: selectedOption.value },
          { headers }
        );
      }else if (type === 'pc-template') {
        response = await axios.post(
          `${BASE_URL}/v1/prod-change/get-prod-change-details`,
          { template_id: templateId, version: selectedOption.value },
          { headers }
        );
      }
    
      const formElement = response.data.data.form_elements;
      // const defaultsFields = [];
      const customFields = [];
      const approverDetails = [];
      const termsAndConditions = [];
      if (type === 'po-template' ) {
      response.data.data.template_fields
        .filter((element) => element.template_type === 'purchaseTemplate' && element.is_default === false)
        .map((element) => {
          if (element.version === selectedOption.value) {
            const foundElement = formElement.find(
              (item) => item.inputType === element.inputType
            );
            customFields.push({
              ...foundElement,
              ...element,
              displayType: element.fieldName,
            });
          }
        });
      }else{
        response.data.data.template_fields
        .filter((element) =>  element.is_default === false)
        .map((element) => {
          if (element.version === selectedOption.value) {
            const foundElement = formElement.find(
              (item) => item.inputType === element.inputType
            );
            customFields.push({
              ...foundElement,
              ...element,
              displayType: element.fieldName,
            });
          }
        });
      }

      setDroppedElements(excelData?excelData:customFields);
      if (type === 'po-template' ) {
        response.data.data.template_fields
          .filter((element) => element.template_type === 'approvermember' && element.is_default === false)
          .map((element) => {
            if (element.version === selectedOption.value) {
              const foundElement = formElement.find(
                (item) => item.inputType === element.inputType
              );
              approverDetails.push({
                ...foundElement,
                ...element,
                displayType: element.fieldName,
              });
            }
          });

        response.data.data.template_fields
          .filter((element) => element.template_type === 'tandc' && element.is_default === false)
          .map((element) => {
            if (element.version === selectedOption.value) {
              const foundElement = formElement.find(
                (item) => item.inputType === element.inputType
              );
              termsAndConditions.push({
                ...foundElement,
                ...element,
                displayType: element.fieldName,
              });
            }
          });

        setApproverMember(approverDetails[0]?.value);
        setTermsAndCondText(termsAndConditions[0]?.value ? termsAndConditions[0].value : '');
      }


      setFieldData(formElement);
      setLoading(false)
    }

    catch (error) {
      console.error("Error fetching options data:", error);
    }
    setSelectedVersion(selectedOption.value)

  };

  const handleItemClick = (item, index) => {

    if (isAdmin === true) {
      setVersionNumber(false)


      setSelectedItem(item);
      setIsActive(!isActive);
      setClose(!close);
      setSelectClose(!selectClose);
      setIsTextTemplateVisible(true);
      setIsDroppedActive(false)
      setActiveIndex((prevActiveIndex) =>
        prevActiveIndex === index ? -1 : index
      );
    }


  };



  const [, dropRef] = useDrop({
    accept: "FORM_ELEMENT",
    drop: (item, monitor) => {
      handleDrop(item);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });



  const handleDrop = (item) => {
    // console.log(handleIndex)
    if (isAdmin === true) {
      setVersionNumber(false)

      const index = 0

      setSaveButton(false)

      setSelectedItem(item);
      setIsActive(!isActive);
      setClose(!close);
      setSelectClose(!selectClose);
      setIsTextTemplateVisible(true);
      setActiveIndex(index)
      setDroppedElements((prevElements) => {
        const newElements = [...prevElements];
        newElements.splice(index, 0, item); // Insert item at the provided index
        return newElements;
      });
    }

  };

  const handleMandatoryContainerClick = () => {
    setIsDroppingMandatoryOpen((prevIsOpen) => !prevIsOpen);
    setMandatoryRotation((prevRotation) => (prevRotation === 180 ? 0 : 180));
  };

  const handleCustomClick = () => {
    setIsDroppingCustomOpen((prevIsOpen) => !prevIsOpen);
    setRotation((prevRotation) => (prevRotation === 180 ? 0 : 180));
  };






  const handleItemDelete = (item, index) => {
    setDroppedElements(
      droppedElements.filter((elem, i) => elem.id !== item.id)
    );
    setSaveButton(false);
  };

  const handleTemplateUpdate = (data) => {
    const updatedElements = droppedElements.map((element) =>
      element.id === selectedItem.id
        ? { ...element, ...data, displayType: data.fieldName }
        : element
    );
    setDroppedElements(updatedElements);
    setSaveButton(false);
  };

  // const handelVrsionNumber = ()=>{
  //   // console.log(versionNumber)
  //   setVersionDefault(versionNumber)
  // }
  // const handleOpenAlert = () => {
  //   if (droppedElements.length < 1) {
  //     setOpenAlert(false)
  //   } else {
  //     setOpenAlert(true)
  //   }
  // }
  const handlecloseAlert = () => {
    setOpenAlert(false)

  }

  const handleSaveData = async (versionDefault, action) => {
    try {
      const headers = {
        'x-auth-token': Cookies.get('token')
      };
      let droppedElementsWithOrder;
      let customTableFields;
      let departmentsData;
      let combinedArray;

      if (type !== 'po-template' ) {
        droppedElementsWithOrder = droppedElements
          .filter(item => item.fieldName) // Filter out items without fieldName
          .map((item, index) => ({
            ...item,
            order:elementDetails.length+ index + 1
          }));
      } else {
        customTableFields = tableFields.filter(item => item.is_default === false)
          .map((item, index) => ({
            ...item,
            template_type: 'poItems',
            order: index + 1
          }));
        departmentsData = departmentList;
        droppedElementsWithOrder = droppedElements
          .filter(item => item.fieldName) // Filter out items without fieldName
          .map((item, index) => ({
            ...item,
            template_type: 'purchaseTemplate',
            order: index + 1
          }));

        const editorText = {
          value: termsAndCondText,
          is_default: false,
          is_default_template: true,
          inputType: 'tandc',
          fieldName: 'Terms and conditions',
          options: [],
          template_type: 'tandc',
          order: 1  // Adjust the order as needed
        };

        const approverDetails = {
          value: approvarMember,
          is_default: true,
          is_default_template: true,
          inputType: 'person',
          fieldName: 'select approver',
          options: [],
          template_type: 'approvermember',
          order: 1  // Adjust the order as needed
        };

        combinedArray = [ ...droppedElementsWithOrder,...tableFields, approverDetails, editorText];
      }

      let jsonData;
      if (type !== 'po-template' ) {
        jsonData = JSON.stringify(droppedElementsWithOrder);
      } else {
        jsonData = JSON.stringify(combinedArray);
      }
      console.log(droppedElementsWithOrder);

      let selectedVersionNumber;
      if (action === 'default') {
        selectedVersionNumber = versionDefault ? versionDefault : 0;
      } else {
        selectedVersionNumber = selectedVersion ? selectedVersion : versionDefault;
      }

      const postData = {
        template_id: templateId,
        data: jsonData,
        templateName,
        selectedVersion: selectedVersionNumber,
        action
      };
      console.log(jsonData);

      if (type === 'template') {
        await axios.post(
          `${BASE_URL}/v1/template/save-custom`,
          postData,
          { headers }
        );
        nav("/template");
      } else if (type === 'ec-template') {
        await axios.post(
          `${BASE_URL}/v1/ec-template/save-ec-custom`,
          postData,
          { headers }
        );
        nav("/ec-template");
      } else if (type === 'supply-template') {
        await axios.post(
          `${BASE_URL}/v1/supply-chain/save-custom`,
          postData,
          { headers }
        );
        nav("/supply-chain-templates");
      } else if (type === 'po-template' ) {
        await axios.post(
          `${BASE_URL}/v1/purchase-order/save-custom`,
          postData,
          { headers }
        );
        nav("/purchase-order-templates");
      } else if (type === 'assets-template' ) {
        await axios.post(
          `${BASE_URL}/v1/asset/save-custom`,
          postData,
          { headers }
        );
        nav("/assets-template");
      }else if (type === 'pc-template' ) {
        await axios.post(
          `${BASE_URL}/v1/prod-change/save-custom`,
          postData,
          { headers }
        );
        nav("/pc-templates");
      }
      // if (excelData) {
      //   // Process and save excelData as needed
      //   console.log("Saving imported data:", excelData);
      // }
      // saveAsExcel((type !== 'po-template' && type !== 'assets-template')?droppedElementsWithOrder:combinedArray);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };


  const handleImport = (data) => {
    console.log("Imported Excel Data:", data);
    setExcelData(data); // Store the imported data in state or process it further
  };
  const saveAsExcel = (data) => {
    try {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(blob, 'data.xlsx');
    } catch (error) {
      console.error("Error generating Excel file:", error.message);
    }
  };
  

  const savingFunction = async (versionDefault) => {
    if (droppedCustom < 1) {

      handleSaveData(versionDefault, 'default')


    } else {
      setOpenAlert(true);
    }
  };

 



  const HandleNoChanges = () => {

    // if (droppedElements.length < 1) {
    //   toast.info("Please add fields to save the template.", toastStyle)
    // }
    if (!droppedElements.every(el => el.hasOwnProperty('fieldName'))) {
      toast.info("Please add text or remove the field.", toastStyle)
    } else {
      toast.info("Please make any changes to save the data.", toastStyle)
    }

  }

  const handleBack = () => {
    // if (type === 'template') {
    //   nav("/template");
    // } else {
    //   nav("/ec-template")
    // }
    nav(-1)

  };
  // const containsAlphanumeric = /^(?=.*[a-zA-Z])\d*[a-zA-Z][a-zA-Z\d]*$/;
  const containsAlphanumeric = /^[A-Za-z][A-Za-z0-9_ ]*$/;

  const fieldNameArray = droppedElements.map(item => item.fieldName);
  let defaultFieldsArray
  if (type !== 'po-template' ) {
    defaultFieldsArray = elementDetails.map(item => item.fieldName.toLowerCase()) || elementDetails.map(item => item.fieldName.toLowerCase());
  } else {
    const filteredElements = elementDetails.filter(item => item.template_type === 'purchaseTemplate');
    defaultFieldsArray = filteredElements.map(item => item.fieldName.toLowerCase()) || elementDetails.map(item => item.fieldName.toLowerCase());
  }


  const noFieldNameError = 'Field name cannot be empty '
  const startsWithAlphaError = 'Field should start with alphabet'
  const uniqueFieldNameError = 'Field name should be unique'
  const uniqueError = 'Field name already in use. Please choose another name.'
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "30px",
      border: "1px solid #777879",
      // padding: "10px 10px",
      boxSizing: "border-box",
      height: "40px",
      width: "200px",
    }),
  };

  // const handleNoDefault = () => {
  //   toast.info('please choose any version to make default', toast)
  // }

  const handleEditTemplate = () => {
    setTemplateEdit(!templateEdit)

  }

  const handleChangeEdit = (e) => {
    setTemplateName(e.target.value)
  }

  const handleSaveTemplateName = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };

      // Replace this with the new template name you want to save

      await axios.post(
        `${BASE_URL}/v1/template/edit-template-name`,
        // Include the new name in the request body{}
        { templateId, templateName, type },
        { headers: headers }
      );
      setTemplateEdit(false)
      console.log("Template name updated successfully");
    } catch (error) {
      console.error("Error updating template name:", error);
    }
  }

  return (
    <>
      {/* <TicketTemplateTopNav /> */}
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="template-home">
            <div className="template-page">
              <div className="template-top-bar">
                <div className="template-top-right">
                  <div className="template-title">
                    <div className="template-back-icon">
                      <img
                        src={`${ASSET_PREFIX_URL}template-back-btn.svg`}
                        alt=""
                        width="24px"
                        height="24px"
                        onClick={handleBack}
                      />
                      <div className="template-title-ver-line"></div>
                    </div>
                    <div data-tour="step-4" className="template-title-div">
                      {!templateEdit && <span>{textLettersLimit(templateName,30)}</span>}
                      {templateEdit && <input type="text" value={templateName} className="template-edit-input" onChange={(e) => handleChangeEdit(e)} />}
                      {!templateEdit && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: "20px", height: "20px", color: "#487BFD" }}
                        className="template-edit" onClick={handleEditTemplate} />}
                      {templateEdit && <div className="template-edit-btns" >
                        <button style={{ color: 'white', background: '#610bee' }} onClick={handleSaveTemplateName}>Save</button>
                        <button style={{ color: '#610bee', background: 'white' }} onClick={handleEditTemplate}>Cancel</button>
                      </div>}

                      <span className="version-numer-text"> {versionDefault ? `v${versionDefault}` : ''}</span>
                    </div>



                  </div>
                  {/* {versionNumber ? 
                   <div className="version-number-text">
                   Version No. : <span>{versionNumber}</span>
                 </div>:<div className="version-number-text-hidden">
                 </div>} */}
                  <div data-tour="step-5">
                    <Select
                      value={{
                        value: selectedVersion ? `version ${selectedVersion}` : `version ${versionDefault || 0}`,
                        label: selectedVersion ? `version ${selectedVersion}` : `version ${versionDefault || 0}`
                      }}

                      styles={customStyles}
                      placeholder="choose version"
                      options={version}
                      onChange={(selectedOption) =>
                        handleVersionChange(selectedOption)
                      }
                    />
                  </div>


                  {/* {versionNumber ?
                    <div className="template-save-btn-1" data-tour="step-6">
                      <button onClick={handleMakeDefault} className="changes-btn">default template</button>
                    </div> : <div className="template-save-btn-1">
                      <button onClick={handleNoDefault} className="changes-btn-non-active" data-tour="step-6">default template</button>
                    </div>} */}
                  {/* {versionNumber ?} */}
                </div>
                {/* <button onClick={()=>handleSaveData(versionDefault)} className="changes-btn">save</button> */}
                {isAdmin === true && <div className="template-save-btn">
                  {/* <UploadExcellSheet onImport={handleImport} /> */}
                  {(saveButton || !droppedElements.every(el => el.hasOwnProperty('fieldName'))) && !versionNumber ? (
                    <button onClick={HandleNoChanges} className="no-changes-btn" data-tour="step-7">
                      Save
                    </button>
                  ) : (
                    (type === 'po-template' ) && (!approvarMember ) ? (
                      <button onClick={HandleNoChanges} className="no-changes-btn" data-tour="step-7">
                        Save
                      </button>
                    ) : (
                      <button
                        onClick={() => savingFunction(versionDefault)}
                        className="changes-btn"
                        data-tour="step-7"
                      >
                        Save
                      </button>
                    )
                  )}




                </div>}


                {openAlert && <TemplateConfirmPopUp onclose={handlecloseAlert} onsave={handleSaveData} type={type}
                  versionDefault={versionDefault} ondefault={handleSaveData} />}
              </div>
              <div className="template-field-details">
                <div
                  className="template-field-left"
                  style={{ display: close ? "none" : "block" }}
                >
                  <div className="template-field-left-top">
                    <div className="template-elements">
                      <span className="template-form-title">Form Elements</span>
                      <span className="template-form-subtitle">
                        Drag and drop form elements in additional section
                      </span>
                    </div>
                  </div>
                  <div></div>

                  <div className="template-field-left-bottom">
                    {fieldData.map((item) => (
                      <DraggableItem
                        key={item._id}
                        icon={item.icon}
                        label={item.displayType}
                        item={item}
                      />
                    ))}
                  </div>
                </div>
                {isActive &&
                  selectedItem.inputType === "text" &&
                  isTextTemplateVisible && (
                    <TextTemplate
                      onUpdate={handleTemplateUpdate}
                      fieldNameArray={fieldNameArray}
                      noFieldNameError={noFieldNameError}
                      startsWithAlphaError={startsWithAlphaError}
                      uniqueFieldNameError={uniqueFieldNameError}
                      uniqueError={uniqueError}
                      defaultFieldsArray={defaultFieldsArray}
                      data={selectedItem}
                      setIsTextTemplateVisible={setIsTextTemplateVisible}
                      containsAlphanumeric={containsAlphanumeric}
                      onClose={(index, item) => handleItemClick(index, item)}
                      style={{ display: selectClose ? "block" : "none" }}
                    />
                  )}

                {isActive &&
                  selectedItem.inputType === "paragraph" &&
                  isTextTemplateVisible && (
                    <ParagraphTemplate
                      style={{ display: selectClose ? "block" : "none" }}
                      noFieldNameError={noFieldNameError}
                      startsWithAlphaError={startsWithAlphaError}
                      uniqueFieldNameError={uniqueFieldNameError}
                      containsAlphanumeric={containsAlphanumeric}
                      uniqueError={uniqueError}
                      defaultFieldsArray={defaultFieldsArray}
                      onUpdate={handleTemplateUpdate}
                      fieldNameArray={fieldNameArray}
                      data={selectedItem}
                      onClose={(index, item) => handleItemClick(index, item)}
                    />
                  )}
                {isActive &&
                  selectedItem.inputType === "number" &&
                  isTextTemplateVisible && (
                    <NumberTemplate
                      style={{ display: selectClose ? "block" : "none" }}
                      noFieldNameError={noFieldNameError}
                      startsWithAlphaError={startsWithAlphaError}
                      uniqueFieldNameError={uniqueFieldNameError}
                      uniqueError={uniqueError}
                      defaultFieldsArray={defaultFieldsArray}
                      fieldNameArray={fieldNameArray}
                      containsAlphanumeric={containsAlphanumeric}
                      onClose={(index, item) => handleItemClick(index, item)}
                      onUpdate={handleTemplateUpdate}
                      data={selectedItem}
                    />
                  )}
                {isActive &&
                  selectedItem.inputType === "date" &&
                  isTextTemplateVisible && (
                    <DateTemplate
                      style={{ display: selectClose ? "block" : "none" }}
                      noFieldNameError={noFieldNameError}
                      startsWithAlphaError={startsWithAlphaError}
                      uniqueFieldNameError={uniqueFieldNameError}
                      uniqueError={uniqueError}
                      defaultFieldsArray={defaultFieldsArray}
                      fieldNameArray={fieldNameArray}
                      containsAlphanumeric={containsAlphanumeric}
                      onClose={(index, item) => handleItemClick(index, item)}
                      onUpdate={handleTemplateUpdate}
                      data={selectedItem}
                    />
                  )}
                {isActive &&
                  selectedItem.inputType === "date_range" &&
                  isTextTemplateVisible && (
                    <DateRangeTemplate
                      style={{ display: selectClose ? "block" : "none" }}
                      noFieldNameError={noFieldNameError}
                      startsWithAlphaError={startsWithAlphaError}
                      uniqueFieldNameError={uniqueFieldNameError}
                      uniqueError={uniqueError}
                      defaultFieldsArray={defaultFieldsArray}
                      fieldNameArray={fieldNameArray}
                      containsAlphanumeric={containsAlphanumeric}
                      onClose={(index, item) => handleItemClick(index, item)}
                      onUpdate={handleTemplateUpdate}
                      data={selectedItem}
                    />
                  )}
                {isActive &&
                  selectedItem.inputType === "person" &&
                  isTextTemplateVisible && (
                    <PersonTemplate
                      style={{ display: selectClose ? "block" : "none" }}
                      noFieldNameError={noFieldNameError}
                      startsWithAlphaError={startsWithAlphaError}
                      uniqueFieldNameError={uniqueFieldNameError}
                      uniqueError={uniqueError}
                      defaultFieldsArray={defaultFieldsArray}
                      fieldNameArray={fieldNameArray}
                      containsAlphanumeric={containsAlphanumeric}
                      onClose={(index, item) => handleItemClick(index, item)}
                      onUpdate={handleTemplateUpdate}
                      data={selectedItem}
                    />
                  )}
                {isActive &&
                  selectedItem.inputType === "team" &&
                  isTextTemplateVisible && (
                    <TeamTemplate
                      style={{ display: selectClose ? "block" : "none" }}
                      noFieldNameError={noFieldNameError}
                      startsWithAlphaError={startsWithAlphaError}
                      uniqueFieldNameError={uniqueFieldNameError}
                      uniqueError={uniqueError}
                      defaultFieldsArray={defaultFieldsArray}
                      fieldNameArray={fieldNameArray}
                      containsAlphanumeric={containsAlphanumeric}
                      onClose={(index, item) => handleItemClick(index, item)}
                      onUpdate={handleTemplateUpdate}
                      data={selectedItem}
                    />
                  )}
                {isActive &&
                  selectedItem.inputType === "link" &&
                  isTextTemplateVisible && (
                    <LinkTemplate
                      style={{ display: selectClose ? "block" : "none" }}
                      noFieldNameError={noFieldNameError}
                      startsWithAlphaError={startsWithAlphaError}
                      uniqueFieldNameError={uniqueFieldNameError}
                      uniqueError={uniqueError}
                      defaultFieldsArray={defaultFieldsArray}
                      fieldNameArray={fieldNameArray}
                      containsAlphanumeric={containsAlphanumeric}
                      onClose={(index, item) => handleItemClick(index, item)}
                      onUpdate={handleTemplateUpdate}
                      data={selectedItem}
                    />
                  )}
                {isActive &&
                  selectedItem.inputType === "single_select" &&
                  isTextTemplateVisible && (
                    <SingleSelectTemplate
                      style={{ display: selectClose ? "block" : "none" }}
                      noFieldNameError={noFieldNameError}
                      startsWithAlphaError={startsWithAlphaError}
                      uniqueFieldNameError={uniqueFieldNameError}
                      uniqueError={uniqueError}
                      defaultFieldsArray={defaultFieldsArray}
                      fieldNameArray={fieldNameArray}
                      containsAlphanumeric={containsAlphanumeric}
                      onUpdate={handleTemplateUpdate}
                      data={selectedItem}
                      onClose={(index, item) => handleItemClick(index, item)}
                    />
                  )}
                {isActive &&
                  selectedItem.inputType === "multi_select" &&
                  isTextTemplateVisible && (
                    <MultiSelectTemplate
                      style={{ display: selectClose ? "block" : "none" }}
                      noFieldNameError={noFieldNameError}
                      startsWithAlphaError={startsWithAlphaError}
                      uniqueFieldNameError={uniqueFieldNameError}
                      uniqueError={uniqueError}
                      defaultFieldsArray={defaultFieldsArray}
                      fieldNameArray={fieldNameArray}
                      containsAlphanumeric={containsAlphanumeric}
                      onClose={(index, item) => handleItemClick(index, item)}
                      onUpdate={handleTemplateUpdate}
                      data={selectedItem}
                    />
                  )}

                {!isTextTemplateVisible && (
                  <div
                    className="template-field-left"
                    style={{ display: close ? "block" : "none" }}
                  >
                    <div className="template-field-left-top">
                      <div className="template-elements">
                        <span className="template-form-title">
                          Form Elements
                        </span>
                        <span className="template-form-subtitle">
                          Drag and drop form elements in additional section
                        </span>
                      </div>
                    </div>
                    <div></div>
                    {loading ? (
                      <p>loading...</p>
                    ) : (
                      <div className="template-field-left-bottom">
                        {fieldData.map((item) => (
                          <DraggableItem
                            key={item._id}
                            icon={item.icon}
                            label={item.displayType}
                            item={item}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
                <div className="template-field-right">
                  <div className="template-mandatory-outbox">
                    <div
                      className="template-mandatory-container"
                      onClick={handleMandatoryContainerClick}
                    >
                      <div className="template-mandatory-content">
                        <span className="mandatory-heading">
                          Default Fields
                        </span>
                        <span className="mandatory-sub-heading">
                          These are mandatory fields required to fill a form
                        </span>
                      </div>
                      <img
                        src={`${ASSET_PREFIX_URL}template-dropdown.svg`}
                        alt=""
                        style={{ transform: `rotate(${rotation}deg)` }}
                      />
                    </div>
                    {isDroppingMandatoryOpen && (
                      <div className="dropping-mandatory">
                        <div className="dropped-default-list-array">
                          {elementDetails.map((element, index) => (
                            // Check if the fieldName is not equal to "deviation end date" and "select ec dhont"
                            (element.fieldName !== "Deviation end date" && element.fieldName !== "Select EC" &&
                              element.fieldName !== "Deviation Title") &&
                            (
                              <div key={index} className="dropped-default-list">
                                <span className="dropped-default-list-title">
                                  {element.fieldName}
                                </span>
                                <span className="dropped-default-list-desc">
                                  {element.description}
                                </span>
                              </div>
                            )
                          ))}

                        </div>
                      </div>
                    )}
                  </div>
                  <div className="template-custom-outbox">
                    <div
                      className="template-custom-container"
                      onClick={handleCustomClick}
                    >
                      <div className="template-custom-content">
                        <span className="custom-heading">Custom Fields</span>
                        <span className="custom-sub-heading">
                          Define additional fields you want to add for your
                          ticket creation
                        </span>
                      </div>
                      <img
                        className="custom-drop-img"
                        src={`${ASSET_PREFIX_URL}template-dropdown.svg`}
                        alt=""
                        style={{ transform: `rotate(${rotation}deg)` }}
                      />
                    </div>
                    {isDroppingCustomOpen && (
                      <div
                        className="dropping-custom"
                        ref={dropRef}
                      // onDragOver={(e) => e.preventDefault()}
                      >
                        {(droppedElements.length === 0 && type !== 'po-template' ) ? (
                          <div className="before-dropping">
                            <img
                              src={`${ASSET_PREFIX_URL}template-adding-button.svg`}
                              alt="No data"
                              width="213px"
                              height="86px"
                            />
                            <span>drag and drop to create</span>
                          </div>
                        ) : (
                          /* Render both dropped elements and custom details */
                          <>
                            {(type === 'po-template' ) && (
                              <PoTableCustomFields
                                elementDetails={elementDetails}
                                setSaveButton={handleSaveTableFields}
                                onopen={handleOpenTableFields}
                                tableFields={tableFields}
                                setTableFields={setTableFields}
                                departmentList={departmentList}
                                termsAndCondText={termsAndCondText}
                                approvarMember={approvarMember}
                                setApproverMember={setApproverMember}
                                setTermsAndCondText={setTermsAndCondText}
                                selectedApprover={selectedApprover}
                                setSelectedApprover={setSelectedApprover}
                                editorContent={editorContent}
                                setEditorContent={setEditorContent}
                              />
                            )}
                            {droppedElements.map((item, index) => (
                              <>
                                <div className={'dragging-over-class'} />
                                <TemplateItem
                                  isAdmin={isAdmin}
                                  key={index}
                                  label={item.displayType}
                                  index={index}
                                  item={item}
                                  setSaveButton={setSaveButton}
                                  isDroppedActive={isDroppedActive}
                                  setDroppedElements={setDroppedElements}
                                  setVersionNumber={setVersionNumber}
                                  activeIndex={activeIndex}
                                  handleItemClick={(item, index) => handleItemClick(item, index)}
                                  handleItemDelete={(item, index) => handleItemDelete(item, index)}
                                  droppedElements={droppedElements}
                                />
                              </>
                            ))}
                          </>
                        )}

                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openTableFields && <PoTableFields
        setSaveButton={handleSaveTableFields} onclose={handleOpenTableFields} tableFields={tableFields} setTableFields={setTableFields} template_id={templateId} />}
    </>
  );
}

export default TemplatePage;
