import React, { useState } from "react";
import "../PopUpsFolder/Notification.css";
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";
import { ASSET_PREFIX_URL } from "../../constants/config";
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";

function PartNumberPopUp({ onclose, onupdate, defaultVariables, characterLength, validateFields }) {
  const [inputSets, setInputSets] = useState(defaultVariables ? defaultVariables : [{ variable: "", meaning: "" }]);
  const [varaibleError, setVariableError] = useState('');
  // const [departmentList, setDepartmentList] = useState([]);

  const handleClose = () => {
    onclose();
  };

  const fetchDepartments = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/depart/get-departments", { headers });
      const departments = response.data.data.departments;
      // setDepartmentList(departments);
      fillInputSetsWithDepartments(departments);
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  const fillInputSetsWithDepartments = (departments) => {
    const updatedInputSets = departments.map((department) => ({
      variable: department.unique_initial,
      meaning: department.departmentName
    }));
    setInputSets(updatedInputSets);
  };

  const handleInputChange = (index, field, value) => {
    const updatedInputSets = [...inputSets];
    updatedInputSets[index][field] = value;
    setInputSets(updatedInputSets);
  };

  const handleAddMore = () => {
    setInputSets((prevInputSets) => [
      ...prevInputSets,
      { variable: "", meaning: "" },
    ]);
  };

  const handleSave = () => {
    // const invalidInputs = inputSets.filter(inputSet => inputSet.variable.length < characterLength);

    // if (invalidInputs.length > 0) {
    //   setVariableError("Variables length should be " + characterLength + " characters.");
    //   return;
    // }

    const inputSetsJSON = JSON.stringify(inputSets);
    validateFields();
    onupdate(inputSetsJSON);
    onclose();
  };

  const handleRemoveInput = (index) => {
    const updatedInputSets = [...inputSets];
    updatedInputSets.splice(index, 1);
    setInputSets(updatedInputSets);
  };

  return (
    <div className="part-number-popup-page">
      <div className="part-number-popup-div">
        <div className="part-number-popup-text">
          <span>Add fixed variables</span>
          <PopupCloseButton handleClose={handleClose} />
        </div>
        <div className="part-number-popup-desc">
          <span>Your variable is Alphabet with 2 length</span>
          <span>E.g. Variable = EN , ‘meaning’ = Engineering</span>
        </div>
        <div className="part-number-popup-hori">{/* HORIZONTAL LINE */}</div>
        <div className="part-number-popup-inputs-container">
          {inputSets.map((inputSet, index) => (
            <div key={index} className="part-number-popup-inputs">
              <input
                type="text"
                placeholder="Enter Variable"
                value={inputSet.variable}
                // maxLength={characterLength}
                onChange={(e) =>
                  handleInputChange(index, "variable", e.target.value)
                }
              />
              <input
                type="text"
                placeholder="Enter Meaning"
                value={inputSet.meaning}
                onChange={(e) =>
                  handleInputChange(index, "meaning", e.target.value)
                }
              />
              <img style={{ cursor: 'pointer' }}
                src={`${ASSET_PREFIX_URL}attri-delete.png`}
                alt='delete'
                onClick={() => handleRemoveInput(index)}
              />
            </div>
          ))}

          <div className="part-number-popup-add-more">
            <div className="part-fixed-addmore" onClick={handleAddMore}>
              <img src={`${ASSET_PREFIX_URL}template-select-add.svg`} alt="" />
              <span>Add more</span>
            </div>
            <div className="part-fixed-addmore" onClick={fetchDepartments}>
              <img src={`${ASSET_PREFIX_URL}departments-blue-icon.png`} alt="" width='22px' height='22px' />
              <span>Departments</span>
            </div>
          </div>
          {varaibleError !== '' && <span className="part-number-popup-desc" style={{ color: 'red' }}>{varaibleError}</span>}
        </div>
        <div className="part-number-popup-save-btn">
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default PartNumberPopUp;
