import React from 'react'
import CloseIcon from "@mui/icons-material/Close";
import '../../ListsPages/TicketList.css'

function TicketTitlePopUp({ ticketTitle, onClose }) {

  const autoResize = () => {
    const textarea = document.getElementById('expandingTextarea');
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };
  return (
    <div className='tkt-title-cont'>
      <div className='tkt-title-box'>
        <div className='tkt-heading'>
          <span>Title</span>
          <CloseIcon onClick={onClose} className='close-icon' />
        </div>
        <div className='horizontalLine'></div>
        <div className='tkt-name-text'>
          <textarea
           id="expandingTextarea"
           onInput={autoResize}
           style={{ resize: 'none', overflowY: 'hidden' }}
            value={ticketTitle} readOnly />
        </div>

      </div>
    </div>
  )
}

export default TicketTitlePopUp