import React, { useEffect, useState } from "react";
import axios from "axios";
import Tree from "react-d3-tree";
import { useCenteredTree } from "../Common//BomHierarchy/BomHelper";
import "../Common/BomHierarchy/Bomheirarchy.css";
import Cookies from "js-cookie";
import { ASSET_PREFIX_URL, BASE_URL } from "../../constants/config";
import Loading from "../Common/Loading/Loading";
import { useParams } from "react-router-dom";
import AddPartsPopUp from "./AddPartsPopUp";
import { useNavigate } from "react-router-dom";
import ResetEBOMtree from "./ResetEBOMtree";

const containerStyles = {
    width: "100%",
    height: "99vh",
};

const renderRectSvgNode = ({
    nodeDatum,
    type,
    toggleNode,
    add,
    setAdd,
    clickedData,
    setClickedData,
    parentNode,
    editable,
    isHovered,
    setIsHovered,

    setShowPlus,
    setActiveNode, setAction,
    activeNode
}) => {

    const handleNodeClick = () => {
        const url = `/part-view/${nodeDatum.part_number}`;
        window.open(url, '_blank');
    };

    const handleMouseEnter = () => {
        if (editable) {
            setIsHovered(true);
            setShowPlus(true);
            if (nodeDatum !== activeNode) {
                setActiveNode(nodeDatum);
            }
        }

    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setShowPlus(false);
    };

    const handleAdd = () => {
        setAdd(!add);
        setClickedData(nodeDatum);
    };

    const handleMenuActions = (action) => {
        setIsHovered(false);
        setShowPlus(false);
        setAction(action)

        setAdd(false)
    };


    const strokeColor = activeNode === nodeDatum ? (isHovered ? "#FF7A7A" : "#C4C9CC") : "#C4C9CC";

    // Check if the node is the root node
    const getTextWidth = (text, fontSize = 15) => {
        // Simple approximation: 8 pixels per character at 15px font size
        const averageWidthPerChar = 8;
        return text.length * averageWidthPerChar;
    };

    const quantityText = nodeDatum.quantity ? nodeDatum.quantity.toString() : '1';
    const quantityTextWidth = getTextWidth(quantityText);

    const formatDate = (date) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString('en-GB', options);
    };
    return (
        <g>
            <rect
                width="293"
                height="150"
                x="-150"
                y="-50"
                rx="8"
                ry="8"
                strokeWidth="0"
                fill="transparent"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{ pointerEvents: 'all' }}
            />
            <rect
                width="293"
                height="90"
                x="-150"
                y="-50"
                rx="8"
                ry="8"
                strokeWidth="1.75"
                onClick={toggleNode}
                fill="#fff"
                stroke="#b557a8"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                    boxShadow: "0px 2px 4px 0px rgba(72, 123, 253, 0.10)",
                    border: "4.5px solid #b557a8",
                    stroke: strokeColor,
                }}
            />
            {activeNode === nodeDatum && isHovered && (
                <g onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <line
                        x1="0"
                        y1="57"
                        x2="0"
                        y2="40"
                        stroke="#FF7A7A"
                        strokeWidth="2"
                    />
                    <image
                        x="-15"
                        y="50"
                        width="30"
                        height="30"
                        rx="10"
                        ry="10"
                        xlinkHref={`${ASSET_PREFIX_URL}Add action-d3.svg`}
                        alt=""
                        onClick={handleAdd}
                        style={{ background: 'white' }}
                    />
                </g>
            )}
            {activeNode === nodeDatum && add && clickedData === nodeDatum && (
                <g
                    transform="translate(145, -50)"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ border: "1px solid black" }}
                >
                    <foreignObject width="160" height="130">
                        <div xmlns="http://www.w3.org/1999/xhtml" className="menu-box">
                            <text onClick={() => handleMenuActions('add')}>
                                <img
                                    src={`${ASSET_PREFIX_URL}add-member.svg`}
                                    alt=""
                                    style={{ width: "20px", height: "20px" }}
                                />Add
                            </text>
                            {nodeDatum.part_number !== parentNode && (
                                <>
                                {type !== 'mbom' && <text onClick={() => handleMenuActions('replace')}>
                                        <img
                                            src={`${ASSET_PREFIX_URL}change-manager.svg`}
                                            alt=""
                                            style={{ width: "20px", height: "20px" }}
                                        />Replace
                                    </text>}
                                    
                                    <text onClick={() => handleMenuActions('update_quantity')}>
                                        <img
                                            src={`${ASSET_PREFIX_URL}change-manager.svg`}
                                            alt=""
                                            style={{ width: "20px", height: "20px" }}
                                        />Update
                                    </text>
                                    <text style={{ color: "#FF3B2F" }} onClick={() => handleMenuActions('delete')}>
                                        <img
                                            src={`${ASSET_PREFIX_URL}menu-delete-icon.svg`}
                                            alt=""
                                            style={{ width: "20px", height: "20px" }}
                                        />Delete
                                    </text>
                                </>
                            )}
                        </div>
                    </foreignObject>
                </g>
            )}
            {nodeDatum.count > 0 && (
                <>
                    <rect
                        width="34"

                        height="20"
                        x="105"
                        y="-42"
                        rx="4"
                        ry="4"
                        fill="#fff"
                        strokeWidth='1.5'
                        onClick={toggleNode}
                        stroke="#b557a8"
                        style={{
                            boxShadow: "0px 2px 4px 0px rgba(72, 123, 253, 0.10)",
                            border: "4.5px solid #b557a8",
                            stroke: "#b557a8",
                        }}
                    />
                    <text
                        fill="black"
                        strokeWidth="1"
                        x="117"
                        y="-42"
                        dy="1em"
                        textAnchor="start"
                        fontFamily="Inter, sans-serif"
                        fontSize="15px"
                    >
                        {nodeDatum.count}
                    </text>
                </>
            )}
            {/* <>

                <text
                    fill="black"
                    strokeWidth="1"
                    x="-140"
                    y="5"
                    dy="1em"
                    textAnchor="start"
                    fontFamily="Inter, sans-serif"
                    fontSize="15px"
                >
                    qty
                </text>
                <rect
                    width="34"
                    height="20"
                    x="-110"
                    y="5"
                    rx="4"
                    ry="4"
                    fill="#fff"
                    strokeWidth='1.5'
                    onClick={toggleNode}
                    stroke="#b557a8"
                    style={{
                        boxShadow: "0px 2px 4px 0px rgba(72, 123, 253, 0.10)",
                        border: "4.5px solid #b557a8",
                        stroke: "#b557a8",
                    }}
                />
                <text
                    fill="black"
                    strokeWidth="1"
                    x="-100"
                    y="5"
                    dy="1em"
                    textAnchor="start"
                    fontFamily="Inter, sans-serif"
                    fontSize="15px"
                >
                    {nodeDatum.quantity ? nodeDatum.quantity : '1'}
                </text>
            </> */}
            <>
                <text
                    fill="black"
                    strokeWidth="1"
                    x="-140"
                    y="-15"
                    dy="1em"
                    textAnchor="start"
                    fontFamily="Inter, sans-serif"
                    fontSize="15px"
                >
                    qty
                </text>
                <rect
                    width={quantityTextWidth + 20}  // Add some padding
                    height="20"
                    x="-110"
                    y="-15"
                    rx="4"
                    ry="4"
                    fill="#fff"
                    strokeWidth='1.5'
                    onClick={toggleNode}
                    stroke="#b557a8"
                    style={{
                        boxShadow: "0px 2px 4px 0px rgba(72, 123, 253, 0.10)",
                        border: "4.5px solid #b557a8",
                        stroke: "#b557a8",
                    }}
                />
                {(type === 'mbom' && nodeDatum.part_number !== parentNode) &&
                    <text
                        fill="black"
                        strokeWidth="1"
                        x="-140"
                        y="15"
                        dy="1em"
                        textAnchor="start"
                        fontFamily="Inter, sans-serif"
                        fontSize="15px"
                    >
                        {formatDate(nodeDatum.start_date)} - {formatDate(nodeDatum.end_date)}
                    </text>}


                <text
                    fill="black"
                    strokeWidth="1"
                    x="-100"
                     y="-15"
                    dy="1em"
                    textAnchor="start"
                    fontFamily="Inter, sans-serif"
                    fontSize="15px"
                >
                    {quantityText}
                </text>

            </>
            <text
                fill="blue"
                onClick={handleNodeClick}
                strokeWidth="0.5"
                x="-140"
                y="-45"
                dy="1em"
                textAnchor="start"
                fontSize="15px"
                style={{ textDecoration: "underline" }}
            >
                {nodeDatum.part_number}
            </text>
            {/* <text
                fill="black"
                strokeWidth="1"
                x="-140"
                y="5"
                dy="1em"
                textAnchor="start"
                fontSize="15px"
            >
                {nodeDatum.part_title}
            </text> */}
        </g>
    );
};

function ModifyEBOMview({ type }) {
    const [treeData, setTreeData] = useState({});
    const [loading, setLoading] = useState(false);
    const [clickedData, setClickedData] = useState(null);
    const [add, setAdd] = useState(false);
    const [action, setAction] = useState(false);
    const [editable, setEditable] = useState(false);
    const { sequence_id } = useParams();
    const [activeNode, setActiveNode] = useState(null);
    const [parentNode, setParentNode] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [showPlus, setShowPlus] = useState(false);
    const [updatedData,setUpdatedData] = useState(false)
    const nav = useNavigate()
    // const [openParentPart, setParentPart] = useState(false);
    // const [parentParts, setParentParts] = useState([]);
    // const [selectedPart, setSelectedPart] = useState([]);
    useEffect(() => {
        fetchBOMhierarchy();
    }, [updatedData]);

    const fetchBOMhierarchy = async () => {
        try {
            setLoading(true);
            const headers = {
                'x-auth-token': Cookies.get("token")
            };
            let response;
            if (type === 'ebom') {
                response = await axios.get(BASE_URL + "/v1/structure-ec/get-ebom", {
                    headers,
                    params: { sequence_id, view: 'tree' }
                });
            } else if (type === 'mbom') {
                response = await axios.get(BASE_URL + "/v1/manufac-bom/get-mbom-view", {
                    headers,
                    params: { sequence_id, view: 'tree' }
                });
            }

            setEditable(response.data.data.should_edit)
            setParentNode(response.data.data.ebom_hierarchy.part_number)
            setTreeData(response.data.data.ebom_hierarchy);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    const [dimensions, translate, containerRef] = useCenteredTree();

    const handleCloseReset = () => {
        setAction(false)
    }


    const handleBack = () => {
        if (type === 'ebom') {
            nav(`/ec-ticket-view/${sequence_id}`)
        } else if (type === 'mbom') {
            nav(`/pci-view/${sequence_id}`)
        }
    }
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div style={containerStyles} ref={containerRef} className="org-hierarchy">
                    <Tree
                        data={treeData}
                        orientation="vertical"
                        zoom={0.75}
                        translate={translate}
                        pathFunc="step"
                        separation={{ siblings: 3.25, nonSiblings: 3.5, parentChild: 200 }}
                        renderCustomNodeElement={(props) =>
                            renderRectSvgNode({
                                ...props, add, setAdd, clickedData, setClickedData, action, setAction,
                                isHovered, setIsHovered, activeNode, setActiveNode, editable,
                                showPlus, setShowPlus, parentNode, sequence_id, setAction, type
                            })
                        }
                    />
                    <button className="btn-back-ebom" style={{ background: 'white' }} onClick={handleBack}>
                        <img src={`${ASSET_PREFIX_URL}template-back-btn.svg`} />
                    </button>
                    {type !== 'mbom' && <button className="btn-collab" onClick={() => setAction('reset')}>
                        Reset
                    </button>}

                </div>
            )}
            {(action === 'add' || action === 'replace' || action === 'delete' || action === 'update_quantity') && <AddPartsPopUp
                activeNode={clickedData} setAction={setAction} action={action} sequence_id={sequence_id} rootnode={parentNode}
                type={type} setUpdatedData={setUpdatedData}
            />}
            {action === 'reset' && <ResetEBOMtree sequence_id={sequence_id} onclose={handleCloseReset} setUpdatedData={setUpdatedData}/>}



        </>
    );
}

export default ModifyEBOMview;
