import React, { useState, useEffect } from "react";
import {
    BASE_URL,
    PHOTO_LINK,
    ASSET_PREFIX_URL,
    DEFAULT_COMPANY_LINK
} from "../../../constants/config";
import './CompanyPage.css'
import axios from "axios";
import Cookies from "js-cookie";
import Loading from "../../Common/Loading/Loading";
import PhotoEditPopUp from "../GeneralPage/PhotoEditPopUp";

function CompanyPage() {
    const [loading, setLoading] = useState(false);
    const [gstEdit, setGstEdit] = useState(false);
    const [gst, setGst] = useState("");
    const [panEdit, setPanEdit] = useState(false);
    const [pan, setPan] = useState("");
    const [cinEdit, setCinEdit] = useState(false);
    const [cin, setCin] = useState("");
    const [tanEdit, setTanEdit] = useState(false);
    const [tan, setTan] = useState("");
    const [company, setCompany] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [isAdmin, setIsAdmin] = useState(false); // Initialize as false
    const [updatedDetails,setUpdatedDetails] = useState(false)
    const [editPhotoOpen, setPhotoEditOpen] = useState(false);
    const [panError, setPanError] = useState("");
    const [gstError, setGstError] = useState("");
    const [cinError, setCinError] = useState("");
    const [tanError, setTanError] = useState("");

    const [addresses, setAddresses] = useState([]);
    const [newAddress, setNewAddress] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [editIndex, setEditIndex] = useState(-1); // Track the index of the address being edited

    useEffect(() => {
        fetchMemberDetails();
    }, [updatedDetails]);

    const validatePan = (value) => {
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        return panRegex.test(value);
    };

    const validateGst = (value) => {
        const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
        return gstRegex.test(value);
    };

    const validateCin = (value) => {
        const cinRegex = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
        return cinRegex.test(value);
    };

    const validateTan = (value) => {
        const tanRegex = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
        return tanRegex.test(value);
    };

    const handlePanChange = (e) => {
        const value = e.target.value;
        setPan(value);
        if (!validatePan(value)) {
            setPanError("Invalid PAN number");
        } else {
            setPanError("");
        }
    };

    const handleGstChange = (e) => {
        const value = e.target.value;
        setGst(value);
        if (!validateGst(value)) {
            setGstError("Invalid GST number");
        } else {
            setGstError("");
        }
    };

    const handleCinChange = (e) => {
        const value = e.target.value;
        setCin(value);
        if (!validateCin(value)) {
            setCinError("Invalid CIN number");
        } else {
            setCinError("");
        }
    };

    const handleTanChange = (e) => {
        const value = e.target.value;
        setTan(value);
        if (!validateTan(value)) {
            setTanError("Invalid TAN number");
        } else {
            setTanError("");
        }
    };

    const getMenu = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/onboard/get-menu", { headers });
            const data = response.data.data;
            localStorage.setItem("orgDetails", JSON.stringify(data.org_details.orgDetails));
            localStorage.setItem("memberDetails", JSON.stringify(data.profile_details));
            localStorage.setItem("starredDepartments", JSON.stringify(data.star_depart));
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    const fetchMemberDetails = async () => {
        try {
            setLoading(true);
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/setting/get-business-details", { headers });
            const required = response.data.data;
            setIsAdmin(required.is_admin);
            setCompany(required.business_details.name);
            setCompanyLogo(required.business_details.logo);
            setPan(required.business_details.pan);
            setGst(required.business_details.gst);
            setCin(required.business_details.cin);
            setTan(required.business_details.tan);
            setAddresses(required.business_details.addresses || []); // Initialize addresses from response

            setLoading(false);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    const handleClosePopup = () => {
        setPhotoEditOpen(false);
    };

    const handleBusinessLogoFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Data = reader.result.split(",")[1];
                    setCompanyLogo(base64Data);
                    const require = { logo: base64Data };
                    updateBusiness(require.logo);
                };
                reader.readAsDataURL(file);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const updateBusiness = async (logo) => {
        console.log(logo)
        // if (panError === '' && gstError === '' && cinError === '' && tanError === '' && logo ===''&&newAddress !== '') {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const postData={
                logo,
                name: company,
                gst,
                tan,
                pan,
                cin,
                address: newAddress ? [...addresses, newAddress] : addresses
            }
            await axios.post(BASE_URL + "/v1/setting/edit-company-details", postData, { headers });
            await getMenu();
            setUpdatedDetails(postData)
        } catch (error) {
            console.error(error);
        }
        // } else {
        //     console.log("Validation errors present, cannot update business details.");
        // }
    };

    const handleAddAddress = () => {
        if (newAddress.trim() !== '') {
            setAddresses([...addresses, newAddress]);
            setNewAddress(''); // Clear the input after adding
            setIsAdding(false); // Exit adding mode
            updateBusiness(); // Update business details after adding new address
        }
    };

    const handleEditAddress = (index) => {
        setEditIndex(index); // Set the index of the address being edited
    };

    const handleSaveAddress = async () => {
        setEditIndex(-1); // Exit edit mode
        updateBusiness(); // Send updated details to the server
    };

    const handleAddressChange = (index, value) => {
        const updatedAddresses = addresses.map((address, idx) =>
            idx === index ? value : address
        );
        setAddresses(updatedAddresses);
    };

    const handleDeleteAddress = (index) => {
        const updatedAddresses = addresses.filter((_, idx) => idx !== index);
        setAddresses(updatedAddresses);
        updateBusiness(); // Update business details after deleting address
    };



    return (
        <>
            {loading ? <Loading /> :
                <div width="100%">
                    <div className="general-page">
                        <div className="general-profiels">
                            <div className="general-upload-con">
                                <span>Company logo</span>
                                <div className="general-upload">
                                    <img
                                        src={companyLogo ? PHOTO_LINK + companyLogo : DEFAULT_COMPANY_LINK}
                                        alt=""
                                        style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                                    />
                                    {isAdmin ? <button onClick={() => document.getElementById("filecmpnyupld").click()}>
                                        <img
                                            src={`${ASSET_PREFIX_URL}upload-plus.svg`}
                                            width="16px"
                                            height="16px"
                                            alt=""
                                        />
                                    </button> : ''}
                                </div>
                            </div>
                            <input
                                type="file"
                                id="filecmpnyupld"
                                accept="image/jpeg, image/png, image/gif"
                                style={{ display: "none" }}
                                onChange={handleBusinessLogoFileUpload}
                                className="btn-upload"
                            />
                        </div>
                        <div className="company-details-bottom">
                            <div className="company-details-left">
                                <div className="general-company-name">
                                    <span className="general-company-name">Company Name</span>
                                    <div className="general-company-name-input">
                                        <input type="text" disabled value={company} />
                                    </div>
                                </div>
                                <div className="general-company-name">
                                    <span className="general-company-name">GST IN</span>
                                    <div className="general-company-name-input">
                                        {gstEdit ? (
                                            <>
                                                <input type="text" value={gst} onChange={handleGstChange} />
                                                <img onClick={() => updateBusiness()} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                <img onClick={() => setGstEdit(false)} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                            </>
                                        ) : (
                                            <>
                                                <input type="text" disabled value={gst} />
                                                {isAdmin &&
                                                    <img onClick={() => setGstEdit(true)} src={`${ASSET_PREFIX_URL}edit-ticket.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />}
                                            </>
                                        )}
                                    </div>
                                    {gstError && <span className="error-message">{gstError}</span>}
                                </div>
                                <div className="general-company-name">
                                    <span className="general-company-name">PAN*</span>
                                    <div className="general-company-name-input">
                                        {panEdit ? (
                                            <>
                                                <input type="text" value={pan} onChange={handlePanChange} />
                                                <img onClick={() => updateBusiness()} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                <img onClick={() => setPanEdit(false)} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                            </>
                                        ) : (
                                            <>
                                                <input type="text" disabled value={pan} />
                                                {isAdmin &&
                                                    <img onClick={() => setPanEdit(true)} src={`${ASSET_PREFIX_URL}edit-ticket.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                }
                                            </>
                                        )}
                                    </div>
                                    {panError && <span className="error-message">{panError}</span>}
                                </div>
                                <div className="general-company-name">
                                    <span className="general-company-name">CIN*</span>
                                    <div className="general-company-name-input">
                                        {cinEdit ? (
                                            <>
                                                <input type="text" value={cin} onChange={handleCinChange} />
                                                <img onClick={() => updateBusiness()} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                <img onClick={() => setCinEdit(false)} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                            </>
                                        ) : (
                                            <>
                                                <input type="text" disabled value={cin} />
                                                {isAdmin &&

                                                    <img onClick={() => setCinEdit(true)} src={`${ASSET_PREFIX_URL}edit-ticket.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                }
                                            </>
                                        )}
                                    </div>
                                    {cinError && <span className="error-message">{cinError}</span>}
                                </div>
                                <div className="general-company-name">
                                    <span className="general-company-name">TAN*</span>
                                    <div className="general-company-name-input">
                                        {tanEdit ? (
                                            <>
                                                <input type="text" value={tan} onChange={handleTanChange} />
                                                <img onClick={() => updateBusiness()} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                <img onClick={() => setTanEdit(false)} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                            </>
                                        ) : (
                                            <>
                                                <input type="text" disabled value={tan} />
                                                {isAdmin &&
                                                    <img onClick={() => setTanEdit(true)} src={`${ASSET_PREFIX_URL}edit-ticket.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />

                                                }
                                            </>
                                        )}
                                    </div>
                                    {tanError && <span className="error-message">{tanError}</span>}
                                </div>
                            </div>
                            <div className="company-address-div">
                                {[...addresses].reverse().map((address, index) => (
                                    <div key={index}>
                                        <div className="general-company-name">
                                            <span className="general-company-name">
                                            {index === 0 ? 'Registered Address (Full address)' : 'Alternate address (Full address)'}
                                            </span>
                                            <div className="general-company-name-input">
                                                {editIndex === addresses.length - 1 - index ? (
                                                    <>
                                                        <textarea
                                                            type="text"
                                                            value={address}
                                                            onChange={(e) => handleAddressChange(addresses.length - 1 - index, e.target.value)}
                                                        />
                                                        <img
                                                            onClick={handleSaveAddress}
                                                            src={`${ASSET_PREFIX_URL}save-details.png`}
                                                            alt=""
                                                            width="24px"
                                                            height="24px"
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        <img
                                                            onClick={() => setEditIndex(-1)}
                                                            src={`${ASSET_PREFIX_URL}cancel-detail.png`}
                                                            alt=""
                                                            width="24px"
                                                            height="24px"
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <textarea type="text" value={address} disabled />
                                                        {isAdmin && (
                                                            <img
                                                                onClick={() => handleEditAddress(addresses.length - 1 - index)}
                                                                src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                                alt=""
                                                                width="24px"
                                                                height="24px"
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {isAdding ? (
                                    <div className="general-company-name">
                                        <span className="general-company-name">
                                            {addresses.length > 0 ? 'Alternate address (Full address)' : 'Registered address (Full address)'}
                                        </span>
                                        <div className="general-company-name-input">
                                            <textarea type="text" value={newAddress} onChange={(e) => setNewAddress(e.target.value)} />
                                            <img
                                                onClick={() => updateBusiness()}
                                                src={`${ASSET_PREFIX_URL}save-details.png`}
                                                alt="" 
                                                width="24px"
                                                height="24px"
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <img
                                                onClick={() => setIsAdding(false)}
                                                src={`${ASSET_PREFIX_URL}cancel-detail.png`}
                                                alt=""
                                                width="24px"
                                                height="24px"
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <button onClick={() => setIsAdding(true)} className="add-alternate-address-btn">
                                        Add alternate address
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {editPhotoOpen && <PhotoEditPopUp ProfilePhoto={companyLogo} name={company} onClose={handleClosePopup} />}
        </>
    );
}

export default CompanyPage;
