import React, { useEffect, useState, useRef,useCallback } from 'react';
import { ASSET_PREFIX_URL, BASE_URL } from '../../constants/config';
import axios from 'axios';
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import Cookies from "js-cookie";
import {  debounceScroll } from '../../constants/helper';
import { debounce } from 'lodash';
import SearchFilter from '../Common/CommonJsx/SearchFilter';


function EcPartsForm({ typeEC, handlePrevStepToParts, handleCreate, errorMessage }) {
    const [partOptions, setPartOptions] = useState([]);
    const [selectedParts, setSelectedParts] = useState([]);
    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [fetching, setFetching] = useState(false);
    const LIMIT = 20;
    const containerRef = useRef(null);

    useEffect(() => {
        setPartOptions([]);
        setActivePage(1);
    }, [search]);

    // Debounce search and trigger API call for parts
    useEffect(() => {
       
            getParts();
        
    }, [activePage, search]);

    const handleSearch = (e) => {
       
       
        debouncedSearch(e.target.value);
        setActivePage(1); // Reset page number on new search
    };
    const debouncedSearch = useCallback(
        debounce((value) => {
          setSearch(value);
        }, 100),
        []
      );
    const handleScroll = debounceScroll(() => {
        const container = containerRef.current;
        if (!hasMore || fetching) return;
    
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
            setActivePage(prevPage => prevPage + 1);
        }
    }, 500);
    
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [containerRef, handleScroll]);

    const getParts = async () => {
        try {
            setFetching(true);
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
    
            let response;
            if (['ec-template', 'ec-deviation'].includes(typeEC)) {
                response = await axios.get(
                    `${BASE_URL}/v1/ec-deviations/unique-parts`,
                    {
                        params: { search, page: activePage, size: LIMIT },
                        headers,
                    }
                );
            } else if (typeEC === 'pc-template') {
                response = await axios.get(
                    `${BASE_URL}/v1/prod-change/get-appr-parts`,
                    {
                        params: { search, page: activePage, size: LIMIT },
                        headers,
                    }
                );
            } else if (['part-create', 'part-variation'].includes(typeEC)) {
                response = await axios.get(
                    `${BASE_URL}/v1/part-nomen/get-list-part-number`,
                    {
                        params: { search, page: activePage, limit: LIMIT },
                        headers,
                    }
                );
            }
    
            const { part_number_list } = response.data.data;
    
            setPartOptions(prev => {
                const existingParts = new Map(prev.map(part => [part.part_number, part]));
    
                // Loop through the new parts and add them to the Map if they don't already exist
                part_number_list.forEach(part => {
                    if (!existingParts.has(part.part_number)) {
                        existingParts.set(part.part_number, part);
                    }
                });
    
                const newPartOptions = Array.from(existingParts.values());
                console.log("Total parts after scroll:", newPartOptions.length); // Log length here
                return newPartOptions;
            });
    
            setHasMore(part_number_list.length === LIMIT);
            setFetching(false);
        } catch (error) {
            console.error("Error fetching parts:", error);
            setFetching(false);
        }
    };
    
    

    const toggleSelectPart = (part) => {
        const index = selectedParts.findIndex(selectedPart => selectedPart.part_number === part.part_number);
    
        if (['ec-template', 'ec-deviation', 'pc-template'].includes(typeEC)) {
            const partIdOrNumber = part._id || part.part_number;
            if (index === -1) {
                setSelectedParts([...selectedParts, { part_number: part.part_number, part_id: partIdOrNumber }]);
            } else {
                const updatedParts = [...selectedParts];
                updatedParts.splice(index, 1);
                setSelectedParts(updatedParts);
            }
        } else {
            const { part_number, part_title, quantity } = part;
            
            if (!quantity) {
                setError('Please provide a quantity to add the part.');
                return;
            }
    
            setError('');
            if (index === -1) {
                setSelectedParts([...selectedParts, { part_number, part_title, quantity }]);
            } else {
                const updatedParts = [...selectedParts];
                updatedParts.splice(index, 1);
                setSelectedParts(updatedParts);
            }
        }
    };
    

    const createEC = (event) => {
        if (['ec-template', 'ec-deviation'].includes(typeEC)) {
            handleCreate(event, typeEC, JSON.stringify(selectedParts));
        }else if(typeEC === 'pc-template'){
            handleCreate(event, typeEC, selectedParts.map(item=>item.part_number));
        } else {
            // if (!errorMessage) {
                handleCreate(selectedParts);
            // } else {
            //     setError(errorMessage);
            // }
        }
    };

    const handleQuantityChange = (e, item) => {
        const newQuantity = e.target.value.trim();
        if (newQuantity === '' || /^\d+$/.test(newQuantity)) {
            const updatedQuantity = newQuantity === '' ? '' : parseInt(newQuantity, 10);
            setPartOptions((prevOptions) =>
                prevOptions.map(part => part.part_number === item.part_number ? { ...part, quantity: updatedQuantity } : part)
            );
        }
    };

    return (
        <div className="ec-part-page">
            <div className="ec-ticket-home">
                <div className="ex-ticket-title">
                    <span className="ec-title">
                        {typeEC === 'ec-template' && 'Create new EC'}
                        {typeEC === 'ec-deviation' && 'Create new deviation'}
                        {typeEC === 'pc-template' && 'Create new PC'}
                        {['part-create', 'part-variation'].includes(typeEC) && 'Add Child Part(s)'}
                    </span>
                    <PopupCloseButton handleClose={handlePrevStepToParts} />
                </div>

                <div className="dept-ticket-steps">
                    <span>Step 2 of 2</span>
                </div>
                <img src={`${ASSET_PREFIX_URL}ticket-step2.svg`} alt="" width="100%" />

                <SearchFilter searchTerm={search} handleSearchChange={handleSearch} listType='part number...' />

                {(error||errorMessage) && <span className='error-message'>{error||errorMessage}</span>}

                <div className='part-div-infinite' onScroll={handleScroll} ref={containerRef}>
                    <table className="department-table-part-data">
                        <thead>
                            <tr>
                                <th className='part-num'>Part number</th>
                                <th className='part-tit'>Part title</th>
                                {(typeEC === 'part-create' || typeEC === 'part-variation') && <th className='part-quan'>Quantity</th>}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {partOptions.map((item) => (
                                <tr className="tr-hover" key={item._id}>
                                    <td>{item.part_number}</td>
                                    <td>{item.part_title}</td>
                                    {(typeEC === 'part-create' || typeEC === 'part-variation') && (
                                        <td>
                                            <input
                                                type="number"
                                                placeholder={!item.quantity && '+Qty'}
                                                value={item.quantity || ''}
                                                className='part-numbers-views-input'
                                                onChange={(e) => handleQuantityChange(e, item)}
                                            />
                                        </td>
                                    )}
                                    <td>
                                        {selectedParts.some(selectedPart => selectedPart.part_number === item.part_number) ? (
                                            <img
                                                width='30px' height='30px'
                                                src={`${ASSET_PREFIX_URL}attri-delete.png`}
                                                alt='remove'
                                                onClick={() => toggleSelectPart(item)}
                                            />
                                        ) : (
                                            <img
                                                width='30px' height='30px'
                                                src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                                                alt='add'
                                                onClick={() => toggleSelectPart(item)}
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="depart-form-btns">
                    <button className="dept-form-cancel" onClick={handlePrevStepToParts}>
                        Go back
                    </button>
                    <button className="dept-form-submit" onClick={(event) => createEC(event)}>
                        Create
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EcPartsForm;