import React, { useState } from 'react'
import ExcelView from '../Common/BomHierarchy/ExcellView';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import BomHierarchy from '../Common/BomHierarchy/Bomhierarchy';
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

const today = new Date();
const start = new Date(today);
const formatDate = (date) => date.toISOString().split('T')[0];

function MbomView() {
    const [selectedViewType, setSelectedViewType] = useState('excel');
    const [date, setDate] = useState(formatDate(start))
    const nav = useNavigate()
    const { product_id } = useParams();
    const handleSectionViewClick = (section) => {

        setSelectedViewType(section);

    };

    const handleDateChange = (e) => {
        setDate(e)
    }
    const handleReset = async () => {


        setDate(formatDate(start))
    };
    return (

        <div style={{ marginTop: '90px', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: '10px 20px', justifyContent: 'space-between' }}>
                <div className="ticket-view-right">
                    <span> <span style={{ cursor: 'pointer', color: "#610BEE" }} onClick={()=>nav('/mbom-list')}>Product</span> /</span>
                    <span style={{ color: "#610BEE" }}>model</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '60%', gap: '20px' }}>
                    <div>
                        <span>Choose date</span>
                        <input type='date' onChange={(e) => handleDateChange(e.target.value)}
                            value={date} style={{ padding: '8px', border: '1px solid #edf3f7', borderRadius: '4px', width: '200px' }} />
                    </div>
                    {date !== formatDate(start) && <button
                        className="reset-btn"
                        onClick={handleReset}
                        data-tour="step-11"
                    >
                        reset <TuneOutlinedIcon />
                    </button>}

                </div>

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', }}>
                <div className="setting-navs" style={{ marginBottom: "20px", width: '100%' }}>
                    <div
                        className={`privacys ${selectedViewType === "excel" ? "settingActive" : ""}`}
                        onClick={() => handleSectionViewClick("excel")}
                        style={{
                            cursor: "pointer",
                            boxShadow: selectedViewType === "excel" ? "0px 3px 0px 0px #FF7A7A" : "none",
                            padding: "10px 16px",
                            color: selectedViewType === "excel" ? "#FF7A7A" : "black",
                            textAlign: 'center',
                            width: '50%'
                        }}
                    >
                        Excel view
                    </div>

                    <div
                        className={`generals ${selectedViewType === "tree" ? "settingActive" : ""}`}
                        onClick={() => handleSectionViewClick("tree")}
                        style={{
                            cursor: "pointer",
                            boxShadow: selectedViewType === "tree" ? "0px 3px 0px 0px #FF7A7A" : "none",
                            padding: "10px 16px",
                            color: selectedViewType === "tree" ? "#FF7A7A" : "black",
                            textAlign: 'center',
                            width: '50%'
                        }}
                    >
                        Tree view
                    </div>
                </div>

            </div>
            {selectedViewType === 'excel' && <ExcelView selectedSection={selectedViewType} tableView='mbom-view' sequence_id={product_id} date={date} />}
            {selectedViewType === 'tree' && <BomHierarchy selectedSection={selectedViewType} bomType='mbom-view' sequence_id={product_id} date={date} />}
        </div>


    )
}

export default MbomView