import React, { useState } from 'react'

import ApprovePopUp from './DetailsViewPopUps/ApprovePopUp';

function ApprovingStatusContainer({ ecApprovalStatus, handeSendForApproval, handleOpenApproveList, sequence_id,
    approvalMemberView, editable, memberApproved, resultApprovalMemberView, ticketId, notifyIds, ticketName,shouldRevert,setUpdatedData }
) {
  console.log(memberApproved)
    const [openApprove, setOpenApprove] = useState('')
    const [actionType, setActionType] = useState('')
    const approvePC = async (action) => {
        setActionType(action)
        setOpenApprove(!openApprove)
        // try {

        //     const headers = {
        //         'x-auth-token': Cookies.get('token'),
        //     };
        //     if (action === 'trail') {
        //         setActionType('trail')
        //         await axios.post(`${BASE_URL}/v1/prod-change/make-trail-approve`, {
        //             sequence_id,
        //             pc_id: ticketId,
        //         }, { headers: headers });
        //         setMemberApproved('trial_approved')
        //     } else if (action === 'plan') {
        //         setActionType()
        //         await axios.post(`${BASE_URL}/v1/prod-change/make-plan-approve`, {
        //             sequence_id,
        //             pc_id: ticketId,
        //         }, { headers: headers });
        //         setMemberApproved('trial_result_approved')
        //     }

        //     // toast.info(`${key} has been removed `, toastStyle);
        //     // fetchFormDetails();

        //     window.location.reload()
        // } catch (error) {
        //     console.log(error);
        // }
    }
    return (
        <>
            <div className="ticket-view-status-div" style={{ flexDirection: 'column', height: '120px' }}>
                <div className="ticket-view-status-bar" style={{ width: '90%' }}>
                    <div className="ticket-view-status-name" style={{ gap: '18%' }}>
                        <span>Trial Draft</span>
                        <span>Pending  Approval</span>
                        <span>Trial Approved</span>
                        <span>Pending Result </span>
                        <span>Result Approved</span>

                    </div>
                    <div className="ticket-view-status-name" >
                        <div className="status-circle" style={{ background: '#610bee' }}></div>
                        <div className="status-line" style={{
                            width: '30%',
                            background: (ecApprovalStatus === 'pending_for_trial_approval' ||
                                ecApprovalStatus === 'trial_approved' || ecApprovalStatus === 'trial_result_approved' ||
                                ecApprovalStatus === 'pending_for_trial_result_approval' ||
                                memberApproved === 'pending_for_trial_approval') ? '#610bee' : ''
                        }}></div>
                        <div className="status-circle" style={{
                            background: (ecApprovalStatus === 'pending_for_trial_approval' || ecApprovalStatus === 'trial_approved' || ecApprovalStatus === 'trial_result_approved' ||
                                memberApproved === 'pending_for_trial_approval' || ecApprovalStatus === 'pending_for_trial_result_approval') ? '#610bee' : ''
                        }}></div>
                        <div className="status-line" style={{
                            width: '30%', background: (ecApprovalStatus === 'trial_approved' ||
                                ecApprovalStatus === 'trial_result_approved' || ecApprovalStatus === 'pending_for_trial_result_approval') ? '#610bee' : ''
                        }}></div>


                        <div className="status-circle" style={{
                            background: (ecApprovalStatus === 'trial_approved' ||
                                ecApprovalStatus === 'trial_result_approved' || ecApprovalStatus === 'pending_for_trial_result_approval') ? '#610bee' : ''
                        }}></div>
                        <div className="status-line" style={{ width: '30%', background: (ecApprovalStatus === 'pending_for_trial_result_approval' || ecApprovalStatus === 'trial_result_approved') ? '#610bee' : '' }}></div>
                        <div className="status-circle" style={{ background: (ecApprovalStatus === 'pending_for_trial_result_approval' || ecApprovalStatus === 'trial_result_approved') ? '#610bee' : '' }}></div>
                        <div className="status-line" style={{ width: '30%', background: (ecApprovalStatus === 'trial_result_approved') ? '#610bee' : '' }}></div>
                        <div className="status-circle" style={{ background: (ecApprovalStatus === 'trial_result_approved') ? '#610bee' : '' }}></div>
                    </div>
                </div>
                <div className="ticket-view-status-buttons">
                    <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>


                        
                        {(editable && (ecApprovalStatus === '' || ecApprovalStatus === 'trial_draft')) &&
                            <button className="ticket-view-status-btn-approve" onClick={() => handeSendForApproval('trial-approvers')}>Send for trial approval</button>}

                        {(approvalMemberView) && <>
                            {(editable && ecApprovalStatus === 'pending_for_trial_approval') &&
                            <button className="ticket-view-status-btn-approve" onClick={() => handleOpenApproveList('revert-trail')}>Revert to draft</button>}
                            {(memberApproved.pc_status === 'pending_for_trial_approval') && <button className="ticket-view-status-btn-approve"
                                onClick={() => approvePC('trail')}>Approve trial</button>}
                            {(memberApproved.pc_status === 'pending_for_trial_approval') &&
                                <button onClick={() => handleOpenApproveList('reject-trail')} className="ticket-view-status-btn-reject"
                                >Reject trial</button>}
                        </>}

                        {(editable && (ecApprovalStatus === 'trial_approved')) &&
                            <button className="ticket-view-status-btn-approve" onClick={() => handeSendForApproval('plan-approvers')}> Send for plan approval</button>}
                        
                        {(resultApprovalMemberView) && <>
                            {(editable && ecApprovalStatus === 'pending_for_trial_result_approval') &&
                            <button className="ticket-view-status-btn-approve" onClick={() => handleOpenApproveList('revert-plan')}>Revert to draft</button>}
                        {(memberApproved.result_status === 'pending_for_trial_result_approval' ) && <button className="ticket-view-status-btn-approve"
                            onClick={() => approvePC('plan')}>Approve result</button>}
                        {(memberApproved.result_status === 'pending_for_trial_result_approval' ) &&
                            <button onClick={() => handleOpenApproveList('reject-plan')} className="ticket-view-status-btn-reject"
                            >Reject result</button>}
                        </> }
                        
                    </div>

                </div>
            </div>
            {openApprove && <ApprovePopUp onclose={() => setOpenApprove(!openApprove)} setUpdatedData={setUpdatedData} sequence_id={sequence_id} type={actionType} ticketId={ticketId} ticketName={ticketName} notifyIds={notifyIds} />}
        </>

    )
}

export default ApprovingStatusContainer