import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './PartAttributes.css';
import { ASSET_PREFIX_URL, BASE_URL } from '../../constants/config';
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton';
import Loading from './../Common/Loading/Loading';


function PartAttributes({ onSave, onclose, type }) {


  const [loading, setLoading] = useState(false);
  const [savedData, setSavedData] = useState(false);
  const [partAttributes, setPartAttributes] = useState(
    type !== 'mbom'
      ? [
        { id: 1, title: 'Unit of measurement', opened_status: 'Unit of measurement', options: [] },
        { id: 2, title: 'Part type', opened_status: 'type', options: [] },
        { id: 3, title: 'Part system', opened_status: 'system', options: [] },
        { id: 4, title: 'Traceability', opened_status: 'trace', options: [] }
      ]
      : []
  );


  const [showDetails, setShowDetails] = useState({


  });
  const [editAttribute, setEditAttribute] = useState(null);
  const [editValue, setEditValue] = useState('');


  useEffect(() => {
    getAttributes();
  }, [type]);


  const getAttributes = async () => {
    try {
      setLoading(true);
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      let fields
      if (type === 'part-num'|| type === 'mbom') {
        let attributeType
        if(type === 'part-num'){
          attributeType = 'ebom'
        }else if(type === 'mbom'){
          attributeType='mbom'
        }
        fields = await axios.get(
          `${BASE_URL}/v1/part-nomen/get-attributes`,
          {
            params: {
                type:attributeType
            },
            headers: headers,
        }
        );
      } else if (type === 'assets-num') {
        fields = await axios.get(
          `${BASE_URL}/v1/asset/get-attributes`,
          { headers }
        );
      }


      setPartAttributes(fields.data.data.attribute_options.data);
      if (fields.data.data.info !== '') {
        setSavedData(true)
      }
      // setTimeout(() => {
      //   setSavedData(false);
      // }, 10000);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const handleToggleDetails = (section) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };


  const handleEditAttribute = (e, index) => {
    setSavedData(false);
    e.stopPropagation();
    
    if (editAttribute === index) {
      setEditAttribute(null);
      setEditValue(''); // Clear the editValue state when exiting edit mode
    } else {
      setEditAttribute(index); // Use the index instead of the title
      // Retrieve the current value of the attribute and set it as the editValue
      const currentAttribute = partAttributes[index];
      if (currentAttribute) {
        setEditValue(currentAttribute.title);
      }
    }
  };
  




  //   const handleAttributeInputChange = (event) => {
  //     setEditValue(event.target.value); // This updates the state with the value entered into the input field
  // };
  const handleAttributeInputChange = (event, value) => {
    setSavedData(false)
    const inputValue = event.target.value;
    setEditValue(inputValue); // Update the state with the value entered into the input field
    if (inputValue === '') {
      setEditValue(''); // Clear the editValue state if the input field is empty
    }
  };






  const handleInputChange = (attributeIndex, inputIndex, event) => {
    setSavedData(false)
    const newPartAttributes = [...partAttributes];
    const inputValue = event.target.value;


    // Ensure that the inputs array is initialized before accessing its elements
    if (newPartAttributes[attributeIndex].options) {
      newPartAttributes[attributeIndex].options[inputIndex] = inputValue;
      setPartAttributes(newPartAttributes);
    }
  };


  const handleRemoveInput = (attributeIndex, inputIndex) => {
    setSavedData(false)
    const newPartAttributes = [...partAttributes];
    newPartAttributes[attributeIndex].options.splice(inputIndex, 1);
    setPartAttributes(newPartAttributes);
  };


  const handleAddInput = (attributeIndex) => {
    setSavedData(false)
    const newPartAttributes = [...partAttributes];
    const attribute = newPartAttributes[attributeIndex];


    if (attribute) {
      console.log("Attribute:", attribute);
      if (Array.isArray(attribute.options)) {
        console.log("Options:", attribute.options);
        const newId = attribute.options.length + 1;
        attribute.options.push('');
        setPartAttributes(newPartAttributes);
      } else {
        console.error("Attribute's options property is not an array:", attribute.options);
      }
    } else {
      console.error("Attribute is not properly initialized:", attribute);
    }
  };








  const handleSaveAttribute = (index, newTitle) => {
    setSavedData(false);
  
    if (editValue === '') {
      setEditValue(partAttributes[index].title); // If no new value is entered, reset the input
      setEditAttribute(null);
    } else {
      // Update the attribute at the specified index
      const updatedAttributes = partAttributes.map((attribute, attributeIndex) => {
        if (attributeIndex === index) {
          return { ...attribute, title: newTitle };
        }
        return attribute;
      });
  
      setPartAttributes(updatedAttributes); // Update the state with the modified attributes
      setEditAttribute(null); // Exit edit mode
      setEditValue(''); // Clear the input field
    }
  };
  






  const handleRemoveAttribute = (index) => {
    const newPartAttributes = [...partAttributes];
    newPartAttributes.splice(index, 1);
    setPartAttributes(newPartAttributes);
  };


  const handleAddAttribute = () => {
    const newAttribute = {
      id: Date.now(), // Assigning a unique id to the new attribute
      title: 'New Attribute',
      opened_status: `newAttribute_${Date.now()}`, // Using current timestamp for unique opened_status
      options: [],
    };
    setPartAttributes([...partAttributes, newAttribute]);
    setShowDetails({ ...showDetails, [newAttribute.title]: true });
  };


  const handleSave = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      // console.log("Data to be saved:", JSON.stringify(partAttributes.map((attribute) => ({
      //   title: attribute.title,
      //   options: attribute.inputs.map((input) => input.value),
      // }))));
      const data = JSON.stringify(partAttributes.map((attribute) => ({
        title: attribute.title,
        options: attribute.options.map((input) => input),
      })))
      if (type === 'part-num' || type === 'mbom') {
        let attributeType
        if(type === 'part-num'){
          attributeType = 'ebom'
        }else if(type === 'mbom'){
          attributeType='mbom'
        }
        await axios.post(
          `${BASE_URL}/v1/part-nomen/create-attributes`, {
          data,type :attributeType
        },
          { headers }
        );
      } else if ('assets-num') {
        await axios.post(
          `${BASE_URL}/v1/asset/create-attributes`, {
          data
        },
          { headers }
        );
      }


      // getAttributes();
      // onclose();
      // window.location.reload();
      getAttributes()
    } catch (error) {
      console.log(error);
    }


  };


const shouldShowSaveButton =
  partAttributes.length > 0 &&
  partAttributes.every(attribute =>
    attribute.options && attribute.options.some(input => input && input.trim() !== '')
  ) &&
  new Set(partAttributes.map(attribute => attribute.title)).size === partAttributes.length;




  const importAttributes = async () => {
    try {
      setLoading(true);
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      let fields
    
       
        fields = await axios.get(
          `${BASE_URL}/v1/part-nomen/get-attributes`,
          {
            params: {
                type:'ebom'
            },
            headers: headers,
        }
        );
     


      setPartAttributes(fields.data.data.attribute_options.data);
      if (fields.data.data.info !== '') {
        setSavedData(true)
      }
      // setTimeout(() => {
      //   setSavedData(false);
      // }, 10000);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    // <div className='part-attribute-popup'>
    <div className='part-attribute-div'>
      {loading ? <Loading /> : <>
        <div className='part-attribute-title'>


          <div className='part-attribute-close'>
            <div className='attribute-page-back'>
              {type !== 'mbom' && <img
                src={`${ASSET_PREFIX_URL}template-back-btn.svg`}
                alt=""
                width="24px"
                height="24px"
                onClick={onclose}
              />}


              <span className='part-attri-title'>Add {type === 'part-num' && 'part'}
                {type === 'assets-num' && 'assets'} attributes</span>
              {savedData && <div className="saved-text-part">
                <img src={`${ASSET_PREFIX_URL}cloud-save.png`} alt="" style={{ width: '24px', height: '24px' }} />
                <span >saved</span>
              </div>}


            </div>
            {/* <div className='part-attribute-save-btn'> */}
             
               {type === 'mbom' &&  <button className='mbom-attribute-save-active' onClick={importAttributes}>Import ebom attributes</button>}


            {/* </div> */}
            {/* <button>Import ebom attributes</button> */}
            {/* <PopupCloseButton handleClose={onclose} /> */}
          </div>


          {/* <button className='attribute-save-active'>Import ebom attributes</button> */}
          {type !== 'mbom' && <span style={{ paddingLeft: '45px', paddingBottom: '5px', paddingTop: '5px' }}>Step 2 of 2</span>}


          {/* <span>Step 2 of 2</span> */}
          {type !== 'mbom' && <span style={{ paddingLeft: '45px' }} className={shouldShowSaveButton ? 'part-attr-noerror' : 'part-attr-error'}>To create {type === 'part-num' && 'part'}
            {type === 'assets-num' && 'assets'} name and nomenclature, first we need to set up part attributes. Please fill the following.</span>}


        </div>
        <div className='part-attribute-options'>
          {partAttributes.map((attribute, attributeIndex) => (
            <div key={attributeIndex} className='part-attri-option-div'>
              <div className='part-attribute-options-title' onClick={() => handleToggleDetails(attributeIndex)}>
                {editAttribute === attributeIndex ? (
                  <>
                    <div className='part-title-edit-input'>
                      <input
                        type="text"
                        value={editValue}
                        // value={editValue ? editValue : attribute.title}
                        onChange={(e) => handleAttributeInputChange(e, attribute.title)}
                      />&nbsp;
                      <button className='attribute-save-active'
                        style={{ color: 'white', border: 'none', borderRadius: '8px' }} onClick={() => handleSaveAttribute(attributeIndex, editValue)}>Save</button>
                    </div>


                  </>
                ) : (
                  <>
                    <div className='part-attri-title-edit'>
                      <span >{attribute.title}</span>
                      <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: "15px", height: "15px", color: "#487BFD" }}
                        className="template-edit" onClick={(e) => handleEditAttribute(e, attributeIndex)} />
                    </div>


                    <div className='drop-delete-attribute'>
                      <img src={`${ASSET_PREFIX_URL}options-drop.png`} alt='' />
                      <img
                        src={`${ASSET_PREFIX_URL}attri-delete.png`}
                        alt='delete'
                        onClick={() => handleRemoveAttribute(attributeIndex)}
                      />
                      {/* <button className='attribute-add-attribute' >Remove Attribute</button> */}
                    </div>




                  </>
                )}
              </div>
              {showDetails[attributeIndex] && <div className={`part-attri-transition`}>
                {attribute.options && attribute.options.map((input, inputIndex) => (
                  <div key={inputIndex} className='part-attri-input'>
                    <input
                      type='text'
                      placeholder='Enter value'
                      value={input}
                      onChange={(e) => handleInputChange(attributeIndex, inputIndex, e)}
                    />
                    <img
                      src={`${ASSET_PREFIX_URL}attri-delete.png`}
                      alt='delete'
                      onClick={() => handleRemoveInput(attributeIndex, inputIndex)}
                    />
                  </div>
                ))}
                <button onClick={() => handleAddInput(attributeIndex)}>Add more
                  <img src={`${ASSET_PREFIX_URL}attribute-addmore.png`} style={{ width: '24px', height: '24px' }} /></button>
              </div>}




            </div>
          ))}
        </div>
        <div className='part-attribute-save-btn'>
          <button className='attribute-save-active' onClick={handleAddAttribute}>Add Attribute</button>&nbsp;
          {(shouldShowSaveButton) ? <button onClick={handleSave} className='attribute-save-active'>Save</button> :
            <button className='attribute-save-inactive'>Save</button>}


        </div>
      </>}




    </div>
    // </div>


  );
}


export default PartAttributes;



