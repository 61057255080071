import React, { useState, useEffect } from "react";
import {
    BASE_URL
} from "../../../constants/config";
import PopupCloseButton from "../../Common/PopupCloseButton/PopupCloseButton";
import Cookies from "js-cookie";
import axios from "axios";
import EcApproveList from "./EcApproveList";
import ErrorPartsPopUp from "./ErrorPartsPopUp";
import { toast } from 'react-toastify';

function PartsBeforePopUp({handleclose, errorParts, ticketId, sequence_id, type, onclose,errorPopUpPlose,selectedEcType}) {
    console.log(selectedEcType)
  
    const [OpenApproval, setOpenApproval] = useState(false);
    const [errorOpenPopUp, setErrorOpenPopUp] = useState(false);

    // Update partDetails when partsToBeAdded or errorParts change
    const [partsToBeAddedPop, setPartsToBeAddedPop] = useState([]);
    const [partsToBeAddedErrorPop, setPartsToBeAddedErrorPop] = useState([]);
    // Update partDetails when partsToBeAdded or errorParts change
    useEffect(()=>{
        fecthPartsToBeAdded()
    },[])
    const fecthPartsToBeAdded = async()=>{
        try {
            // setLoading(true)
            const headers = {
              "x-auth-token": Cookies.get("token"),
            };
            let approvalResponse;
            if ((type === 'ec-template' && selectedEcType === 'release_ec') || type === 'ec-deviation') {
               approvalResponse = await axios.get(
                `${BASE_URL}/v1/ec-template/validate-part-num-mapping`,
                {
                  headers,
                  params: {
                    [type === 'ec-template' ? 'ec_id' : 'dev_id']: ticketId
                  }
                }
              );
            }else if ((type === 'ec-template' && selectedEcType === 'structuring_ec')){
              approvalResponse = await axios.get(
                `${BASE_URL}/v1/ec-template/validate-structured-ec-part-num-mapping`,
                {
                  headers,
                  params: {
                    ec_sequence_id : sequence_id
                  }
                }
              );
            }
           
      
           
           
            console.log(approvalResponse.data.data)
           
            
              setPartsToBeAddedPop(approvalResponse.data.data.parts_to_be_added); 
              setPartsToBeAddedErrorPop(approvalResponse.data.data.err_parts)
            //  setLoading(false)
          } catch (error) {
            console.log(error)
            // setLoading(false)
          }
    }
    // Function to log partDetails on button click
    const handleGetApproval = async() => {
        console.log("Part details:", partsToBeAddedPop);
        console.log(partsToBeAddedPop.map(item=>item.part_number))
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
              };
           let response
            if (type === 'ec-template'&& selectedEcType !== 'structuring_ec') {
              response = await axios.post(
                `${BASE_URL}/v1/ec-template/create-part-num-mapping`,
                {
                  enchange_id: ticketId, sequence_id: sequence_id,
                  
                  part_list: partsToBeAddedPop.map(item=>item.part_number),
  
                },
                { headers: headers }

                
              );
             
              
           
              
            }else if(type === 'ec-template'&& selectedEcType === 'structuring_ec'){
              response = await axios.post(
                `${BASE_URL}/v1/structure-ec/add-parts-to-logs`,
                {
                   ec_sequence_id: sequence_id,
                  
                  part_list: partsToBeAddedPop.map(item=>item.part_number),
  
                },
                { headers: headers }

                
              );
              
                
            } else if (type === 'ec-deviation') {
              response  = await axios.post(
                `${BASE_URL}/v1/ec-deviations/create-part-number-dev`,
                {
                  deviation_id: ticketId, sequence_id: sequence_id,
                  part_list:partsToBeAddedPop.map(item=>item.part_number),
  
                },
                { headers: headers }
              );
              
            }
            if(response.data.meta.success === true){
              if (partsToBeAddedErrorPop.length > 0) {
              
                  setErrorOpenPopUp(true)
                  // handleclose()
              } else {
                  setOpenApproval(true)
              }
            }else{
              toast.error(response.data.meta.message)
            }
            
           
          } catch (error) {
            console.log(error)
          }
        // handleclose()
       
        
        // handleclose()
        // Here you can perform additional actions, such as sending the part details for approval
    };
    return (
        <>
            <div className="notification-page">
                <div className="notification-div">
                    <div className="border-bot-partsadd">
                        <div className="part-number-popup-text">
                            {/* <img src={`${ASSET_PREFIX_URL}bell_icon.png`} alt=""/> */}
                            <span>Parts to be added before {`${type === 'ec-template' ? 'EC' : 'Deviation'}`} approval</span>

                        </div>

                        <PopupCloseButton
                         handleClose={handleclose} 
                        />
                    </div>
                    <div className="part-number-popup-border"></div>


                    <div
                        className="parts-add-before"
                    // onScroll={handleScroll}
                    // ref={containerRef}
                    >
                        {partsToBeAddedPop.map((item, index) => (
                            <div
                                // key={item.part_id} 
                                className="part-numbers-views">
                                <div className="part-numbers-views-details">

                                    <div className="part-numbers-views-content">

                                    {/* <span className="part-number-view-number" style={{ cursor: 'pointer' }}
                                      >{item.part_number}</span> */}
                                      <a href={`/part-view/${item.part_number}`} target='_blank' className='link-to-view-ec'>{item.part_number}</a>
                                    <span className="part-number-view-title">{item.part_title}</span>
                                        
                                    </div>

                                </div>
                            </div>
                        ))}
                        


                    </div>
                    <button className="parts-add-before-btn" onClick={handleGetApproval}>Add these part(s) to {`${type === 'ec-template' ? 'EC' : 'Deviation'}`}</button>

                </div>
            </div>
            {errorOpenPopUp && 
            <ErrorPartsPopUp errorParts={errorParts} handleclose={errorPopUpPlose}
            closePartsAdd={handleclose} ticketId={ticketId} type={type} sequence_id={sequence_id} selectedEcType={selectedEcType}/>}
            {OpenApproval && <EcApproveList sequence_id={sequence_id} type={type} ticketId={ticketId}
            onclose={onclose}  />}
        </>

    )
}

export default PartsBeforePopUp