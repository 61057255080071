import React, { useState, useEffect } from "react";
import { ASSET_PREFIX_URL } from "../../../constants/config";

import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import "./Org.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";


// /part-list

// templateSteps={templateSteps} emptyTemplateSteps={emptyTemplateSteps} ticketSteps={ticketSteps}
// emptyTicketSteps={emptyTicketSteps}
function TicketLeftHOC({
  staredDept,

}) {
  const [showPhotos, setShowPhotos] = useState(false);

  const [dropdown, setDropDown] = useState({});
  const [smallNext, setSmallNext] = useState(false);
  const [hoverMenu, setHoverMenu] = useState(false)
  const [hoverSubMenu, setHoverSubMenu] = useState(false)


  // const [setUpShow, setSetUpShow] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const { department } = useParams();

  const { id } = useParams();
  const nav = useNavigate();


  const handleOpenDropDownMenu = (section) => {
    setDropDown((prevState) => ({
      ...prevState,
      [section]: !prevState[section], // Toggle the specific section
    }));
    setShowPhotos(true);
  };

  // const handleOpenDropDownMenu = (action) => {
  //   if (dropdown === action) {
  //     // Toggle off if the same action is clicked
  //     setDropDown("");
  //     setShowPhotos(true);
  //   } else {
  //     // Open the new dropdown
  //     setDropDown(action);
  //     setShowPhotos(true);
  //   }
  // };


  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      nav("/login");
    }

    return () => { };
  }, [nav]);

  const location = useLocation();

  const handleTogglePhotos = () => {
    setShowPhotos(!showPhotos);

    setHoverMenu(null)
    setHoverSubMenu(null)
    setDropDown('')
    if (!showPhotos) {
      setSmallNext(!smallNext);
    }
  };

  // const handleMenuItemClick = (menuItem) => {

  //   setActiveMenuItem(menuItem);

  //   if (menuItem === "teams") {

  //     nav("/org-home");

  //   } else if (menuItem === "template") {
  //     nav("/template");

  //   } else if (menuItem === "ticket") {
  //     nav("/all-list");

  //   } else if (menuItem === "overview") {
  //     nav("/overview");

  //   } else if (menuItem === "departments") {
  //     nav("/depart-list");

  //   } else if (menuItem === "analytics") {

  //   } else if (menuItem === "settings") {
  //     nav("/settings");
  //   }
  // };

  const hoveredMenu = (action) => {
    setHoverMenu(action)
  }
  const hoveredSubMenu = (subAction, action) => {
    setHoverSubMenu(subAction)
    setHoverMenu(action)
  }
  const hoveredLeaveMenu = () => {
    setHoverMenu(null)
    setHoverSubMenu(null)
  }
  const hoveredLeaveSubMenu = () => {

    setHoverSubMenu(null)
  }
  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
    // setShowPhotos(false)
    setHoverMenu(null)
    setHoverSubMenu(null)
    // /all-inventory
    // setDropDown('')
    switch (menuItem) {
      case "teams":
        nav("/org-home");
        break;
      case "part-num":

        nav("/part-number");
        break;
      case "template":
        nav("/template");
        break;
      case "inventory-list":
        nav("/all-inventory");
        break;
      case "ec-template":
        nav("/ec-template");
        break;
      case "asset-templates":
        nav("/assets-template");
        break;
      case "assets-number":
        nav("/assets-number");
        break;
      // assets-number
      case "supply-template":
        nav("/supply-chain-templates");
        break;
      case "pc-template":
        nav("/pc-templates");
        break;
        case "mbom-attributes":
          nav("/mbom-attributes");
          break;
        // mbom-attributes
      case "po-template":
        nav("/purchase-order-templates");
        break;
      case "po-number":
        nav("/po-number");
        break;
      case "ticket":

        nav("/all-list")
        break;
      case "production-list":
        nav("/production-list");
        break;
      case "work-order-list":
        nav("/work-order-list");
        break;
      case "material-list":
        nav("/material-list");
        break;
      case "parts-grn":

        nav("/all-grn-parts")
        break;
      case "supply-list":

        nav("/all-supplier-list")
        break;
      // mbom-list
      case "mbom-list":

        nav("/mbom-list")
        break;
      case "pci-list":

        nav("/pci-list")

        break;
        case "launched-parts":

        nav("/launched-parts")
        
        break;
      // pci-list
      case "pc-list":

        nav("/pc-list")
        break;
      case "pc-launched-list":

        nav("/pc-launched-list")
        break;
      case "po-list":

        nav("/all-po-list")
        break;
      case "assets-list":

        nav("/assets-list")
        break;
      case "assets-part-list":

        nav("/assets-parts-list")
        break;
      case "ec-ticket":
        nav("/all-ec-list");
        break;
      case "ec-deviation":
        nav("/all-deviation-list");
        break;
      case "overview":
        nav("/overview");
        break;
      case "departments":
        nav("/depart-list");
        break;
      case "create-part":
        nav("/part-list");
        break;
      case "analytics":
        // Handle analytics case
        break;
      case "settings":
        nav("/settings");
        break;
      default:
      // Handle default case
    }
  };



  // case "settings":
  //       window.location.pathname = "/settings";
  //       break;
  const containerStyles = {
    width: showPhotos ? "260px" : "93px",
    transition: "width 0.5s ease",
  };
  useEffect(() => {
    const pathname = location.pathname;

    if (pathname === "/org-home") {
      setActiveMenuItem("teams");
    } else if (pathname === "/part-number") {
      setActiveMenuItem("part-num");
    }
    else if (pathname.startsWith("/template")) {
      setActiveMenuItem("template");
    } else if (pathname.startsWith("/ec-template")) {
      setActiveMenuItem("ec-template");
    } else if (pathname.startsWith("/asset-template")) {
      setActiveMenuItem("asset-templates");
    } else if (pathname.startsWith("/supply-chain-templates")) {
      setActiveMenuItem("supply-template");
    } else if (pathname.startsWith("/purchase-order-templates")) {
      setActiveMenuItem("po-template");
    } else if (pathname.startsWith("/po-number")) {
      setActiveMenuItem("po-number");
    } else if (pathname.startsWith("/assets-number")) {
      setActiveMenuItem("assets-number");
    } else if (pathname.startsWith("/all-supplier-list")) {
      setActiveMenuItem("supply-list");
    } else if (pathname.startsWith("/mbom-list")) {
      setActiveMenuItem("mbom-list");
      // pci-list
    } else if (pathname.startsWith("/pci-list")) {
      setActiveMenuItem("pci-list");
      // pci-list
    }else if (pathname.startsWith("/launched-parts")) {
      setActiveMenuItem("launched-parts");
      // pci-list
    } else if (pathname.startsWith("/pc-list")) {
      setActiveMenuItem("pc-list");
    } else if (pathname.startsWith("/pc-launched-list")) {
      setActiveMenuItem("pc-launched-list");
    } else if (pathname.startsWith("/all-po-list")) {
      setActiveMenuItem("po-list");
    } else if (pathname.startsWith("/pc-templates")) {
      setActiveMenuItem("pc-template");
    
    }else if (pathname.startsWith("/mbom-attributes")) {
      setActiveMenuItem("mbom-attributes");
    
    } else if (pathname.startsWith("/assets-list")) {
      setActiveMenuItem("assets-list");
    } else if (pathname.startsWith("/production-list")) {
      setActiveMenuItem("production-list");
    } else if (pathname.startsWith("/work-order-list")) {
      setActiveMenuItem("work-order-list");
    } else if (pathname.startsWith("/material-list")) {
      setActiveMenuItem("material-list");
    } else if (pathname === "/overview") {
      setActiveMenuItem("overview");
    } else if (pathname === "/depart-list") {
      setActiveMenuItem("departments");
    } else if (pathname === "/settings") {
      setActiveMenuItem("settings");
    } else if (
      pathname === "/all-list" ||
      pathname.startsWith("/ticket-view")
    ) {
      setActiveMenuItem("ticket");
    } else if (
      pathname === "/all-ec-list" ||
      pathname.startsWith("/ticket-view")
    ) {
      setActiveMenuItem("ec-ticket");
    }
    else if (
      pathname === "/all-deviation-list" ||
      pathname.startsWith("/ticket-view")
    ) {
      setActiveMenuItem("ec-deviation");
    } else if (
      pathname === "/all-inventory" ||
      pathname.startsWith("/ticket-view")
    ) {
      setActiveMenuItem("inventory-list");
    } else if (pathname === "/part-list") {
      setActiveMenuItem("create-part");
    } else if (pathname === "/assets-part-list") {
      setActiveMenuItem("assets-part-list");
    } else if (pathname === "/all-grn-parts") {
      setActiveMenuItem("parts-grn");
    } else if (pathname === `/department-ticket/${department}/${id}`) {
      setActiveMenuItem(id);
    }
  }, [location.pathname, department, id]);

  const handleClick = (department, id) => {
    setActiveMenuItem(id);
    nav(`/department-ticket/${department}/${id}`);

    // window.location.reload();
  };
  return (

    <>
      <div className="ticket-template-page">
        <div
          className="next-btn"
          onClick={handleTogglePhotos}
          style={{ cursor: "pointer" }}
        >
          {showPhotos ? (
            <KeyboardArrowLeftRoundedIcon />
          ) : (
            <KeyboardArrowRightRoundedIcon />
          )}
        </div>
        <div
          style={containerStyles}
          className={`template-sidebar-menu ${smallNext ? "small-next" : ""} ${showPhotos ? "sidebar-expanded" : "sidebar-collapsed"
            }`}
        >





          <div className="menu">

            {showPhotos ? (
              <div
                className={`menu-details ${activeMenuItem === "teams1" ? "active" : ""
                  }`}
                onClick={() => handleOpenDropDownMenu("teams")}
                style={{ justifyContent: 'space-between' }}
              >

                {/* <span >Hierarchy</span> */}
                <div className="dropdown-part-create">
                  <img src={`${ASSET_PREFIX_URL}hr-icon.png`} alt="teams" />
                  <span>HR</span>
                </div>
                <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["teams"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />


              </div>
            ) : (
              <div
                title="HR"
                className={`menu-details ${activeMenuItem === "teams" ? "active" : ""
                  }`}
                onMouseEnter={() => hoveredMenu('hr')}
              // onClick={() => handleMenuItemClick("teams")}
              >
                <img src={`${ASSET_PREFIX_URL}hr-icon.png`} alt="teams" />


              </div>
            )}
            {dropdown["teams"] && <div
              className={`menu-details-part ${activeMenuItem === "teams" ? "active" : ""
                }`}
              onClick={() => handleMenuItemClick("teams")}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
            >
              <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

              <span>Hierarchy</span>


            </div>}

            {showPhotos ? (
              <div
                data-tour="step-template"
                className={`menu-details ${activeMenuItem === "template1" ? "active" : ""
                  }`}
                onClick={() => {
                  // handleMenuItemClick("template1");
                  handleOpenDropDownMenu('template');
                }}
                style={{ justifyContent: 'space-between' }}
              >
                {/* <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" /> */}
                <div className="dropdown-part-create">
                  <img src={`${ASSET_PREFIX_URL}tkt-icon.png`} alt="part-num" />
                  <span>Tickets</span>
                </div>
                <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["template"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

              </div>
            ) : (
              <div
                data-tour="step-template"
                title="Tickets"
                className={`menu-details ${(activeMenuItem === "template" || activeMenuItem === "ticket") ? "active" : ""
                  }`}
                onMouseEnter={() => hoveredMenu('tkt-template')}
              // onClick={() => handleOpenDropDownMenu('template')}
              >
                {/* <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" /> */}
                <img src={`${ASSET_PREFIX_URL}tkt-icon.png`} alt="part-num" />


              </div>
            )}

            {dropdown["template"] &&
              <>
                <div
                  className={`menu-details-part ${activeMenuItem === "template" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("template")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Template</span>


                </div>
                <div
                  className={`menu-details-part ${activeMenuItem === "ticket" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("ticket")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Tickets</span>


                </div>
              </>

            }






            {showPhotos ? (
              <div
                className={`menu-details ${activeMenuItem === "part-num1" ? "active" : ""
                  }`}
                onClick={() => {
                  // handleMenuItemClick("part-num1");
                  handleOpenDropDownMenu('part-num');
                }}
                style={{ justifyContent: 'space-between' }}

              >
                {/* <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" /> */}
                <div className="dropdown-part-create">
                  <img src={`${ASSET_PREFIX_URL}en-icon.png`} alt="part-num" />
                  <span>Engineering</span>
                </div>
                <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["part-num"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

              </div>
            ) : (
              <div
                title="Engineering"
                className={`menu-details ${(activeMenuItem === "ec-templates" || activeMenuItem === "ec-template" ||
                  activeMenuItem === "part-num" || activeMenuItem === "part-num" || activeMenuItem === "create-part" ||
                  activeMenuItem === "ec-ticket" || activeMenuItem === "ec-deviation"
                ) ? "active" : ""
                  }`}
                onMouseEnter={() => hoveredMenu('part-num')}
              // onClick={() => handleOpenDropDown()}
              >
                {/* <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" /> */}
                <img src={`${ASSET_PREFIX_URL}en-icon.png`} alt="part-num" />


              </div>
            )}
            {dropdown["part-num"] &&
              <>
                <div
                  className={`menu-details-part ${activeMenuItem === "ec-templates" ? "active" : ""
                    }`}
                  onClick={() => handleOpenDropDownMenu("ec-templates")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: "left" }}
                >
                  <div className="dropdown-part-create">
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>Templates</span>
                  </div>


                  <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["ec-templates"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

                </div>
                {dropdown["ec-templates"] && <>
                  <div
                    className={`menu-details-part ${activeMenuItem === "ec-template" ? "active" : ""
                      }`}
                    onClick={() => handleMenuItemClick("ec-template")}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                  >
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>EC Template</span>


                  </div>

                  <div
                    className={`menu-details-part ${activeMenuItem === "part-num" ? "active" : ""
                      }`}
                    onClick={() => handleMenuItemClick("part-num")}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                  >
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>Part naming</span>


                  </div>
                </>}

                <div
                  className={`menu-details-part ${activeMenuItem === "create-part" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("create-part")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Parts</span>


                </div>
                <div
                  className={`menu-details-part ${activeMenuItem === "ec-ticket" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("ec-ticket")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Engg. change (EC)</span>


                </div>
                {/* <div
                  className={`menu-details-part ${activeMenuItem === "ec-deviation" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("ec-deviation")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Deviation</span>


                </div> */}
              </>

            }




            {showPhotos ? (
              <div
                className={`menu-details ${activeMenuItem === "part-num1" ? "active" : ""
                  }`}
                onClick={() => {
                  // handleMenuItemClick("part-num1");
                  handleOpenDropDownMenu('assets');
                }}
                style={{ justifyContent: 'space-between' }}

              >
                {/* <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" /> */}
                <div className="dropdown-part-create">
                  <img src={`${ASSET_PREFIX_URL}assets-logo.png`} alt="part-num" />
                  <span>Assets</span>
                </div>
                <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["assets"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

              </div>
            ) : (
              <div
                title="Assets"
                className={`menu-details ${(activeMenuItem === "assets-templates") ? "active" : ""
                  }`}
                onMouseEnter={() => hoveredMenu('asset-template')}
              // onClick={() => handleOpenDropDown()}
              >
                {/* <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" /> */}
                <img src={`${ASSET_PREFIX_URL}assets-logo.png`} alt="part-num" />


              </div>
            )}
            {dropdown["assets"] &&
              <>
                <div
                  className={`menu-details-part ${activeMenuItem === "ec-templates" ? "active" : ""
                    }`}
                  onClick={() => handleOpenDropDownMenu("assets-templates")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: "left" }}
                >
                  <div className="dropdown-part-create">
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>Templates</span>
                  </div>


                  <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["assets-templates"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

                </div>
                {dropdown["assets-templates"] && <>
                  <div
                    className={`menu-details-part ${activeMenuItem === "assets-number" ? "active" : ""
                      }`}
                    onClick={() => handleMenuItemClick("assets-number")}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                  >
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>Asset naming</span>


                  </div>

                  <div
                    className={`menu-details-part ${activeMenuItem === "asset-templates" ? "active" : ""
                      }`}
                    onClick={() => handleMenuItemClick("asset-templates")}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                  >
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>Asset template</span>


                  </div>
                </>}

                <div
                  className={`menu-details-part ${activeMenuItem === "assets-part-list" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("assets-part-list")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Assets</span>


                </div>

              </>

            }


            {showPhotos ? (
              <div
                className={`menu-details ${activeMenuItem === "part-num1" ? "active" : ""
                  }`}
                onClick={() => {
                  // handleMenuItemClick("part-num1");
                  handleOpenDropDownMenu('supply-chain');
                }}
                style={{ justifyContent: 'space-between' }}

              >
                {/* <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" /> */}
                <div className="dropdown-part-create">
                  <img src={`${ASSET_PREFIX_URL}supply-icon.png`} alt="part-num" />
                  <span>Supply chain</span>
                </div>
                <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["supply-chain"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

              </div>
            ) : (
              <div
                title="Supply chain"
                className={`menu-details ${activeMenuItem === "part-num1" ? "active" : ""
                  }`}
                onMouseEnter={() => hoveredMenu('supply-chain')}
              // onClick={() => handleOpenDropDown()}
              >
                {/* <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" /> */}
                <img src={`${ASSET_PREFIX_URL}supply-icon.png`} alt="part-num" />


              </div>
            )}

            {dropdown["supply-chain"] &&
              <>
                <div
                  className={`menu-details-part ${activeMenuItem === "supply-template" ? "active" : ""
                    }`}
                  onClick={() => handleOpenDropDownMenu("supply-template")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: "left" }}
                >
                  <div className="dropdown-part-create">
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>Templates</span>
                  </div>


                  <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["supply-template"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

                </div>
                {dropdown['supply-template'] &&
                  <>

                    <div
                      className={`menu-details-part ${activeMenuItem === "supply-template" ? "active" : ""
                        }`}
                      onClick={() => handleMenuItemClick("supply-template")}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                    >
                      <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                      <span>Supplier</span>


                    </div>
                    {/* <div
                      className={`menu-details-part ${activeMenuItem === "part-num5559" ? "active" : ""
                        }`}
                      // onClick={() => handleMenuItemClick("part-num")}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                    >
                      <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                      <span>RFQ</span>


                    </div> */}
                    <div

                      className={`menu-details-part ${activeMenuItem === "po-number" ? "active" : ""}`} onClick={() => handleMenuItemClick("po-number")}
                      // onClick={() => handleMenuItemClick("part-num")}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                    >
                      <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                      <span>PO number</span>


                    </div>
                    <div
                      className={`menu-details-part ${activeMenuItem === "po-template" ? "active" : ""
                        }`}
                      onClick={() => handleMenuItemClick("po-template")}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                    >
                      <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                      <span>PO</span>


                    </div>
                  </>}

                <div
                  className={`menu-details-part ${activeMenuItem === "supply-list" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("supply-list")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Suppliers</span>


                </div>

                <div
                  className={`menu-details-part ${activeMenuItem === "po-list" ? "active" : ""
                    }`}
                  onClick={() => handleOpenDropDownMenu("po-list")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: "left" }}
                >
                  <div className="dropdown-part-create">
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>PO</span>
                  </div>


                  <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["po-list"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

                </div>
                {dropdown['po-list'] &&
                  <>

                    <div
                      className={`menu-details-part ${activeMenuItem === "po-list" ? "active" : ""
                        }`}
                      onClick={() => handleMenuItemClick("po-list")}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                    >
                      <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                      <span>Parts PO</span>


                    </div>
                    <div
                      className={`menu-details-part ${activeMenuItem === "assets-list" ? "active" : ""
                        }`}
                      onClick={() => handleMenuItemClick("assets-list")}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                    >
                      <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                      <span>Assets PO</span>


                    </div>


                  </>}
                <div
                  className={`menu-details-part ${activeMenuItem === "all-grn" ? "active" : ""
                    }`}
                  onClick={() => handleOpenDropDownMenu("all-grn")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: "left" }}
                >
                  <div className="dropdown-part-create">
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>GRN</span>
                  </div>


                  <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["all-grn"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

                </div>
                {dropdown['all-grn'] &&
                  <>

                    <div
                      className={`menu-details-part ${activeMenuItem === "parts-grn" ? "active" : ""
                        }`}
                      onClick={() => handleMenuItemClick("parts-grn")}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                    >
                      <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                      <span>Parts GRN</span>


                    </div>
                    {/* <div
                      className={`menu-details-part ${activeMenuItem === "part-num5559" ? "active" : ""
                        }`}
                      // onClick={() => handleMenuItemClick("part-num")}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                    >
                      <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                      <span>Assets GRN</span>


                    </div> */}

                  </>}
                <div
                  className={`menu-details-part ${activeMenuItem === "inventory-list" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("inventory-list")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Inventory</span>


                </div>
              </>
            }


            {showPhotos ? (
              <div
                className={`menu-details ${activeMenuItem === "part-num1" ? "active" : ""
                  }`}
                onClick={() => {
                  // handleMenuItemClick("part-num1");
                  handleOpenDropDownMenu('manufacturing');
                }}
                style={{ justifyContent: 'space-between' }}

              >
                {/* <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" /> */}
                <div className="dropdown-part-create">
                  <img src={`${ASSET_PREFIX_URL}manufacturing.svg`} alt="part-num" />
                  <span>Manufacturing</span>
                </div>
                <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["manufacturing"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

              </div>
            ) : (
              <div
                title="manufacturing"
                className={`menu-details ${activeMenuItem === "part-num1" ? "active" : ""
                  }`}
                onMouseEnter={() => hoveredMenu('manufacturing')}
              // onClick={() => handleOpenDropDown()}
              >
                {/* <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" /> */}
                <img src={`${ASSET_PREFIX_URL}manufacturing.svg`} alt="part-num" />


              </div>
            )}
            {dropdown["manufacturing"] &&
              <>

                <div
                  className={`menu-details-part ${activeMenuItem === "ec-templates" ? "active" : ""
                    }`}
                  onClick={() => handleOpenDropDownMenu("pc-templates")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: "left" }}
                >
                  <div className="dropdown-part-create">
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>Templates</span>
                  </div>


                  <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown["ec-templates"] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />

                </div>
                {dropdown["pc-templates"] && <>
                  <div
                    className={`menu-details-part ${activeMenuItem === "pc-template" ? "active" : ""
                      }`}
                    onClick={() => handleMenuItemClick("pc-template")}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                  >
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>PC Template</span>
                  </div>
                  <div
                    className={`menu-details-part ${activeMenuItem === "mbom-attributes" ? "active" : ""
                      }`}
                    onClick={() => handleMenuItemClick("mbom-attributes")}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left", marginLeft: '50px' }}
                  >
                    <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                    <span>MBOM attributes</span>
                  </div>
                </>}


                <div
                  className={`menu-details-part ${activeMenuItem === "pc-list" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("pc-list")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>PC</span>


                </div>
                <div
                  className={`menu-details-part ${activeMenuItem === "pci-list" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("pci-list")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>PCI</span>


                </div>
                <div
                  className={`menu-details-part ${activeMenuItem === "launched-parts" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("launched-parts")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Launched parts</span>


                </div>
                <div
                  className={`menu-details-part ${activeMenuItem === "mbom-list" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("mbom-list")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Products</span>


                </div>
                <div
                  className={`menu-details-part ${activeMenuItem === "production-list" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("production-list")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Production schedule</span>


                </div>
                <div
                  className={`menu-details-part ${activeMenuItem === "work-order-list" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("work-order-list")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Work order</span>


                </div>
                <div
                  className={`menu-details-part ${activeMenuItem === "material-list" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("material-list")}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                >
                  <img src={`${ASSET_PREFIX_URL}part-num.svg`} alt="part-num" style={{ visibility: 'hidden' }} />

                  <span>Material planning</span>


                </div>
              </>
            }


          </div>
          {showPhotos ? (
            <div className="horizontalline"></div>
          ) : (
            <div className="horizontalline1"></div>
          )}

          {/* department-details */}
          <div>
            {showPhotos ? (
              <div
                data-tour="step-depart"
                className={`menu-details ${activeMenuItem === "departments" ? "active" : ""
                  }`}
                onClick={() => handleMenuItemClick("departments")}
              >
                <div className="program-template">
                  <img src={`${ASSET_PREFIX_URL}programs.svg`} alt="template" />
                  <span>Departments</span>
                </div>
              </div>
            ) : (
              <div
                title="departments"
                className={`menu-details ${activeMenuItem === "departments" ? "active" : ""
                  }`}
                onMouseEnter={() => hoveredLeaveMenu('supply-chain')}
                onClick={() => handleMenuItemClick("departments")}
              >
                <img src={`${ASSET_PREFIX_URL}programs.svg`} alt="template" />
              </div>
            )}
            {showPhotos ? (
              staredDept.map((name, index) => (
                <div
                  className={`prg-org ${activeMenuItem === name.departmentID ? "active" : ""
                    }`}
                  key={index}

                  onClick={() =>
                    handleClick(name.departmentName, name.departmentID)
                  }
                >
                  <span>{name.departmentName}</span>
                </div>
              ))
            ) : (
              <div className="prg-org">
                <span></span>
              </div>
            )}

          </div>
        </div>
      </div>
      {hoverMenu === 'hr' && <div className='menu-absolute-div' style={{ top: '100px' }} onMouseLeave={() => hoveredLeaveMenu('hr')}>
        <div className={`absolute-menus ${activeMenuItem === "teams" ? "active" : ""}`} onClick={() => handleMenuItemClick("teams")}><span >Hierarchy</span></div></div>}
      {hoverMenu === 'tkt-template' && <div className="menu-absolute-div" style={{ top: '170px' }}
        onMouseLeave={() => hoveredLeaveMenu('tkt-template')}>
        <div className={`absolute-menus ${activeMenuItem === "template" ? "active" : ""}`}
          onClick={() => handleMenuItemClick("template")}><span >Templates</span></div>
        <div
          className={`absolute-menus ${activeMenuItem === "ticket" ? "active" : ""}`}
          onClick={() => handleMenuItemClick("ticket")}
        >
          <span>Tickets</span>
        </div>

      </div>}
      {hoverMenu === 'part-num' && <div className="menu-absolute-div-sub" style={{ top: '200px' }}
        onMouseLeave={() => hoveredLeaveMenu('supply-template')}
      >
        {hoverMenu === 'part-num' && (
          <div className="menu-absolute-div-list">
            <div
              className="absolute-menus"
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              onMouseEnter={() => hoveredSubMenu('part-template', 'part-num')}
            >
              <span>Templates</span>
              <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" style={{ transform: 'rotate(270deg)' }} />
            </div>
            <div
              className={`absolute-menus ${activeMenuItem === "create-part" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("create-part")}
              onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}
            >
              <span>Parts</span>
            </div>
            <div
              className={`absolute-menus ${activeMenuItem === "ec-ticket" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("ec-ticket")}
              onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}
            >
              <span>Engg. change (EC)</span>
            </div>
            {/* <div
              className={`absolute-menus ${activeMenuItem === "ec-deviation" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("ec-deviation")}
              onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}
            >
              <span>Deviation</span>
            </div> */}
          </div>
        )}
        {hoverSubMenu === 'part-template' && (
          <div className="menu-absolute-div-sub" style={{ top: '10px', left: '250px', background: 'white', borderRadius: '12px', width: '230px', flexDirection: 'column', boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.20)' }} onMouseEnter={() => hoveredSubMenu('part-template', 'part-num')}>
            <div
              className={`absolute-menus ${activeMenuItem === "part-num" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("part-num")}
            >
              <span>Part naming</span>
            </div>
            <div
              className={`absolute-menus ${activeMenuItem === "ec-template" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("ec-template")}
            >
              <span>EC</span>
            </div>
          </div>
        )}


      </div>}



      {hoverMenu === 'asset-template' && <div className="menu-absolute-div-sub" style={{ top: '260px' }}
        onMouseLeave={() => hoveredLeaveMenu('supply-template')}
      >
        {hoverMenu === 'asset-template' && (
          <div className="menu-absolute-div-list">
            <div
              className="absolute-menus"
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              onMouseEnter={() => hoveredSubMenu('asset-templates', 'asset-template')}
            >
              <span>Templates</span>
              <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" style={{ transform: 'rotate(270deg)' }} />
            </div>
            <div
              className={`absolute-menus ${activeMenuItem === "assets-part-list" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("assets-part-list")}
              onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}
            >
              <span>Assets</span>
            </div>

          </div>
        )}
        {hoverSubMenu === 'asset-templates' && (
          <div className="menu-absolute-div-list" style={{ height: '80px' }} onMouseEnter={() => hoveredSubMenu('asset-templates', 'asset-template')}>
            <div
              className={`absolute-menus ${activeMenuItem === "assets-number" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("assets-number")}
            >
              <span>Asset naming</span>
            </div>
            <div
              className={`absolute-menus ${activeMenuItem === "asset-templates" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("asset-templates")}
            >
              <span>Asset template</span>
            </div>
          </div>
        )}


      </div>}


      <div>


      </div>

      {hoverMenu === 'supply-chain' && <div className="menu-absolute-div-sub" style={{ top: '320px' }}
        onMouseLeave={() => hoveredLeaveMenu('supply-template')}>
        {hoverMenu === 'supply-chain' && <div className="menu-absolute-div-list"
        // onMouseLeave={() => hoveredLeaveMenu('supply-template')}
        >
          <div className="absolute-menus" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onMouseEnter={() => hoveredSubMenu('supply-template', 'supply-chain')}

          >
            {/* supply-list */}
            <span >Templates</span>
            <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" style={{ transform: 'rotate(270deg)' }} /></div>
          <div className={`absolute-menus ${activeMenuItem === "supply-list" ? "active" : ""}`} onClick={() => handleMenuItemClick("supply-list")} onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}><span >Suppliers</span></div>

          <div className="absolute-menus" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onMouseEnter={() => hoveredSubMenu('po-list', 'supply-chain')}

          >
            {/* supply-list */}
            <span >PO</span>
            <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" style={{ transform: 'rotate(270deg)' }} /></div>


          <div className="absolute-menus" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onMouseEnter={() => hoveredSubMenu('all-grn', 'supply-chain')}

          >
            {/* supply-list */}
            <span >GRN</span>
            <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" style={{ transform: 'rotate(270deg)' }} /></div>
          <div className={`absolute-menus ${activeMenuItem === "inventory-list" ? "active" : ""}`} onMouseEnter={() => hoveredLeaveSubMenu('inventory-list')} onClick={() => handleMenuItemClick("inventory-list")}><span >Inventory</span></div>
        </div>}
        {hoverSubMenu === 'supply-template' && <div className="menu-absolute-div-list"
          onMouseEnter={() => hoveredSubMenu('supply-template', 'supply-chain')}


        >
          <div className={`absolute-menus ${activeMenuItem === "supply-template" ? "active" : ""}`} onClick={() => handleMenuItemClick("supply-template")}><span >Supplier</span></div>
          {/* <div className="absolute-menus"><span >RFQ</span></div> */}
          <div className={`absolute-menus ${activeMenuItem === "po-number" ? "active" : ""}`} onClick={() => handleMenuItemClick("po-number")}><span >PO number</span></div>
          <div className={`absolute-menus ${activeMenuItem === "po-template" ? "active" : ""}`} onClick={() => handleMenuItemClick("po-template")}><span >PO</span></div>
        </div>}
        {hoverSubMenu === 'all-grn' && <div className="menu-absolute-div-sub" style={{ top: '140px', left: '250px', background: 'white', borderRadius: '12px', width: '230px', flexDirection: 'column', boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.20)' }}
          onMouseEnter={() => hoveredSubMenu('all-grn', 'supply-chain')}


        >
          <div className={`absolute-menus ${activeMenuItem === "parts-grn" ? "active" : ""}`} onClick={() => handleMenuItemClick("parts-grn")}><span >Parts GRN</span></div>
          {/* <div className={`absolute-menus ${activeMenuItem === "po-nuzmber" ? "active" : ""}`} onClick={() => handleMenuItemClick("po-number")}><span >Assets GRN</span></div> */}

        </div>}
        {hoverSubMenu === 'po-list' && <div className="menu-absolute-div-sub" style={{ top: '95px', left: '250px', background: 'white', borderRadius: '12px', width: '230px', flexDirection: 'column', boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.20)' }}
          onMouseEnter={() => hoveredSubMenu('po-list', 'supply-chain')}


        >
          <div className={`absolute-menus ${activeMenuItem === "po-list" ? "active" : ""}`}
            onClick={() => handleMenuItemClick("po-list")}><span >Parts PO</span></div>
          <div className={`absolute-menus ${activeMenuItem === "assets-list" ? "active" : ""}`}
            onClick={() => handleMenuItemClick("assets-list")}><span >Assets PO</span></div>

        </div>}

      </div>}

      {hoverMenu === 'manufacturing' && <div className="menu-absolute-div-sub" style={{ top: '300px' }}
        onMouseLeave={() => hoveredLeaveMenu('supply-template')}>
        {hoverMenu === 'manufacturing' && <div className="menu-absolute-div-list"
        // onMouseLeave={() => hoveredLeaveMenu('supply-template')}
        >
          <div
            className="absolute-menus"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onMouseEnter={() => hoveredSubMenu('pc-template', 'manufacturing')}
          >
            <span>Templates</span>
            <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" style={{ transform: 'rotate(270deg)' }} />
          </div>

          <div className={`absolute-menus ${activeMenuItem === "pc-list" ? "active" : ""}`}
            onClick={() => handleMenuItemClick("pc-list")} onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}><span >
              PC</span></div>
          <div className={`absolute-menus ${activeMenuItem === "pci-list" ? "active" : ""}`}
            onClick={() => handleMenuItemClick("pci-list")} onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}><span >
              PCI</span></div>
              <div className={`absolute-menus ${activeMenuItem === "launched-parts" ? "active" : ""}`}
            onClick={() => handleMenuItemClick("launched-parts")} onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}><span >
              Launched parts</span></div>
          <div className={`absolute-menus ${activeMenuItem === "mbom-list" ? "active" : ""}`}
            onClick={() => handleMenuItemClick("mbom-list")} onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}><span >
              Products</span></div>
          <div className={`absolute-menus ${activeMenuItem === "production-list" ? "active" : ""}`}
            onClick={() => handleMenuItemClick("production-list")} onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}><span >
              Production schedule</span></div>
          <div className={`absolute-menus ${activeMenuItem === "work-order-list" ? "active" : ""}`}
            onClick={() => handleMenuItemClick("work-order-list")} onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}><span >
              Work order</span></div>
          <div className={`absolute-menus ${activeMenuItem === "material-list" ? "active" : ""}`}
            onClick={() => handleMenuItemClick("material-list")} onMouseEnter={() => hoveredLeaveSubMenu('supply-template')}><span >
              Material planning</span></div>

        </div>

        }


        {hoverSubMenu === 'pc-template' && (
          <div className="menu-absolute-div-sub"
            style={{ top: '10px', left: '250px', background: 'white', borderRadius: '12px', width: '230px', flexDirection: 'column', boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.20)' }}
            onMouseEnter={() => hoveredSubMenu('pc-template', 'manufacturing')}>
            <div
              className={`absolute-menus ${activeMenuItem === "pc-template" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("pc-template")}
            >
              <span>PC template</span>
            </div>
            <div
              className={`absolute-menus ${activeMenuItem === "mbom-attributes" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("mbom-attributes")}
            >
              <span>MBOM attributes</span>
            </div>
            
          </div>
          
        )}

        {hoverSubMenu === 'pc-lists' && (
          <div className="menu-absolute-div-sub"
            style={{ top: '60px', left: '250px', background: 'white', borderRadius: '12px', width: '230px', flexDirection: 'column', boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.20)' }}
            onMouseEnter={() => hoveredSubMenu('pc-lists', 'manufacturing')}>
            <div
              className={`absolute-menus ${activeMenuItem === "pc-list" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("pc-list")}
            >
              <span>Test Phase</span>
            </div>
            <div
              className={`absolute-menus ${activeMenuItem === "pc-launched-list" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("pc-launched-list")}
            >
              <span>Launch Phase</span>
            </div>
          </div>
        )}

      </div>}



    </>

  );
}

export default TicketLeftHOC;