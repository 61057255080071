import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";

function InventoryPopUps({ selectedOption, handleCloseInventory, data }) {
    const [reason, setReason] = useState('');
    const [quantity, setQuantity] = useState('');
    const [error, setError] = useState('');

    const handleConfirm = () => {
        // Handle confirm action
        console.log('Confirmed with reason:', reason);
    };

    const handleClose = () => {
        handleCloseInventory();
    };

    const addInventory = async () => {
        try {
            const parsedQuantity = parseInt(quantity);
            if (isNaN(parsedQuantity) || parsedQuantity <= 0) {
                setError("Quantity must be greater than zero.");
                return;
            }

            if (selectedOption === 'INVENTORY_MANUAL' && parsedQuantity > data.quantity) {
                setError("Quantity cannot exceed available inventory.");
                return; // Exit function without saving
            }

            const headers = {
                "x-auth-token": Cookies.get("token"),
            };

            const response = await axios.post(
                BASE_URL + "/v1/inventory/inventory-out-missing",
                {
                    part_number: data.part_number,
                    quantity: parsedQuantity,
                    reason: reason,
                    action_type: selectedOption,
                },
                {
                    headers: headers,
                }
            );

            console.log(response);
            handleCloseInventory();
            window.location.reload();
        } catch (error) {
            console.error("Error adding inventory:", error);
        }
    };

    return (
        <div className='create-part-number-popup-page'>
            {selectedOption === 'INVENTORY_MANUAL' &&
                <div className="inventory-model-popup">
                    <div className="modal-header">
                        <h2>Inventory out</h2>
                        <div onClick={handleClose} className="closing-notif">
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="inventory-modal-body">
                        <table>
                            <thead>
                                <tr>
                                    <th>Part number</th>
                                    <th>Inventory</th>
                                    <th>Out qty</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.part_number}</td>
                                    <td>{data.quantity}</td>
                                    <td>
                                        <input
                                            type="number"
                                            className='inventory-quantity-input'
                                            value={quantity}
                                             placeholder='+Qty'
                                            onChange={(e) => setQuantity(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                            placeholder="Enter reason"
                                            className='inventory-reason-input'
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {error && <span className='error-message'>{error}</span>}
                    <div className="inventory-modal-btns">
                        <button className="inventory-go-back-button" onClick={handleClose}>Go back</button>
                        <button className="inventory-confirm-button" onClick={addInventory}>Confirm</button>
                    </div>
                </div>
            }
            {selectedOption === 'MISSING' &&
                <div className="inventory-model-popup">
                    <div className="modal-header">
                        <h2>Missing qty</h2>
                        <div onClick={handleClose} className="closing-notif">
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="inventory-modal-body">
                        <table>
                            <thead>
                                <tr>
                                    <th>Part number</th>
                                    <th>Inventory</th>
                                    <th>Add</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.part_number}</td>
                                    <td>{data.quantity}</td>
                                    <td>
                                        <input
                                            type="number"
                                            className='inventory-quantity-input'
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            placeholder='+Qty'
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                            placeholder="Enter reason"
                                            className='inventory-reason-input'
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {error && <span className='error-message'>{error}</span>}
                    <div className="inventory-modal-btns">
                        <button className="inventory-go-back-button" onClick={handleClose}>Go back</button>
                        <button className="inventory-confirm-button" onClick={addInventory}>Confirm</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default InventoryPopUps;
