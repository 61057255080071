

import React, { useState, useEffect } from "react";
import '../DepartmentPages/DepartmentForm.css'
import axios from "axios";
import Cookies from "js-cookie";
import Select from "react-select";
import {
    PHOTO_LINK,
    TICKET_ATTACHMENT_BUCKET,
    ASSET_PREFIX_URL,
    BASE_URL,
} from "./../../constants/config";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import NameProfile from "../Common/CommonJsx/NameProfile";
import { useNavigate } from 'react-router-dom';
import AttachmentsFunction from "../Common/CommonJsx/AttachmentsFunction";
import EcPartsForm from "./EcPartsForm";
import StructuringEcParts from "./StructuringEcParts";

const optionsOfECtype = [
    { value: 'release_ec', label: 'Release EC' },
    { value: 'structuring_ec', label: 'Structuring EC' }
];


function EcPageForm({ onClose, templateType }) {

    const [formData, setFormData] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileFormat, setFileFormat] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState({});
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [selectMember, setSelectMember] = useState([])
    const [allMembers, setAllMembers] = useState([])
    const [ecTitles, setEcTitles] = useState([])
    const [partOptions, setPartOptions] = useState([])
    const [options, setOptions] = useState([]);
    // const [deptName, setDeptName] = useState('')
    const [label, setLabel] = useState([]);
    const [type, setType] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({});
    const [templateId, setTemplateId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [fileSizeError, setFileSizeError] = useState("");
    const [uploadError, setUploadError] = useState("");
    const [owner, setOwner] = useState({});

    const [openNextStep, setOpenNextStep] = useState(false);
    const [selectedEcType, setSelectedType] = useState(null);

    const handleSelectEcType = (option) => {
        setSelectedType(option.value);
        console.log('Selected option:', option);
    };



    useEffect(() => {
        fetchTicketDetails();
    }, []);
    const nav = useNavigate()
    //   const { department } = useParams();
    const fetchTicketDetails = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            let response
            if (templateType === 'pc-template') {
                response = await axios.get(
                    `${BASE_URL}/v1/prod-change/get-prod-change-fields`,
                    { headers: headers }
                );
            } else if (templateType === 'pci-view') {
                response = await axios.get(
                    `${BASE_URL}/v1/manufac-bom/get-pci-fields`,
                    { headers: headers }
                );
            }
            else {
                response = await axios.get(
                    `${BASE_URL}/v1/ec-template/get-ec-fields`,
                    { headers: headers }
                );
            }
            console.log(response)
            if (!response) {
                // Display an alert and close the component
                // alert('No fields found. Unable to create ticket.');
                onClose(); // Assuming onClose is a function to close the component
                return;
            }
            if (templateType !== 'pci-view') {
                setOwner(response.data.data.profile_details);
                handleFieldChange("Owner", response.data.data.profile_details._id);
                setTemplateId(response.data.data.fields[0].template_id);
                // setDeptName(response.data.data.departmentNames);

                const filteredFields = response.data.data.fields.filter(
                    (field) =>
                        field.is_default_template === true ||
                        field.is_default_template === undefined
                );
                //  const combinedArray = [...fieldsWithUndefinedOrTrue,...customFields]
                //  console.log(combinedArray)
                setLabel(filteredFields);
                // console.log(response.data.data.fields)
                setType(response.data.data.fields.map((item) => item.fieldName));
            } else if (templateType === 'pci-view') {
                const filteredFields = response.data.data.fields.filter(
                    (field) =>
                        field.is_default_template === true ||
                        field.is_default_template === undefined
                );
                setLabel(response.data.data.fields)
            }
            // console.log(response.data.data.fields.length)

        } catch (error) {
            //   toast.info(
            //     `please create a template to create a ticket for ${department}`
            //   );
            onClose();
            console.error("Error fetching options data:", error);
        }
    };
    const getFileFormat = (fileName) => {
        const parts = fileName.split(".");
        if (parts.length > 1) {
            return parts[parts.length - 1].toUpperCase();
        }
        return "Unknown";
    };

    let inputRef = React.createRef();

    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const handleFileDrop = async (event) => {
        event.preventDefault();

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            handleFile(file);
        }
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        handleFile(file);
    };

    const handleFile = async (file) => {
        setFileSizeError("");
        setUploadError("");
        const fileSizeMB = file.size / (1024 * 1024); // size in MB
        if (fileSizeMB > 2) {
            setFileSizeError("File size cannot be more than 2 MB"); // Notify the user
            return; // Exit the function
        }
        //fetch pre signed URL
        setIsLoading(true);
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const preSignedURL = await axios.post(
                `${BASE_URL}/v1/member/get-presigned-url`,
                { bucket_name: TICKET_ATTACHMENT_BUCKET, file: file.name },
                { headers: headers }
            );
            if (
                preSignedURL.data.meta.code === 200 &&
                preSignedURL.data.meta.message === "SUCCESS" &&
                preSignedURL.data.data.url
            ) {
                //upload to s3
                await axios.put(preSignedURL.data.data.url, file, {
                    headers: {
                        "Content-Type": file.type,
                    },
                });
                // file.name = preSignedURL.data.data.file_name;
                handleFieldChange("file", file.name);
                setFileFormat(getFileFormat(file.name));
                setUploadedFiles((prevUploadedFiles) => [
                    ...prevUploadedFiles,
                    { name: file.name, key: preSignedURL.data.data.key },
                ]);
            } else {
                alert("Error generating signed URL");
            }
        } catch (e) {
            setUploadError("Error in uploading file");
        } finally {
            // Set isLoading back to false after upload (whether success or failure)
            setIsLoading(false);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();

    };




    const handleSubmit = (event) => {
        event.preventDefault();

        Object.entries(formData).map(([type, value]) => ({
            type,
            value,
        }));
    };

    const handleNextStepToParts = () => {
        const requiredFields = label.filter(
            (field) =>
                (field.is_mandatory || field.is_default) &&
                field.inputType !== "comments" &&
                field.fieldName !== "Owner"
        );



        const errors = {};
       
        requiredFields.forEach((field) => {
            if (field.inputType !== "attachment" && field.fieldName !== "Select part numbers" && field.inputType !== "select-person" && !formData[field.fieldName]) {
                errors[field.fieldName] = "This field is required.";
            }
            if (field.inputType === "select-person" ) {
                // if (field.fieldName === "Approval list") {
                    if (!selectedMembers[field.fieldName] || selectedMembers[field.fieldName].length < 2) {
                        errors[field.fieldName] = 'At least 2 members are required for this field.';
                    }
                // }
            }

            // Exclude specific fields when templateType is "ec-template"
            if (templateType === "ec-template") {
                if (field.fieldName === "Deviation end date" || field.fieldName === "Select EC" || field.fieldName === 'Deviation Title') {
                    delete errors[field.fieldName]; // Remove error message for these fields
                }
            }
            if (templateType === "ec-deviation" || templateType === "pc-template") {
                if (field.fieldName === "EC Title") {
                    delete errors[field.fieldName]; // Remove error message for these fields
                }
            }if(templateType === "ec-deviation" ){
                delete errors["Select EC"];
            } if (templateType === "pc-template") {
                // Remove any errors related to 'Select EC' and 'Trail results'
                delete errors["Select EC"];
                delete errors["Trial results"];
            }
        });
        setFieldErrors(errors);
        if (Object.keys(errors).length === 0 && (templateType !== 'ec-template' || (templateType === 'ec-template' && selectedEcType))) {
            if (templateType === 'ec-template' && selectedEcType === 'structuring_ec') {

                setOpenNextStep('structure-parts')
            } else {

                setOpenNextStep('release-parts')
            }

        }

    }
    const handlePrevStepToParts = () => {
        setOpenNextStep(false)
    }
    const handleCreate = async (event, typeEC, selectedParts) => {
        event.preventDefault();
        console.log(selectedParts)

        const ticketData = {};
        const defaultFieldsData = {};

        label.forEach((field, index) => {
            let fieldName = field.fieldName.replace(/\s+/g, "_").toLowerCase(); // Replace spaces with underscores

            if (field.inputType === "attachment") {
                // Handling attachment fields
                defaultFieldsData[fieldName.toString()] = {
                    value: uploadedFiles.map((file) => {
                        return { name: file.name, key: file.key };
                    }),
                    is_default: field.is_default,
                    inputType: field.inputType,
                    fieldName: field.fieldName,
                    order: index + 1,
                };
            }

            else if (field.inputType === "person") {
                // Handling person fields
                let selectedPerson;
                if (field.fieldName === "Owner") {
                    selectedPerson = [
                        {
                            photo: owner.photo,
                            _id: owner.id,
                            fullName: owner.name,
                        },
                    ];
                } else {
                    let selectedMember = formData[field.fieldName];
                    selectedPerson = allMembers.filter(
                        (member) => member._id === selectedMember
                    );
                }
                // Adding selected person data to ticketData or defaultFieldsData based on conditions
                if (selectedPerson && selectedPerson.length > 0) {
                    let value = {
                        photo: selectedPerson[0].photo,
                        member_id: selectedPerson[0]._id,
                        fullName: selectedPerson[0].fullName,
                    };
                    if (field.is_default) {
                        defaultFieldsData[fieldName.toString()] = {
                            value,
                            is_default: true,
                            inputType: field.inputType,
                            fieldName: field.fieldName,
                            order: index + 1,
                        };
                    } else {
                        ticketData[fieldName.toString()] = {
                            value,
                            is_default: false,
                            inputType: field.inputType,
                            fieldName: field.fieldName,
                            order: index + 1,
                        };
                    }
                }
            }else if (field.fieldName === "Trial results") {
                // Handling person fields
                
                    if (field.is_default) {
                        defaultFieldsData[fieldName.toString()] = {
                            value:'',
                            is_default: true,
                            inputType: field.inputType,
                            fieldName: field.fieldName,
                            placeholder:field.placeholder,
                            order: index + 1,
                        };
                    
                }
            } else if (field.inputType === "select-person") {

                // Handling approval list fields
                // Assuming selectedMembers contains the IDs of selected members
                // let selectedPerson = selectMember.filter(member => selectedMembers.includes(member._id));

                // Convert selected members to the desired format with member_id as the key
                let value = selectedMembers[field.fieldName].map(member => ({
                    member_id: member._id,
                    photo: member.photo,
                    fullName: member.fullName,
                    status: ''
                }));

                // Add the selected members data to ticketData or defaultFieldsData based on conditions
                if (field.is_default) {
                    defaultFieldsData[fieldName.toString()] = {
                        value,
                        is_default: true,
                        inputType: field.inputType,
                        fieldName: field.fieldName,
                        order: index + 1,
                    };
                } else {
                    ticketData[fieldName.toString()] = {
                        value,
                        is_default: false,
                        inputType: field.inputType,
                        fieldName: field.fieldName,
                        order: index + 1,
                    };
                }
            } else {
                // Handling other types of fields
                const fieldData = {
                    value: formData[field.fieldName],
                    is_default: field.is_default,
                    inputType: field.inputType,
                    fieldName: field.fieldName,
                    option: field.options,
                    order: index + 1,
                };
                if (field.is_default) {
                    defaultFieldsData[fieldName.toString()] = fieldData;
                } else {
                    ticketData[fieldName.toString()] = fieldData;
                }
            }
        });

        // Convert to JSON string
        const hasTicketData = Object.keys(ticketData).length > 0;
        const jsonData = {
            defaultFieldsData: { ...defaultFieldsData },
            customFieldsData: hasTicketData ? ticketData : {},
        };
        console.log(jsonData)
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            let endpoint;
            let navigateTo
            if (templateType === 'ec-template') {
                endpoint = 'ec-template'
                navigateTo = 'ec-ticket-view'
            } else if (templateType === 'ec-deviation') {
                endpoint = 'ec-deviations'
                navigateTo = 'deviation-ticket-view'
            } else if (templateType === 'pc-template') {

                navigateTo = 'pc-view'
            }else if (templateType === 'pci-view') {
                navigateTo = 'pci-view'
            }
            let requestData = {
                data: jsonData,
                templateId
            };

            if (templateType === 'ec-template') {
                requestData.ec_type = selectedEcType;
            } else if (templateType === 'pc-template') {
                requestData.part_numbers = selectedParts
            }
            let response
            if (templateType === 'pc-template') {
                response = await axios.post(
                    `${BASE_URL}/v1/prod-change/create-pc`,
                    requestData,
                    { headers: headers }
                );
            } else {
                response = await axios.post(
                    `${BASE_URL}/v1/${endpoint}/create-ec`,
                    requestData,
                    { headers: headers }
                );

            }

            if (typeEC === 'ec-template') {
                if (selectedEcType === 'structuring_ec') {
                    await axios.post(
                        `${BASE_URL}/v1/structure-ec/create-parent-part`,
                        {
                            enchange_id: response.data.data._id, sequence_id: response.data.data.sequence_id,
                            part_number:selectedParts,

                        },
                        { headers: headers }
                    );
                } else {
                    await axios.post(
                        `${BASE_URL}/v1/ec-template/create-part-num-mapping`,
                        {
                            enchange_id: response.data.data._id, sequence_id: response.data.data.sequence_id,
                            part_details: selectedParts,

                        },
                        { headers: headers }
                    );
                }


            } else if (typeEC === 'ec-deviation') {

                const getpartsDetails = await axios.post(
                    `${BASE_URL}/v1/ec-deviations/create-part-number-dev`,
                    {
                        deviation_id: response.data.data._id, sequence_id: response.data.data.sequence_id,
                        part_details: selectedParts,

                    },
                    { headers: headers }
                );
            }


            // console.log(response)
            // deviation-ticket-view/:sequence_id

            nav(`/${navigateTo}/${response.data.data.sequence_id}`);

            //     // window.location.reload()
        } catch (error) {
            console.error("Error fetching options data:", error);
        }





    };
    const handlePciCreate = async () => {
       

        const ticketData = {};
        const defaultFieldsData = {};

        label.forEach((field, index) => {
            let fieldName = field.fieldName.replace(/\s+/g, "_").toLowerCase(); // Replace spaces with underscores

            if (field.inputType === "attachment") {
                // Handling attachment fields
                defaultFieldsData[fieldName.toString()] = {
                    value: uploadedFiles.map((file) => {
                        return { name: file.name, key: file.key };
                    }),
                    is_default: field.is_default,
                    inputType: field.inputType,
                    fieldName: field.fieldName,
                    order: index + 1,
                };
            }

            else if (field.inputType === "person") {
                // Handling person fields
                let selectedPerson;
                if (field.fieldName === "Owner") {
                    selectedPerson = [
                        {
                            photo: owner.photo,
                            _id: owner.id,
                            fullName: owner.name,
                        },
                    ];
                } else {
                    let selectedMember = formData[field.fieldName];
                    selectedPerson = allMembers.filter(
                        (member) => member._id === selectedMember
                    );
                }
                // Adding selected person data to ticketData or defaultFieldsData based on conditions
                if (selectedPerson && selectedPerson.length > 0) {
                    let value = {
                        photo: selectedPerson[0].photo,
                        member_id: selectedPerson[0]._id,
                        fullName: selectedPerson[0].fullName,
                    };
                    if (field.is_default) {
                        defaultFieldsData[fieldName.toString()] = {
                            value,
                            is_default: true,
                            inputType: field.inputType,
                            fieldName: field.fieldName,
                            order: index + 1,
                        };
                    } else {
                        ticketData[fieldName.toString()] = {
                            value,
                            is_default: false,
                            inputType: field.inputType,
                            fieldName: field.fieldName,
                            order: index + 1,
                        };
                    }
                }
            } else if (field.inputType === "select-person") {

                // Handling approval list fields
                // Assuming selectedMembers contains the IDs of selected members
                // let selectedPerson = selectMember.filter(member => selectedMembers.includes(member._id));

                // Convert selected members to the desired format with member_id as the key
                let value = Array.isArray(selectedMembers[field.fieldName]) 
                ? selectedMembers[field.fieldName].map(member => ({
                    member_id: member._id,
                    photo: member.photo,
                    fullName: member.fullName,
                    status: ''
                })) 
                : [];
            

                // Add the selected members data to ticketData or defaultFieldsData based on conditions
                if (field.is_default) {
                    defaultFieldsData[fieldName.toString()] = {
                        value,
                        is_default: true,
                        inputType: field.inputType,
                        fieldName: field.fieldName,
                        order: index + 1,
                    };
                } else {
                    ticketData[fieldName.toString()] = {
                        value,
                        is_default: false,
                        inputType: field.inputType,
                        fieldName: field.fieldName,
                        order: index + 1,
                    };
                }
            } else {
                // Handling other types of fields
                const fieldData = {
                    value: formData[field.fieldName],
                    is_default: field.is_default,
                    inputType: field.inputType,
                    fieldName: field.fieldName,
                    option: field.options,
                    order: index + 1,
                };
                if (field.is_default) {
                    defaultFieldsData[fieldName.toString()] = fieldData;
                } else {
                    ticketData[fieldName.toString()] = fieldData;
                }
            }
        });
        const requiredFields = label.filter(
            (field) =>
                (field.is_mandatory || field.is_default) &&
                field.inputType !== "comments" &&
                field.fieldName !== "Owner"
        );
        const errors={}
        // Convert to JSON string
        requiredFields.forEach((field) => {
            if (field.inputType !== "attachment" && field.fieldName !== "Select part numbers" && field.inputType !== "select-person" && !formData[field.fieldName]) {
                errors[field.fieldName] = "This field is required.";
            }
            if (field.inputType === "select-person" ) {
                // if (field.fieldName === "Approval list") {
                    if (!selectedMembers[field.fieldName] || selectedMembers[field.fieldName].length < 2) {
                        errors[field.fieldName] = 'At least 2 members are required for this field.';
                    }
                // }
            }
          
            // Exclude specific fields when templateType is "ec-template"
            
        });
       
        console.log(errors)
        setFieldErrors(errors);
        if (Object.keys(errors).length === 0 ) {
            const jsonData = {
                defaultFieldsData: { ...defaultFieldsData },
            };
            console.log(jsonData)
            try {
                const headers = {
                    "x-auth-token": Cookies.get("token"),
                };
                
                let requestData = {
                    data: JSON.stringify(jsonData),
                    product_name:selectedOption.product_name,
                    part_number: selectedOption.part_number,
                    product_id: selectedOption._id
                };
    
               let response = await axios.post(
                    `${BASE_URL}/v1/manufac-bom/create-mbom-product`,
                    requestData,
                    { headers: headers }
                );
               
    
               
    
    
                // console.log(response)
                // deviation-ticket-view/:sequence_id
    
                nav(`/pci-view/${response.data.data.sequence_id}`);
    
                //     // window.location.reload()
            } catch (error) {
                console.error("Error fetching options data:", error);
            }

        }
       
        





    };

    const handleSingleSelectChange = (fieldName, value) => {
        handleFieldChange(fieldName, value);
    };
    const handlePersonSelectChange = (fieldName, value) => {
        handleFieldChange(fieldName, value);
    };



    const handleMultiSelectChange = (fieldName, selectedOptions) => {
        console.log(selectedOptions)
        let selectedValues
        if (fieldName === 'Select part numbers') {
            const partNumbers = selectedOptions.map(option => ({
                part_id: option.value,
                part_number: option.label
            }));
            console.log(partNumbers);
            selectedValues = partNumbers;
        } else if (fieldName === 'Select EC') {
            const partNumbers = selectedOptions.map(option => ({
                ec_sequence_id: option.value,
                ec_title: option.label.props.children[0].props.children                
            }));
            console.log(partNumbers);
            selectedValues = partNumbers;
        }
        else {
            selectedValues = selectedOptions.map((option) => option.value);
        }

        handleFieldChange(fieldName, selectedValues);
    };



    useEffect(() => {
        fetchAllMembers();
    }, []);

    const fetchMembers = async (search) => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };

            const response = await axios.get(
                `${BASE_URL}/v1/ec-template/get-members-hierarchy`,
                { headers, params: { search: search } } // Pass the search query as a parameter
            );

            //   setPartNumberOptions(search !== '' ? listofPartNumber.data.data.part_number_list : (prevNotifications) => [...prevNotifications, ...listofPartNumber.data.data.part_number_list]);


            setSelectMember(response.data.data);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    let typingTimeout = null;
    const handleChange = (e) => {
        setIsDropdownOpen(true)
        const value = e.target.value;

        clearTimeout(typingTimeout); // Clear the previous timeout if any
        typingTimeout = setTimeout(() => {
            fetchMembers(value); // Call fetchMembers after 2 seconds of inactivity
        }, 500); // Call fetchMembers with the search query
    };

    const fetchAllMembers = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            // const response = await axios.get(BASE_URL + "/v1/ec-template/get-members-hierarchy", {
            //     headers: headers,
            // });
            const memberResponse = await axios.get(BASE_URL + "/v1/setting/get-mem", {
                headers: headers,
            });



            if (templateType === 'ec-deviation') {
                const listofUniquePartNumber = await axios.get(
                    `${BASE_URL}/v1/ec-deviations/get-unique-ec`,
                    {
                        headers: headers,
                    }
                );
                console.log(listofUniquePartNumber)
                const ecTitlesAndIds = listofUniquePartNumber.data.data.uniqueECTitles
                    .map(item => ({
                        ec_id: item.ec_id,
                        ec_title: item.ec_title,
                        ec_sequence_id: item.ec_sequence_id
                    }));
                setEcTitles(ecTitlesAndIds)
            } else if (templateType === 'pc-template') {
                const listofUniquePartNumber = await axios.get(
                    `${BASE_URL}/v1/prod-change/get-unique-ec`,
                    {
                        headers: headers,
                    }
                );
                console.log(listofUniquePartNumber)
                const ecTitlesAndIds = listofUniquePartNumber.data.data.uniqueECTitles
                    .map(item => ({
                        ec_id: item.ec_id,
                        ec_title: item.ec_title,
                        ec_sequence_id: item.ec_sequence_id
                    }));
                setEcTitles(ecTitlesAndIds)
            }



            setAllMembers(memberResponse.data.data.memberList)
            // setSelectMember(response.data.data);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };




    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "369px",
            backgroundColor: "transparent",
            border: "1px solid #EDF2F7",
            boxShadow: "none",
        }),
        menu: (provided) => ({
            ...provided,
            maxWidth: "369px", // Set the maximum width for the dropdown menu
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),

        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit",
            cursor: "pointer",
            ":hover": {
                background: "lightgray",
            },
        }),


    };





    const toggleDropdown = (fieldName, e) => {
        fetchMembers();
        setIsDropdownOpen(prevState => ({
            ...prevState,
            [fieldName]: !prevState[fieldName]
        }));
        e.stopPropagation();
    };

    const handleMemberClick = (member, fieldName, e) => {
        e.stopPropagation();

        const memberId = member.entity_id;
        const fullName = member.fullName;
        const photo = member.photo;

        setSelectedMembers((prevState) => {

            const newState = { ...prevState };

            const selectedForField = newState[fieldName] || [];

            const isSelected = selectedForField.some(selectedMember => selectedMember._id === memberId);

            if (isSelected) {

                newState[fieldName] = selectedForField.filter(selectedMember => selectedMember._id !== memberId);
            } else {

                newState[fieldName] = [...selectedForField, { _id: memberId, fullName, photo }];
            }


            return newState;
        });

        console.log(selectedMembers);
    };


    const fetchProducts = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };

            const response = await axios.get(
                `${BASE_URL}/v1/manufac-bom/get-product-name`,
                { headers }
            );

            setOptions(response.data.data.product_list);
        } catch (error) {
            console.error("Error fetching members data:", error);
        }
    };

    const handleOnchange = (selected) => {
        setSelectedOption(selected);
        console.log(selected);
        // Clear the specific validation error when the user makes a change

    };

    return (
        <>
            <div className="ec-form-page">

                <div className="ec-ticket-home" onClick={() => setIsDropdownOpen(false)}>

                    <div className="ex-ticket-title">
                        <span className="ec-title">Create new
                            {templateType === 'ec-template' && ' EC'}
                            {templateType === 'ec-deviation' && ' deviation'}
                            {templateType === 'pc-template' && ' PC'}
                            {templateType === 'pci-view' && ' PCI'}
                        </span>
                        <PopupCloseButton handleClose={onClose} />
                    </div>
                    {templateType !== 'pci-view' && <>
                        <div className="dept-ticket-steps">
                        <span>step 1 of 2</span>
                    </div>
                    <img
                        src={`${ASSET_PREFIX_URL}ticket-step1.svg`}
                        alt=""
                        width="100%"
                    />
                    </>}
                   


                    <div onSubmit={handleSubmit} className="form-grid" style={{ marginTop: '20px', width: '100%' }}>
                        {templateType === 'ec-template' && <div className="grid-item">

                            <span>Select EC type*</span>
                            <Select styles={customStyles} placeholder='Select EC type' options={optionsOfECtype}
                                onChange={handleSelectEcType}
                            />
                            {!selectedEcType && (
                                <span className="error-message" style={{ color: "red" }}>
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                    Please select EC type
                                </span>
                            )}
                        </div>}
                        {templateType === 'pci-view' && <div className="grid-item">

                            <span>Select Product*</span>
                            <Select
                                options={options}
                                menuPlacement="auto"
                                placeholder='Select product'
                                styles={customStyles}
                                getOptionLabel={(option) => (
                                    <div className="select-photo">
                                        {option.product_name}
                                    </div>
                                )}
                                onChange={handleOnchange}
                                onFocus={fetchProducts}
                                value={selectedOption}
                            />
                            {!selectedOption && (
                                <span className="error-message" style={{ color: "red" }}>
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                    Please select product
                                </span>
                            )}
                        </div>}
                        {templateType === 'pci-view' && <div className="grid-item">

                            <span>Part number</span>
                           <input className="type-texts" type="text" placeholder="part number" disabled value={selectedOption && selectedOption.part_number}/>
                           
                        </div>}

                        {label.map((field, index) => (
                            <div
                                key={field}
                                className={
                                    field.inputType === "attachment"
                                        ? "full-width-item"
                                        : "grid-item"
                                }
                                style={
                                    (field.inputType === "comments" ||
                                        field.fieldName === 'Select part numbers' ||
                                        (templateType === 'ec-template' &&
                                            (field.fieldName === 'Deviation end date' ||
                                                field.fieldName === 'Select EC' ||
                                                field.fieldName === 'Deviation Title')) ||
                                        (templateType === 'ec-deviation' &&
                                            (field.fieldName === 'EC Title'))
                                    ) ? { display: "none" } : null
                                }


                            >
                                {field.inputType === 'attachment' && <span>{field.fieldName}</span>}
                                
                                {(field.inputType !== 'attachment') && <span>{field.fieldName}{(field.is_mandatory === true&&field.fieldName !== 'Trial results'&&field.fieldName !== 'Select EC') ? '*' : ''}: <span style={{ color: '#610bee', fontWeight: '500' }}>
                                    </span></span>}
                                {field.fieldName === "Owner" ? (
                                    <div>
                                        <Select
                                            placeholder={field.placeholder}
                                            isDisabled={true}
                                            styles={customStyles}
                                            value={{
                                                label: (
                                                    <div
                                                        className="member-option"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        <NameProfile userName={owner.name} width='26px' memberPhoto={owner.photo} fontweight='500' />


                                                        {owner.name}
                                                        <span className="you-text">Me</span>
                                                    </div>
                                                ),
                                            }}
                                        />
                                        {/* Render any error messages for the Owner field */}
                                    </div>
                                ) : field.inputType === "person" ? (
                                    <div>
                                        <Select
                                            placeholder={field.placeholder}
                                            styles={customStyles}
                                            isMulti={field.fieldName === "Watchers" ? true : false}
                                            options={allMembers.map((member) => ({
                                                value: member._id, // Use full name as the value
                                                label: (
                                                    <div
                                                        className="member-option"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        <NameProfile userName={member.fullName} width='26px' memberPhoto={member.photo} fontweight='500' />

                                                        {member.fullName}
                                                    </div>
                                                ),
                                            }))}
                                            onChange={(selectedOption) => {
                                                field.fieldName === "Watchers"
                                                    ? handlePersonSelectChange(
                                                        field.fieldName,
                                                        selectedOption
                                                    )
                                                    : handlePersonSelectChange(
                                                        field.fieldName,
                                                        selectedOption.value
                                                    );
                                            }}
                                            value={
                                                formData[field.fieldName]
                                                    ? formData[field.fieldName]._id
                                                    : null
                                            } // Use fullName as value
                                        />
                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message" style={{ color: "red" }}>
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                        {/* Render any error messages for the Owner field */}
                                    </div>
                                ) : field.inputType === 'select-person' ? (
                                    <>
                                        <div className="create-part-num-div-form-input-options-ec">
                                            <div className='create-part-num-div-form-input-options-drop' onClick={(e) => toggleDropdown(field.fieldName, e)}>
                                                <input
                                                    type="text"
                                                    placeholder="Select Approver(s)"
                                                    width='350px'
                                                    onChange={(e) => handleChange(e)}
                                                />
                                                <div className='droping-search-div'>
                                                    <img className='droping-search' src={`${ASSET_PREFIX_URL}options-drop.png`} alt="" />
                                                </div>
                                            </div>
                                            {isDropdownOpen[field.fieldName] && (
                                                <div className='part-options-child-div' style={{ width: '369px' }}>
                                                    {selectMember.map((member) => (
                                                        <div
                                                            key={member.entity_id}
                                                            className={`part-options-child ${selectedMembers[field.fieldName]?.some(selectedMember => selectedMember._id === member.entity_id) ? 'selected' : ''}`}
                                                            onClick={(e) => handleMemberClick(member, field.fieldName, e)}
                                                        >
                                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                                {member.photo ? (
                                                                    <img
                                                                        src={PHOTO_LINK + member.photo}
                                                                        alt=""
                                                                        style={{
                                                                            borderRadius: "50%",
                                                                            width: "26px",
                                                                            height: "26px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div className="owner-image-span">
                                                                        <NameProfile userName={member.fullName} />
                                                                    </div>
                                                                )}
                                                                <span>{member.fullName}</span>
                                                            </div>

                                                            <img
                                                                src={selectedMembers[field.fieldName]?.some(selectedMember => selectedMember._id === member.entity_id) ?
                                                                    `${ASSET_PREFIX_URL}part-selected.png` : `${ASSET_PREFIX_URL}part-unselect.png`}
                                                                alt="Part"
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        {selectedMembers[field.fieldName]?.length > 0 && (
                                            <span className="selected-mem-list">
                                                {selectedMembers[field.fieldName].map((item, index) => (
                                                    <React.Fragment key={item._id}>
                                                        {item.fullName}
                                                        {index < selectedMembers[field.fieldName].length - 1 && ", "}
                                                    </React.Fragment>
                                                ))}
                                            </span>
                                        )}

                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message" style={{ color: "red" }}>
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                    </>

                                ) : field.inputType === "text" ||
                                    
                                    field.inputType === "team" ? (
                                    <>
                                        <div className="type-texts">
                                            <input
                                                type="text"
                                                placeholder={field.placeholder}
                                                value={formData[field.fieldName] || ""}
                                                onChange={(e) =>
                                                    handleFieldChange(field.fieldName, e.target.value)
                                                }
                                                disabled={
                                                    field.inputType === "date" &&
                                                    field.label === "CreatedOn"
                                                }
                                            />
                                        </div>
                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message" style={{ color: "red" }}>
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                    </>
                                ) :field.inputType === "paragraph" ?(
                                    <>
                                      <div className="type-texts">
                                        <textarea
                                          type="text"
                                          placeholder={field.placeholder}
                                          value={formData[field.fieldName] || ""}
                                          onChange={(e) =>
                                            handleFieldChange(field.fieldName, e.target.value)
                                          }
                                          disabled={
                                            field.fieldName === 'Trial results'
                                          }
                                        />
                                      </div>
                                      {fieldErrors[field.fieldName] && (
                                        <span className="error-message" style={{ color: "red" }}>
                                          <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                          {fieldErrors[field.fieldName]}
                                        </span>
                                      )}
                                    </>
                                  ): field.inputType === "single_select" ? (
                                    <>
                                        <Select
                                            placeholder={field.placeholder}
                                            styles={customStyles}
                                            options={field.options.map((opt) => ({
                                                value: opt,
                                                label: opt,
                                            }))}
                                            onChange={(selectedOption) =>
                                                handleSingleSelectChange(
                                                    field.fieldName,
                                                    selectedOption.value
                                                )
                                            }
                                            value={
                                                formData[field.fieldName]
                                                    ? {
                                                        value: formData[field.fieldName],
                                                        label: formData[field.fieldName],
                                                    }
                                                    : null
                                            }
                                        />
                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message" style={{ color: "red" }}>
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                    </>
                                ) : field.inputType === "multi_select" ? (
                                    <>
                                        <Select
                                            placeholder={field.placeholder}
                                            styles={customStyles}
                                            options={
                                                field.fieldName === "Select EC"
                                                    ? ecTitles.map(title => ({ value: title.ec_sequence_id, 
                                                        label:(
                                                            <div
                                                                className="member-option"
                                                               
                                                            >
                                                               <span>{title.ec_title}</span>
                                                               <span>({title.ec_sequence_id})</span>
                                                            </div>
                                                        )  }))
                                                    : field.fieldName === "Select part numbers"
                                                        ? partOptions.map(number => ({ value: number._id, label: number.part_number }))
                                                        : field.options.map(opt => ({ value: opt, label: opt }))
                                            }

                                            isMulti
                                            onChange={(selectedOptions) =>
                                                handleMultiSelectChange(field.fieldName, selectedOptions)
                                            }
                                        />
                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message" style={{ color: "red" }}>
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                    </>
                                ) : field.inputType === "number" ? (
                                    <>
                                        <div className="type-texts">
                                            <input
                                                type="number"
                                                placeholder={field.placeholder}
                                                value={formData[field.fieldName] || ""}
                                                onChange={(e) =>
                                                    handleFieldChange(field.fieldName, e.target.value)
                                                }
                                                disabled={
                                                    field.inputType === "date" &&
                                                    field.label === "CreatedOn"
                                                }
                                            />
                                        </div>
                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message" style={{ color: "red" }}>
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                    </>
                                ) : field.inputType === "date" ? (
                                    <>
                                        <div className="type-texts">
                                            <input
                                                type="date"
                                                placeholder={field.placeholder}
                                                value={formData[field.fieldName] || ""}
                                                // value={formData[field.fieldName] ? formatDate(formData[field.fieldName]) :  ''}
                                                min={new Date().toISOString().split("T")[0]}
                                                onChange={(e) =>
                                                    handleFieldChange(field.fieldName, e.target.value)
                                                }
                                            />
                                        </div>
                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message" style={{ color: "red" }}>
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                    </>
                                ) : field.inputType === "date_range" ? (
                                    <>
                                        <div className="type-texts">
                                            <div>
                                                <span>Start Date</span>
                                                <input
                                                    placeholder={field.placeholder} 
                                                    type="date"
                                                    value={formData[field.fieldName]?.start || ""}
                                                    min={new Date().toISOString().split("T")[0]}
                                                    onChange={(e) =>
                                                        handleFieldChange(field.fieldName, {
                                                            ...formData[field.fieldName],
                                                            start: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <span>End Date</span>
                                                <input
                                                    type="date"
                                                    value={formData[field.fieldName]?.end || ""}
                                                    min={formData[field.fieldName]?.start}
                                                    onChange={(e) =>
                                                        handleFieldChange(field.fieldName, {
                                                            ...formData[field.fieldName],
                                                            end: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>

                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message" style={{ color: "red" }}>
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                    </>
                                ) : field.inputType === "link" ? (
                                    <>
                                        <div className="type-texts">
                                            <input
                                                type="url"
                                                placeholder={field.placeholder}
                                                value={formData[field.fieldName] || ""}
                                                onChange={(e) =>
                                                    handleFieldChange(field.fieldName, e.target.value)
                                                }
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message" style={{ color: "red" }}>
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                    </>
                                ) : field.inputType === "attachment" ? (
                                    <div>
                                        <input
                                            type="file"
                                            onChange={(e) => handleFileInputChange(e)}
                                            className="full-width-input"
                                            ref={inputRef}
                                            style={{ display: "none" }}
                                        />

                                        <div
                                            className="filedropzone"
                                            onDrop={handleFileDrop}
                                            onDragOver={handleDragOver}
                                            style={{ width: '80%' }}
                                        // onDragLeave={handleDragLeave}
                                        >
                                            <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                                            {isLoading ? (
                                                <p>Uploading ....</p>
                                            ) : (
                                                <p>
                                                    Drop files to attach or
                                                    <span onClick={() => inputRef.current.click()}>
                                                        {" "}
                                                        Browse
                                                    </span>
                                                </p>
                                            )}
                                        </div>

                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message">
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                        <div className="file-info">
                                            {fileFormat && <p>File Format: {fileFormat}</p>}
                                            {/* {uploading && <div className="loading-line" />} */}
                                        </div>

                                        <AttachmentsFunction uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} type='form-upload' />

                                        {uploadError && <div className="error">{uploadError}</div>}
                                        {fileSizeError && (
                                            <div className="error">
                                                <span
                                                    className="error-message"
                                                    style={{ color: "#FF9845" }}
                                                >
                                                    <ErrorOutlineOutlinedIcon /> {fileSizeError}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <>
                                        <div className="type-texts">
                                            <input
                                                type={type[index]}
                                                value={formData[type[index]] || ""}
                                                onChange={(e) =>
                                                    handleFieldChange(type[index], e.target.value)
                                                }
                                                disabled={type[index] === "date" && field === "CreatedOn"}
                                            />
                                        </div>

                                        {fieldErrors[field.fieldName] && (
                                            <span className="error-message" style={{ color: "red" }}>
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors[field.fieldName]}
                                            </span>
                                        )}
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="depart-form-btns">
                       {templateType !== 'pci-view' && <button className="dept-form-submit" onClick={handleNextStepToParts}>
                            Next
                        </button>} 
                        {templateType === 'pci-view' && <button className="dept-form-submit" onClick={handlePciCreate}>
                            Save
                        </button>}

                        <button className="dept-form-cancel" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            {/*  setOpenNextStep('structure-parts')
            }else{
                setOpenNextStep('release-parts') */}
            {openNextStep === 'release-parts' && <EcPartsForm
                typeEC={templateType} onClose={onClose}
                handlePrevStepToParts={handlePrevStepToParts} handleCreate={handleCreate} />}
            {openNextStep === 'structure-parts' && <StructuringEcParts
                typeEC={templateType} onClose={onClose} type='ec-parts'
                handlePrevStepToParts={handlePrevStepToParts} handleCreate={handleCreate} />}
        </>

    );
}

export default EcPageForm;
