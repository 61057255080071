import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './PoCreate.css';
import Select from 'react-select';
import { ASSET_PREFIX_URL, BASE_URL } from '../../constants/config';
import PartSupplierAttributes from './PartSupplierAttributes';
const attributeOptions = [
    { value: 'lead_time', label: 'Lead Time' },
    { value: 'shipping_quantity', label: 'Shipping Quantity' },
];

function PoExcelSheet({ defaultFields = [], setTableDetails, savedData = [], inputDisable, poType, errors, setErrors, supplierId }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [srNoColumnIndex, setSrNoColumnIndex] = useState(-1);
    const [partNumber, setPartNumber] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreSuggestions, setHasMoreSuggestions] = useState(true);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [openAttributePopUp, setOpenAttributePopUp] = useState(false);
    const [index, setIndex] = useState('');
    const [suggestedAttributes, setSuggestedAttributes] = useState({})

    useEffect(() => {
        if (!Array.isArray(defaultFields) || defaultFields.length === 0) {
            console.error('defaultFields is not an array or is empty:', defaultFields);
            return;
        }

        const filteredColumns = defaultFields.filter(field => field.template_type === 'poItems' &&
            ((field.is_default === false && field.is_default_template === true)));

        const orderedColumns = orderColumns(filteredColumns);
        const srNoIndex = orderedColumns.findIndex(field => field.fieldName === 'Sr.no');

        setColumns(orderedColumns);
        setSrNoColumnIndex(srNoIndex);
        setRows(savedData.length > 0 ? savedData : generateInitialData(orderedColumns));
    }, [defaultFields]);

    useEffect(() => {
        if (!Array.isArray(rows) || rows.length === 0 || !Array.isArray(columns) || columns.length === 0) {
            console.error('rows or columns is not an array or is empty:', rows, columns);
            return;
        }

        const formattedData = rows.map(row => {
            const formattedRow = {};
            columns.forEach(column => {
                formattedRow[column.fieldName.toLowerCase().replace(/\s+/g, '_')] = {
                    value: row.data[column.fieldName],
                    ...column
                };
            });
            return formattedRow;
        });

        const validationErrors = validateRows(rows, columns);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setTableDetails(formattedData);
        }

    }, [rows, columns, setTableDetails]);

    const orderColumns = (columns) => {
        const ordered = [];
        const otherFields = [];

        columns.forEach(column => {
            otherFields.push(column);
        });

        return [...otherFields];
    };

    const generateInitialData = (columns) => {
        if (!Array.isArray(columns) || columns.length === 0) {
            console.error('columns is not an array or is empty:', columns);
            return [];
        }

        return [{
            id: Date.now(),
            data: columns.reduce((acc, column) => {
                acc[column.fieldName] = column.fieldName.toLowerCase() === 'sr.no' ? '1' : '';
                return acc;
            }, {})
        }];
    };



    // const validateRows = (rows, columns) => {
    //     const errors = {};
    //     rows.forEach((row, rowIndex) => {
    //         columns.forEach(column => {
    //             const fieldValue = row.data[column.fieldName];
    //             if (!fieldValue && column.fieldName.toLowerCase() !== 'sr.no') {
    //                 if (!errors[rowIndex]) {
    //                     errors[rowIndex] = {};
    //                 }
    //                 errors[rowIndex][column.fieldName] = true;
    //             }
    //             // Validate quantity if the field is 'quantity'
    //             if (poType === 'create') {
    //                 if (column.fieldName.toLowerCase() === 'quantity') {
    //                     console.log('here');
    //                     const quantity = parseInt(fieldValue, 10);

    //                     // Check if suggestedAttributes and shipment_quantity are defined
    //                     if (suggestedAttributes && suggestedAttributes.shipment_quantity) {
    //                         if (quantity % suggestedAttributes.shipment_quantity !== 0) {
    //                             if (!errors[rowIndex]) {
    //                                 errors[rowIndex] = {};
    //                             }
    //                             errors[rowIndex]['Quantity'] = `Quantity must be divisible by ${suggestedAttributes.shipment_quantity}`;
    //                         }
    //                     } else {
    //                         console.error('Suggested attributes or shipment_quantity is missing.');
    //                     }
    //                 }
    //             }

    //         });
    //     });
    //     return errors;
    // };

    const handleOpenSuggestions = (rowIndex, fieldName, value) => {
        if (fieldName.toLowerCase() === 'part number') {
            fetchSuggestions(value, rowIndex, 1); // Reset suggestions on new part number input
        }
    }

    // const handleCellChange = (rowIndex, fieldName, value) => {
    //     const newRows = [...rows];
    //     // Debugging: Check shipment quantity value


    //     newRows[rowIndex].data[fieldName] = value;
    //     setRows(newRows);

    //     if (fieldName.toLowerCase() === 'part number') {
    //         fetchSuggestions(value, rowIndex, 1); // Reset suggestions on new part number input
    //     }
    //     // }
    // };





    const getShipmentData = async (partnumber) => {

        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const response = await axios.get(`${BASE_URL}/v1/purchase-order/get-attribute-supplier`, {
                params: { supplier_id: supplierId, part_number: partnumber, },
                headers
            });
            console.log(response)
            setSuggestedAttributes(response.data.data && response.data.data)


        } catch (error) {
            console.log(error)
        }


    }

    const fetchSuggestions = async (searchValue, rowIndex, page = 1) => {
        setLoading(true);
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            let response;
            if (poType === 'create') {
                response = await axios.get(`${BASE_URL}/v1/purchase-order/get-part-po`, {
                    params: { search: searchValue, page, size: 10 },
                    headers
                });
                console.log(response)
                const parts = response.data.data.parts.filter(part => !isPartNumberInTable(part.part_number));
                setSuggestions(prevSuggestions => (page === 1 ? parts : [...prevSuggestions, ...parts]));

                setHasMoreSuggestions(parts.length > 0);
            } else {
                response = await axios.get(`${BASE_URL}/v1/asset/get-asset-in-asset-po`, {
                    params: { search: searchValue, page, size: 5 },
                    headers
                });
                const assets = response.data.data.asset.filter(asset => !isPartNumberInTable(asset.asset_number));
                setSuggestions(prevSuggestions => (page === 1 ? assets : [...prevSuggestions, ...assets]));
                setHasMoreSuggestions(assets.length > 0);
            }

            setActiveSuggestionIndex(rowIndex);
            setCurrentPage(page);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadMoreSuggestions = () => {
        if (!loading && hasMoreSuggestions) {
            fetchSuggestions(rows[activeSuggestionIndex].data['Part number'], activeSuggestionIndex, currentPage + 1);
        }
    };

    const isPartNumberInTable = (partNumber) => {
        return rows.some(row => row.data['Part number'] === partNumber);
    };

    const handleSuggestionClick = async (rowIndex, suggestion) => {
        // Create a copy of the rows array
        const newRows = [...rows];

        // Find the fields for part number and description
        const descriptionField = columns.find(col => col.fieldName.toLowerCase() === 'part number');
        const titleField = columns.find(col => col.fieldName.toLowerCase() === 'description');

        // Update the row data based on the selected suggestion
        if (descriptionField) {
            newRows[rowIndex].data[descriptionField.fieldName] = poType === 'create' ? suggestion.part_number : suggestion.asset_number;
        }
        if (titleField) {
            newRows[rowIndex].data[titleField.fieldName] = poType === 'create' ? suggestion.part_title : suggestion.asset_title;
        }

        console.log(suggestion);

        // Determine which attributes to set based on poType
        if (poType === 'create') {
            try {
                const headers = {
                    "x-auth-token": Cookies.get("token"),
                };
                const response = await axios.get(`${BASE_URL}/v1/purchase-order/get-attribute-supplier`, {
                    params: { supplier_id: supplierId, part_number: suggestion.part_number, },
                    headers
                });
                console.log(response)
                if (response.data.data === null) {

                    setSuggestedAttributes({ part_number: suggestion.part_number })
                    setOpenAttributePopUp(!openAttributePopUp)
                    setIndex(rowIndex)
                }



            } catch (error) {
                console.log(error)
            }


        }

        // Update state with new rows and clear suggestions
        setRows(newRows);
        setSuggestions([]);
        setActiveSuggestionIndex(-1);
    };

    const handleClearRow = (rowIndex) => {
        console.log(rowIndex)
        const newRows = [...rows];
        console.log(newRows)


        newRows[rowIndex].data['Part number'] = '';
        newRows[rowIndex].data['Description'] = ''; // Clear part number


        setRows(newRows); // Update rows state
    };
    const addRow = () => {
        const newRow = {
            id: Date.now(),
            data: columns.reduce((acc, column) => {
                acc[column.fieldName] = column.fieldName.toLowerCase() === 'sr.no' ? (rows.length + 1).toString() : '';
                return acc;
            }, {})
        };
        setRows(prevRows => {
            const updatedRows = [...prevRows, newRow];
            if (srNoColumnIndex !== -1) {
                updatedRows.forEach((row, index) => {
                    row.data[columns[srNoColumnIndex].fieldName] = (index + 1).toString();
                });
            }
            return updatedRows;
        });
    };

    const removeRow = (rowId) => {
        if (rows.length > 1) {
            const newRows = rows.filter(row => row.id !== rowId);
            if (srNoColumnIndex !== -1 && columns[srNoColumnIndex]) {
                newRows.forEach((row, index) => {
                    row.data[columns[srNoColumnIndex].fieldName] = (index + 1).toString();
                });
            }
            setRows(newRows);

        }
    };



    const handleAttribute = async (selected, row) => {
        console.log(row)
        setSelectedAttribute(selected.label)
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const response = await axios.get(`${BASE_URL}/v1/purchase-order/get-attribute-supplier`, {
                params: { supplier_id: supplierId, part_number: row.data['Part number'], },
                headers
            });
            console.log(response)
            setSuggestedAttributes(response.data.data && response.data.data)
            setOpenAttributePopUp(!openAttributePopUp)

        } catch (error) {
            console.log(error)
        }

    }
    const handleClosePopup = () => {
        setOpenAttributePopUp(!openAttributePopUp)
    }




    // Add a new field to your row data to store the shipment quantity
    const handleCellChange = (rowIndex, fieldName, value) => {
        const newRows = [...rows];
        newRows[rowIndex].data[fieldName] = value;
        newRows[rowIndex].data['shipment_quantity'] = suggestedAttributes?.shipment_quantity || null; // Update shipment quantity for the row
        setRows(newRows);

        if (fieldName.toLowerCase() === 'part number') {
            fetchSuggestions(value, rowIndex, 1); // Reset suggestions on new part number input
        }
    };

    const validateRows = (rows, columns) => {
        const errors = {};
        rows.forEach((row, rowIndex) => {
            columns.forEach(column => {
                const fieldValue = row.data[column.fieldName];
                if (!fieldValue && column.fieldName.toLowerCase() !== 'sr.no') {
                    if (!errors[rowIndex]) {
                        errors[rowIndex] = {};
                    }
                    errors[rowIndex][column.fieldName] = true;
                }
                // Validate quantity if the field is 'quantity'
                if (poType === 'create') {
                    if (column.fieldName.toLowerCase() === 'quantity') {
                        const quantity = parseInt(fieldValue, 10);
                        const shipmentQuantity = row.data['shipment_quantity'];

                        if (shipmentQuantity !== undefined && quantity % shipmentQuantity !== 0) {
                            if (!errors[rowIndex]) {
                                errors[rowIndex] = {};
                            }
                            errors[rowIndex]['Quantity'] = `Quantity must be divisible by ${shipmentQuantity}`;
                        }
                    }
                }
            });
        });
        return errors;
    };

    useEffect(() => {
        if (!Array.isArray(rows) || rows.length === 0 || !Array.isArray(columns) || columns.length === 0) {
            console.error('rows or columns is not an array or is empty:', rows, columns);
            return;
        }

        const formattedData = rows.map(row => {
            const formattedRow = {};
            columns.forEach(column => {
                formattedRow[column.fieldName.toLowerCase().replace(/\s+/g, '_')] = {
                    value: row.data[column.fieldName],
                    ...column
                };
            });
            return formattedRow;
        });

        const validationErrors = validateRows(rows, columns);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setTableDetails(formattedData);
        }
    }, [rows, columns, setTableDetails]);
    return (
        <>
            <div className="po-excel-sheet-container">
                <span>Item table</span>
                <table className="po-table" style={{ marginTop: '12px' }}>
                    <thead>
                        <tr>
                            {columns.map((column) => (
                                <th key={column.id} style={styles.tableHeader}>
                                    {column.fieldName}<br />
                                    {column.fieldName === "Quantity" && poType === 'create' && (
                                        <span style={{ color: 'red', fontSize: '8px' }}>
                                            (should be multiple of shipment quantity)
                                        </span>
                                    )}

                                </th>
                            ))}
                          {poType === 'create' && <th style={styles.tableHeader}>Actions</th>}
                            <th style={styles.tableHeader}></th>
                        </tr>
                    </thead>

                    <tbody>
                        {rows.map((row, rowIndex) => (
                            <tr key={row.id}>
                                {columns.map((column) => (
                                    <td key={column.fieldName}>
                                        {column.fieldName.toLowerCase() === 'sr.no' ? (
                                            <input
                                                type="text"
                                                value={row.data[column.fieldName]}
                                                className="po-input"
                                                readOnly
                                                style={styles.input}
                                            />
                                        ) : (
                                            <div style={{ position: 'relative' }}>
                                                <input
                                                    style={{
                                                        ...styles.input,
                                                        borderColor: errors[rowIndex] && errors[rowIndex][column.fieldName] ? 'red' : '#edf2f7'
                                                    }}
                                                    type="text"
                                                    value={row.data[column.fieldName]}
                                                    className="po-input"
                                                    onFocus={() => {
                                                        // Call getShipmentData only when poType is 'create' and column is 'Quantity'
                                                        if (poType === 'create' && column.fieldName.toLowerCase() === 'quantity') {
                                                            getShipmentData(row.data['Part number']);
                                                        }
                                                    }}
                                                    onClick={(e) => handleOpenSuggestions(rowIndex, column.fieldName, e.target.value)}
                                                    onChange={(e) => handleCellChange(rowIndex, column.fieldName, e.target.value)}
                                                />
                                                {column.fieldName.toLowerCase() === 'part number' && rowIndex === activeSuggestionIndex && (
                                                    <div className="suggestions-box" onScroll={loadMoreSuggestions}>
                                                        {/* {loading ? (
                                                            <div className='loader-po-div'>
                                                                laoding...
                                                            </div>
                                                        ) : ( */}
                                                        {
                                                            suggestions.length < 1 ? (
                                                                <div className="suggestion-item" style={{ width: '150px' }}>No parts!</div>
                                                            ) : (
                                                                suggestions.map((suggestion, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="suggestion-item"
                                                                        onClick={() => handleSuggestionClick(rowIndex, suggestion)}
                                                                    >
                                                                        {poType === 'create' ? (
                                                                            <>
                                                                                {suggestion.part_number}, {suggestion.part_title}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {suggestion.asset_number}, {suggestion.asset_title}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ))
                                                            )
                                                        }

                                                        {/* )} */}
                                                    </div>
                                                )}


                                            </div>
                                        )}

                                    </td>
                                ))}
                                {poType=== 'create' && <td>
                                    <Select
                                        options={attributeOptions}
                                        placeholder="Select"
                                        onChange={(selected) => handleAttribute(selected, row)}
                                        isDisabled={!row.data['Part number']}
                                    />
                                </td>}
                                
                                <td>
                                    <img
                                        src={`${ASSET_PREFIX_URL}attri-delete.png`}
                                        alt='delete'
                                        className="delete-icon"
                                        onClick={() => removeRow(row.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="spreadsheet-controls">
                    <img
                        src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                        alt='add'
                        width='24px'
                        height='24px'
                        className="add-icon"
                        onClick={addRow}
                    />
                </div>
            </div>
            {openAttributePopUp && <PartSupplierAttributes partNumberRow={index} supplierId={supplierId} partNumber={partNumber} onclose={handleClosePopup} type={selectedAttribute} handleClearRow={handleClearRow} data={suggestedAttributes} />}
        </>

    );
}




const styles = {
    poTable: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
        border: '1px solid #ddd',
    },
    tableHeader: {
        padding: '8px',
        border: '1px solid #ddd',
        backgroundColor: '#f0f0f0',
        textAlign: 'left',
    },
    tableCell: {
        padding: '8px',
        border: '1px solid #ddd',
    },
    input: {
        width: '90%',
        padding: '8px',
        border: '1px solid #edf2f7',
        borderRadius: '4px',
        background: 'white',
    },
    icon: {
        cursor: 'pointer',
    },
};

export default PoExcelSheet;
