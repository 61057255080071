import React, { useEffect, useState, useRef } from 'react';
import './PoCreate.css';
import axios from 'axios';
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import Cookies from 'js-cookie';
import { ASSET_PREFIX_URL, BASE_URL } from '../../constants/config';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import SupplyFieldsDiv from './SupplyFieldsDiv';
import PoExcelSheet from './poExcellSheet';
import PoTemplateFields from './PoTemplateFields';
import PoTemplateFieldsView from './PoTemplateFieldsView';
import PoExcellSheetView from './PoExcellSheetView';
import Loading from '../Common/Loading/Loading';
import DownloadPo from '../DownloadPo/DownloadPo';
import EcApproveList from '../../Components/DetailsViewFolder/DetailsViewPopUps/EcApproveList';
import { toast } from "react-toastify";
import RejectPopUp from '../../Components/DetailsViewFolder/DetailsViewPopUps/RejectPopUp';
import TermsAndConditions from './TermsAndConditions';
import CreateGRN from '../GRNfolder/CreateGRN';
import NameProfile from '../Common/CommonJsx/NameProfile';
import AttachmentsGRN from '../GRNfolder/AttachmentsGRN';
import GRNexcellView from '../GRNfolder/GRNexcellView';
import ChangeLogs from '../DetailsViewFolder/ChangeLogs';
import PoNamingForm from './PoNamingForm';
import PoNamingView from './PoNamingView';
// purchase_order_nomenclature
function PoCreate({ poType }) {


    const nav = useNavigate();
    const sequence_id = useParams();
    const [supplierId, setSupplierId] = useState('');
    const [loading, setLoading] = useState(true);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [supplierDetails, setSupplierDetails] = useState({});
    const [supplierDetailsAddress, setSupplierDetailsAddress] = useState([]);
    const [label, setLabel] = useState([]);
    const [defaultDataView, setDefaultDataView] = useState({});
    const [formData, setFormData] = useState({});
    const [formCustomData, setCustomFormData] = useState({});
    const [customDetails, setCustomDetals] = useState([]);
    const [defaultFields, setDefaultFields] = useState([]);
    const [generatePoNumber, setGeneratePoNumber] = useState('');
    const [tableDetails, setTableDetails] = useState({});
    const [templateId, setTemplateId] = useState('');
    const [approvalMemberView, setApprovalMemberView] = useState(false);
    const [memberApproved, setMemberApproved] = useState('');
    const [editable, setEditable] = useState(false);
    const [shouldRevert, setShouldRevert] = useState(false);
    const [ecApprovalStatus, setEcApprovalStatus] = useState('');
    const [PoId, setpoId] = useState('')
    const [editorText, setEditiorText] = useState('')
    const [editedValue, setEditedValue] = useState('')
    const [openApproveList, setOpenApproveList] = useState(false);
    const [openCreateGRN, setOpenCreateGRN] = useState(false);
    const [showComment, setShowComment] = useState([]);
    const [showEditButtons, setShowEditButtons] = useState(false);
    const [editableComment, setEditableComment] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [grnList, setGrnList] = useState([]);
    const [showEditSaveButtons, setShowEditSaveButtons] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [editedtableData, setEditedTabledData] = useState({});
    const [customEditedData, setCustomEditedData] = useState({});
    const [grnId, setGrnId] = useState({});
    const [poId, setPOId] = useState({});
    const [selectedVersion, setSelectedVersion] = useState(0);
    const [versionNumbers, setVersionNumbers] = useState([]);
    const [defaultVersion, setDefaultVersion] = useState('');
    const [IsPopupOpen, setIsPopupOpen] = useState(false);
    const [disableUpdate, setDisableUpdate] = useState(false);
    const [inputDisable, setInputsDisable] = useState(false);
    const [supplierAddressDisable, setSupplierAddressDisable] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({});
    const [selectMembersCond, setSelectMembersCond] = useState(false);
    const [updatedData, setUpdatedData] = useState(false);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [departmentList, setDepartmentList] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedSupplierAddresses, setSelectedSupplierAddress] = useState('');
    const [rightSectionItems, setRightSectionItems] = useState([]);
    const [selectedValues, setSelectedValues] = useState({});
    const [orgDetails, setOrgDetails] = useState({});
    const [selectedAddresses, setSelectedAddress] = useState('');
    const [errors, setErrors] = useState({});
    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };
    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };
    const downloadPoRef = useRef();

    const handleAllEvents = () => {

        setIsDropdownVisible(false);
        setIsDropdownOpen(false)
    };

    const handleDownload = () => {
        if (downloadPoRef.current) {
            downloadPoRef.current.generatePdf();
        }

    };
    const handleOpenCreateGRN = () => {
        setOpenCreateGRN(!openCreateGRN)
    }
    useEffect(() => {
        if ((poType === 'create' || poType === 'assets')) {
            fetchPoMandatoryFields();
            fetchOrganizationDetails()
            fetchDepartments()
            // getSupplierNames();
        } else if (poType === 'view' || poType === 'assets-view') {
            fetchPoDetails();
            fetchOrganizationDetails()
            getComments();
            fetchDepartments()
        } else if (poType === 'parts-grn-view') {
            fetchPartsGrnDetails();

        }
        // fetchOrganizationDetails()
    }, [poType, selectedVersion,updatedData]);



    const getComments = async () => {
        try {



            const headers = {
                "x-auth-token": Cookies.get("token"),
            };

            let getComment

            getComment = await axios.get(
                `${BASE_URL}/v1/comment/get-comment/${sequence_id.sequence_id}`,
                { headers }
            );



            const renderData = getComment.data.data.commentsDetails;

            setShowComment(renderData);
        } catch (error) {
            console.log(error);
        }
    };

    const getSupplierNames = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/purchase-order/get-suppliers", { headers });

            const supplierNames = response.data.data.supplier_list.map(supplier => ({
                value: supplier.sequence_id,
                label: supplier.data.defaultFieldsData.supplier_name.value,
                id: supplier._id
            }));
            console.log(response)
            setSupplierOptions(supplierNames);
        } catch (error) {
            console.log(error);
        }
    };

    const getSupplierDetails = async (selectedId) => {
        console.log(selectedId)
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/purchase-order/get-supplier-details",
                { headers, params: { sequence_id: selectedId } });
            setSupplierDetails({
                ...response.data.data.supplier_details.data.defaultFieldsData,
                ...response.data.data.supplier_details.data.customFieldsData
            });
            setSupplierId(response.data.data.supplier_details._id)
            setSupplierDetailsAddress(response.data.data.supplier_details.data.defaultFieldsData.supplier_address.value)
        } catch (error) {
            console.log(error);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "320px",
            backgroundColor: state.hasValue ? "#610BEE" : "white",
            border: state.hasValue ? 'none' : "1px solid #edf2f7",
            boxShadow: "none",
            color: state.hasValue ? 'white' : 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "#610BEE" : "white",
            color: state.isSelected ? "white" : "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: state.hasValue ? "#610BEE" : "transparent",
            color: state.hasValue ? "white" : "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "320px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    }; const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "200px",
            backgroundColor: "white",
            border: "1px solid #edf2f7",
            boxShadow: "none",
            color: 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: "white",
            color: "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "200px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    };
    const versionVustomStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "150px",
            backgroundColor: state.hasValue ? "#610BEE" : "white",
            border: state.hasValue ? 'none' : "1px solid #edf2f7",
            boxShadow: "none",
            color: state.hasValue ? 'white' : 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "#610BEE" : "white",
            color: state.isSelected ? "white" : "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: state.hasValue ? "#610BEE" : "transparent",
            color: state.hasValue ? "white" : "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "150px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    };


    const fetchPoMandatoryFields = async () => {
        try {
            setLoading(true);
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            let response;


            response = await axios.get(`${BASE_URL}/v1/purchase-order/get-purchase-order-fields`, { headers });

            if (!response) return;

            const filteredFields = response.data.data.fields.filter(
                (field) => field.is_default_template !== false
            );
            console.log(response)
            setLabel(filteredFields);
            setTemplateId(response.data.data.fields[0].template_id);
            setRightSectionItems(response.data.data.active_fields)

            // setGeneratePoNumber(response.data.data.active_fields)
            setLoading(false);
        } catch (error) {
            console.error("Error fetching options data:", error);
            setLoading(false);
        }
    };

    const fetchDepartments = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/depart/get-departments", { headers });
            const departments = response.data.data.departments;

            setDepartmentList(departments);
            //   fillInputSetsWithDepartments(departments);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };




    const fetchPoDetails = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            let response;
            if (poType === 'view') {
                response = await axios.get(`${BASE_URL}/v1/purchase-order/po-details`, {
                    headers, params: {
                        po_name: sequence_id.sequence_id,
                        selected_version: selectedVersion ? selectedVersion : defaultVersion
                    }
                });
            } else if (poType === 'assets-view') {
                response = await axios.get(`${BASE_URL}/v1/asset/asset-details`, {
                    headers, params: {
                        asset_name: sequence_id.sequence_id,
                        selected_version: selectedVersion ? selectedVersion : defaultVersion
                    }
                });
            }
            //    check here for type and render the data 
            if (poType === 'view') {
                setDefaultDataView(response.data.data.po_details.data);
                setpoId(response.data.data.po_details._id)
                setGeneratePoNumber(response.data.data.po_details.purchase_order_nomenclature)
                setEditable(response.data.data.should_edit)
                setSupplierId(response.data.data.po_details.data.supplier_id)
                setTemplateId(response.data.data.po_details.templateId)
                setVersionNumbers(response.data.data.po_versions)
                setDefaultVersion(response.data.data.po_details.po_version)
                setEcApprovalStatus(response.data.data.po_details.po_status)
                setMemberApproved(response.data.data.have_approved)
                setApprovalMemberView(response.data.data.should_approve);
                setShouldRevert(response.data.data.should_revert)
                setSelectedAddress(response.data.data.po_details.data.company_address)
                setSelectedDepartment(response.data.data.po_details.data.department_name)
                setSelectedSupplierAddress(response.data.data.po_details.data.supplier_address)
            } else if (poType === 'assets-view') {
                setDefaultDataView(response.data.data.asset_details.data);

                setpoId(response.data.data.asset_details._id)
                setGeneratePoNumber(response.data.data.asset_details.asset_nomenclature)
                setEditable(response.data.data.should_edit)
                setSupplierId('')
                setTemplateId(response.data.data.asset_details.templateId)
                setVersionNumbers(response.data.data.asset_versions)
                setDefaultVersion(response.data.data.asset_details.asset_version)
                setEcApprovalStatus(response.data.data.asset_details.asset_po_status)
                setMemberApproved(response.data.data.have_approved)
                setApprovalMemberView(response.data.data.should_approve);
                setShouldRevert(response.data.data.should_revert)
                setSelectedAddress(response.data.data.asset_details.data.company_address)
                setSelectedDepartment(response.data.data.asset_details.data.department_name)
                setSelectedSupplierAddress(response.data.data.asset_details.data.supplier_address)

            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    const fetchOrganizationDetails = async () => {
        try {
            // setLoading(true);
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/setting/get-business-details", { headers });

            setOrgDetails(response.data.data.business_details)


        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    const fetchPartsGrnDetails = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const response = await axios.get(`${BASE_URL}/v1/goods-receipts/get-grn-details`, { headers, params: { id: sequence_id.sequence_id } });
            setDefaultDataView(response.data.data.grn_details);

            setGrnId(response.data.data.grn_details.grn_sequence_id)
            setPOId(response.data.data.grn_details.purchase_order_number)
            setLoading(false);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    const handleSelectChange = (selectedOption) => {

        getSupplierDetails(selectedOption.value);

        setSupplierAddressDisable(true)
    };

    const handleBack = () => {
        if (poType === 'view' || poType === 'create') {
            nav('/all-po-list');
        } if (poType === 'assets' || poType === 'assets-view') {
            nav('/assets-list');
        } else if (poType === 'parts-grn-view') {
            nav('/all-grn-parts');
        }
    }


    const handleSubmit = async () => {


        if (Object.keys(fieldErrors).every(key => fieldErrors[key] === '') && selectedAddresses && selectedDepartment
            && selectedSupplierAddresses && Object.keys(errors).every(key => errors[key] === '')) {


            try {
                const headers = {
                    "x-auth-token": Cookies.get("token"),
                };
                const statusData = {
                    "status": {
                        "value": "Created",
                        "is_default": true,
                        "inputType": "single_select",
                        "fieldName": "Status",
                        "options": [
                            "Created",
                            "Placed",
                            "Closed"
                        ],
                        "order": 10
                    }
                };


                const payload = {
                    tableDetails,
                    defaultFields: { ...defaultFields, ...statusData, }, // Adding statusData to defaultFields
                    supplierDetails,
                    customDetails,
                    editor_text: editorText ? editorText : label.find(item => item.template_type === 'tandc')?.value,
                    fixed_variable_values: selectedValues,
                    company_address: selectedAddresses,
                    department_name: selectedDepartment,
                    supplier_address: selectedSupplierAddresses,
                    supplier_id: supplierId,
                };
                console.log(payload)
                // console.log("Payload:", payload);
                let response;
                if (poType === 'create') {
                    response = await axios.post(
                        `${BASE_URL}/v1/purchase-order/create-po`,
                        {
                            data: JSON.stringify(payload),
                            templateId,
                            selected_version: 0,
                            po_status: '',
                            fixed_variable_values: selectedValues
                        },
                        { headers: headers }
                    );
                } else if (poType === 'assets') {
                    response = await axios.post(
                        `${BASE_URL}/v1/asset/create-asset`,
                        {
                            data: JSON.stringify(payload),
                            templateId,
                            selected_version: 0,
                            po_status: '',
                            fixed_variable_values: selectedValues
                        },
                        { headers: headers }
                    );
                }

                let url;
                if (poType === 'create') {
                    url = `/po-view/${response.data.data.po_id}`;
                } else {
                    url = `/po-asset-view/${response.data.data.asset_id}`;
                }
                console.log(url)

                window.location.href = url;

                console.log("Response:", response);
            } catch (error) {
                console.error("Error saving data:", error);
            }
        }

    };



    const handleUpdateData = async () => {
        // console.log(editedData)
        const updatedData = { ...editedData };

        // Update the status field within the value array of the approver
        updatedData.approver.value = updatedData.approver.value.map(person => {
            return { ...person, status: 'Create' };
        });



        //     console.log("Updated defaultDataView:", editedData);
        if (!disableUpdate) {
            console.log('make changes')
        } else {
            try {
                const headers = {
                    "x-auth-token": Cookies.get("token"),
                };
                const payload = {
                    tableDetails: editedtableData,
                    defaultFields: editedData,
                    supplierDetails: defaultDataView.supplierDetails,
                    customDetails: customEditedData,
                    editor_text: editedValue,
                    fixed_variable_values: defaultDataView.fixed_variable_values,
                    company_address: selectedAddresses ? selectedAddresses : defaultDataView.company_address,
                    department_name: selectedDepartment ? selectedDepartment : defaultDataView.department_name,
                    supplier_address: selectedSupplierAddresses,
                    supplier_id:supplierId

                };
                // console.log(payload)
                let response;
                if (poType === 'view') {
                    response = await axios.post(
                        `${BASE_URL}/v1/purchase-order/create-po`,
                        {

                            data: JSON.stringify(payload), templateId, poNomenclature: generatePoNumber, fixed_variable_values: selectedValues,
                            selected_version: selectedVersion ? selectedVersion : defaultVersion, po_name: sequence_id.sequence_id,
                            po_status: 'Create'
                        },
                        { headers: headers }
                    );



                } else {
                    response = await axios.post(
                        `${BASE_URL}/v1/asset/create-asset`,
                        {

                            data: JSON.stringify(payload), templateId, poNomenclature: generatePoNumber, fixed_variable_values: selectedValues,
                            selected_version: selectedVersion ? selectedVersion : defaultVersion, asset_name: sequence_id.sequence_id,
                            asset_po_status: ecApprovalStatus
                        },
                        { headers: headers }
                    );



                }

              setUpdatedData(payload)

                // console.log(response);
            } catch (error) {
                console.error("Error saving data:", error);
            }
        }

        //     console.log("custom details",customEditedData)
        // console.log("Updated editorText:", editedValue);
        // console.log('tabledata',editedtableData)

    }




    const makeApprove = async () => {
        if (ecApprovalStatus === '') {
 
            toast.info("PO havent sent for approve");


            
        }
        else {
            try {

                const headers = {
                    'x-auth-token': Cookies.get('token'),
                };

                if (poType === 'view') {
                    await axios.post(`${BASE_URL}/v1/purchase-order/make-approve`, {
                        po_name: sequence_id.sequence_id,
                        po_id: PoId,
                    }, { headers: headers });
                } else if (poType === 'assets-view') {
                    await axios.post(`${BASE_URL}/v1/asset/make-approve`, {
                        asset_name: sequence_id.sequence_id,
                        po_id: PoId,
                    }, { headers: headers });
                }

                
                setMemberApproved('Approved')
                setUpdatedData('approved')
            } catch (error) {
                console.log(error);
            }
        }



    }

    const handleRevert = async () => {

        try {
            const headers = {
                'x-auth-token': Cookies.get('token'),
            };


            if (poType === 'view') {
                await axios.post(`${BASE_URL}/v1/purchase-order/revert-po`,
                    { po_name: sequence_id.sequence_id, po_id: PoId, }, { headers: headers });
                setMemberApproved('Create')
            } else if (poType === 'assets-view') {
                await axios.post(`${BASE_URL}/v1/asset/revert-asset`,
                    { asset_name: sequence_id.sequence_id, po_id: PoId, }, { headers: headers });
                setMemberApproved('Create')
            }
            setUpdatedData('updatted-revert')
            //   fetchFormDetails()
            // window.location.reload()

        } catch (error) {
            console.log(error)
        }
    }

    const handleOpenApproveList = (action) => {

        if (action === 'revert') {
            handleRevert()
            //   getApprovals()
           setUpdatedData('updated revert')
        } if (action === 'reject') {
            setOpenApproveList(action)
        } if (action === 'approvelist') {
            setOpenApproveList(action)
        }
        // getApprovalListMembers()
    }
    const handleOpenApproveListClose = () => {
        // setOpenPartsAdded(false);
        setOpenApproveList(false)
    }
    const handleMouseEnter = (index, comment, updatedAt) => {
        const currentTime = new Date().getTime();
        const updatedAtTime = new Date(updatedAt).getTime();
        const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds

        const memberDetails = JSON.parse(localStorage.getItem("memberDetails"));


        if (editableComment && comment.memberId === memberDetails.id && currentTime - updatedAtTime <= fifteenMinutes) {
            setShowEditButtons((prevButtons) => ({ ...prevButtons, [index]: true }));
        }
    };
    const handleMouseLeave = (index) => {
        setShowEditButtons((prevButtons) => ({ ...prevButtons, [index]: false }));
    };
    const handleEditIconClick = (index) => {
        setEditingIndex(index);
        // setShowEditSaveButtons(true);

        const commentElement = document.getElementById(`comment-${index}`);
        commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };
    const handleEditChange = (e, index) => {
        e.stopPropagation()
        const newComments = [...showComment];
        newComments[index].comments = e.target.value;
        setShowEditSaveButtons(true);
        setShowComment(newComments);
    };
    const handleCancelEdit = () => {
        setShowEditSaveButtons(false);
        setEditingIndex(false);
    };

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);




    const allGRNs = async () => {

        try {

            const headers = {
                "x-auth-token": Cookies.get("token"),
            };

            const response = await axios.get(
                `${BASE_URL}/v1/goods-receipts/get-grn-list`,

                {
                    params: {
                        // page: currentPage,
                        // limit: itemsPerPage,
                        // search: searchTerm,
                        purchase_order_number: sequence_id.sequence_id.toString(),

                    },
                    headers: headers,
                }
            );

            const details = response.data.data.grn_list;

            setGrnList(details)

        } catch (error) {
            console.log(error);

        }
    };
    const toggleDropdown = (e) => {
        e.stopPropagation()
        allGRNs()
        setIsDropdownVisible(!isDropdownVisible);
    };
    // const grnList = [
    //     { code: 'GRN-22021', date: '1 April, 2024' },
    //     { code: 'GRN-22022', date: '1 April, 2024' },
    //     { code: 'GRN-22022', date: '1 April, 2024' },
    // ];

    const handleViewAll = (action) => {
        if (action === 'grn') {
            nav(`/all-grn-parts/${sequence_id.sequence_id}`)
        } else {
            nav('/all-po-list')
        }


    }
    const handleGrnView = (id) => {
        // nav(`/parts-grn-view/${id}`)
        const url = `/parts-grn-view/${id}`;

        // Navigate to the new URL
        window.location.href = url;

    }
    const handlePoView = (id) => {
        const url = `/po-view/${id}`;

        // Navigate to the new URL
        window.location.href = url;
    }

    const handleVersionChange = async (selectedOption) => {

        setSelectedVersion(selectedOption.value);
    }
    const handleAddressChange = async (selectedOption) => {
        console.log(selectedOption)
        setSelectedSupplierAddress(selectedOption.value);

        setInputsDisable(true)
    }


    const handleClosePo = async (sequence_id) => {

        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const payload = {

                po_number: sequence_id,

            };
            const response = await axios.post(
                `${BASE_URL}/v1/goods-receipts/close-po-status`,
                payload,
                { headers: headers }
            );
            setUpdatedData(payload)
        } catch (error) {
            console.log(error)
        }

    }
    return (
        <>
            {loading ? <Loading /> : <>
                <div className='create-po-page' onClick={handleAllEvents}>
                    <div className='create-po-page-top'>
                        <div className='create-po-title'>
                            <img
                                style={{ cursor: 'pointer' }}
                                src={`${ASSET_PREFIX_URL}template-back-btn.svg`}
                                alt=""
                                width="24px"
                                height="24px"
                                onClick={handleBack}
                            />
                            {(poType === 'create' || poType === 'assets') && <span>Create PO</span>}



                            {poType === 'view' &&
                                <div onClick={handleBack}> <span>Purchase order /</span><span style={{ color: '#610bee' }}> {generatePoNumber}</span></div>
                            }
                            {poType === 'assets-view' && <div onClick={handleBack}> <span>PO /</span><span style={{ color: '#610bee' }}> {generatePoNumber}</span></div>}
                            {poType === 'parts-grn-view' &&
                                <div onClick={handleBack}> <span>GRN /</span><span style={{ color: '#610bee' }}> {grnId}</span></div>
                            }

                        </div>

                        <div className="create-po-btns">
                            {poType === 'parts-grn-view' && (
                                <div className='view-grns-page-div-relative'>
                                    <button className='create-grn-btn-po' onClick={(e) => toggleDropdown(e)}>
                                        View PO <img style={{ width: '20px', height: '20px' }} src={`${ASSET_PREFIX_URL}grn-dropdown.png`} alt='' />
                                    </button>
                                    {isDropdownVisible && (
                                        <div className="view-grns-page-div-absolute" style={{ right: '30px' }}>
                                            {/* {[...new Set(grnList.map(grn => grn.purchase_order_number))].slice(0, 2).map((purchase_order_number, index) => ( */}
                                            <div>
                                                <div className="grn-item" onClick={() => handlePoView(poId)}>
                                                    <span className="grn-code">{poId}</span>
                                                </div>
                                                <div className='horizontal-line-po' style={{ margin: '0px' }}></div>
                                            </div>
                                            {/* ))} */}

                                        </div>
                                    )}
                                </div>
                            )}


                            {(poType === 'view' || poType === 'assets-view') && (
                                <>
                                    <Select
                                        value={{
                                            value: selectedVersion ? `version ${selectedVersion}` : `version ${defaultVersion || 0}`,
                                            label: selectedVersion ? `version ${selectedVersion}` : `version ${defaultVersion || 0}`
                                        }}
                                        styles={versionVustomStyles}
                                        placeholder="choose version"
                                        options={versionNumbers.map((version) => ({
                                            value: poType === 'view' ? version.po_version : version.asset_version,
                                            label: `Version ${poType === 'view' ? version.po_version : version.asset_version}`,
                                        }))}
                                        onChange={(selectedOption) => handleVersionChange(selectedOption)}
                                    />
                                    {(ecApprovalStatus === 'Approved' && (poType === 'view' || poType === 'assets-view') && !disableUpdate) && (
                                        <>
                                            {poType === 'view' && <>
                                                <div className='view-grns-page-div-relative'>
                                                    <button className='create-grn-btn-po' onClick={(e) => toggleDropdown(e)}>
                                                        View GRN <img style={{ width: '20px', height: '20px' }} src={`${ASSET_PREFIX_URL}grn-dropdown.png`} alt='' />
                                                    </button>
                                                    {isDropdownVisible && (
                                                        <div className="view-grns-page-div-absolute">
                                                            {grnList.slice(0, 2).map((grn, index) => (
                                                                <div key={index}>
                                                                    <div className="grn-item" onClick={() => handleGrnView(grn._id)}>
                                                                        <span className="grn-code">{grn.grn_sequence_id}</span>
                                                                        <span className="grn-date">{grn.updatedAt}</span>
                                                                    </div>
                                                                    <div className='horizontal-line-po' style={{ margin: '0px' }}></div>
                                                                </div>
                                                            ))}
                                                            {grnList.length > 2 && (
                                                                <div className="view-all-btn-grn">
                                                                    <span className="view-all-link" onClick={() => handleViewAll('grn')}>View All</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                {editable && <>
                                                    <button className='create-grn-btn-po' onClick={handleOpenCreateGRN}>Create GRN</button>
                                                    <button className="btn close-po" onClick={() => handleClosePo(sequence_id.sequence_id)}>Close PO</button>
                                                </>}
                                            </>}

                                            <button style={{ color: 'white', background: '#610bee' }} onClick={handleDownload}>Download
                                                PO </button>
                                        </>
                                    )}
                                    {disableUpdate && (
                                        <button style={{ color: 'white', background: '#610bee' }} onClick={handleUpdateData}>Update PO</button>
                                    )}
                                </>
                            )}

                            {(poType === 'create' || poType === 'assets') &&

                                (
                                    Object.keys(fieldErrors).every(key => fieldErrors[key] === '') &&
                                        selectedAddresses &&
                                        selectedDepartment &&
                                        selectedSupplierAddresses &&
                                        Object.keys(errors).every(key => errors[key] === '') ? (
                                        <button style={{ color: 'white', background: '#610bee' }} onClick={handleSubmit}>
                                            Create
                                        </button>
                                    ) : (
                                        <button className='no-changes-btn' style={{ color: 'white' }} >
                                            Create
                                        </button>
                                    )

                                )}

                            {(poType === 'view' || poType === 'assets-view') && (
                                <>

                                    <DownloadPo
                                        ref={downloadPoRef}
                                        sequence_id={sequence_id.sequence_id}
                                        orgDetails={orgDetails}
                                        selectedAddresses={selectedAddresses ? selectedAddresses : defaultDataView.company_address}
                                        supplierDetails={defaultDataView.supplierDetails}
                                        editorText={defaultDataView.editor_text}
                                        tableViewData={defaultDataView.tableDetails || []}
                                        defaultData={defaultDataView.defaultFields}
                                        customData={defaultDataView.customDetails}
                                        generatePoNumber={generatePoNumber}
                                    />


                                    <div className="ticket-view-left">
                                        <HistoryOutlinedIcon
                                            onClick={handleOpenPopup}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                    {(poType === 'view' || poType === 'assets-view') && (
                        <div className="ticket-view-status-div" style={{ width: '50%' }}>
                            <div className="ticket-view-status-bar">
                                <div className="ticket-view-status-name">
                                    <span>Created</span>
                                    <span>Pending for approval</span>
                                    <span>Approved</span>
                                </div>
                                <div className="ticket-view-status-name" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                    <div className="status-circle" style={{ background: '#610bee' }}></div>
                                    <div className="status-line" style={{
                                        background: (ecApprovalStatus === 'Pending for approval' || ecApprovalStatus === 'Approved' ||
                                            memberApproved === 'Pending for approval') ? '#610bee' : ''
                                    }}></div>
                                    <div className="status-circle" style={{
                                        background: (ecApprovalStatus === 'Pending for approval' || ecApprovalStatus === 'Approved' ||
                                            memberApproved === 'Pending for approval') ? '#610bee' : ''
                                    }}></div>
                                    <div className="status-line" style={{ background: (ecApprovalStatus === 'Approved') ? '#610bee' : '' }}></div>
                                    <div className="status-circle" style={{ background: (ecApprovalStatus === 'Approved') ? '#610bee' : '' }}></div>
                                </div>
                            </div>
                            <div className="ticket-view-status-buttons">
                                {approvalMemberView === true ? (
                                    <>
                                        {(memberApproved === 'Approved') ? '' :
                                            <>
                                                {(editable && (ecApprovalStatus === 'Create')) && <button className="ticket-view-status-btn-approve" onClick={() => handleOpenApproveList('approvelist')}>Send for approval</button>}&nbsp;&nbsp;
                                                {(editable && ecApprovalStatus === 'Pending for approval' && shouldRevert) && <button className="ticket-view-status-btn-approve" onClick={() => handleOpenApproveList('revert')}>Revert to draft</button>}&nbsp;&nbsp;
                                                {ecApprovalStatus === 'Pending for approval' && <button className="ticket-view-status-btn-approve" onClick={makeApprove}>Approve</button>} &nbsp;&nbsp;

                                                {(ecApprovalStatus !== 'Create') ? <button className="ticket-view-status-btn-reject" onClick={() => handleOpenApproveList('reject')}>Reject</button> : ''}
                                            </>
                                        }
                                    </>
                                ) : (
                                    (!editable || ecApprovalStatus === 'Approved') ? (
                                        null
                                    ) : (
                                        (ecApprovalStatus === 'Pending for approval') ? (
                                            <button className="ticket-view-status-btn-approve" onClick={() => handleOpenApproveList('revert')}>Revert to draft</button>
                                        ) : (
                                            <button className="ticket-view-status-btn-approve" onClick={() => handleOpenApproveList('approvelist')}>Send for approval</button>
                                        )
                                    )
                                )}
                            </div>
                        </div>
                    )}

                    <div className='horizontal-line-po' ></div>
                    <div className='create-po-page-bottom'>
                        {(poType === 'create' || poType === 'assets') && (
                            <div className='create-po-page-bottom-select'>
                                <Select
                                    options={supplierOptions}
                                    styles={customStyles}
                                    placeholder='Select supplier'
                                    onFocus={getSupplierNames}
                                    onChange={handleSelectChange}
                                />
                                {supplierAddressDisable &&
                                    <Select
                                        //  value={{
                                        //     value: selectedSupplierAddresses,
                                        //     label: selectedSupplierAddresses
                                        // }}
                                        styles={customStyles}
                                        placeholder="Choose supplier address"

                                        options={supplierDetailsAddress.map((item) => ({
                                            value: item,
                                            label: item,
                                        }))}
                                        onChange={(selectedOption) => handleAddressChange(selectedOption)}

                                    />}

                            </div>
                        )}

                        <div className='create-po-page-bottom-fileds-cont'>

                            <>
                                {((poType === 'create' || poType === 'assets') && inputDisable) && (
                                    <SupplyFieldsDiv
                                        supplyDetails={supplierDetails}
                                        poType={poType}
                                        inputDisable={inputDisable}
                                        selectedSupplierAddresses={selectedSupplierAddresses}
                                    />
                                )}

                                {(poType === 'view' || poType === 'assets-view') && (
                                    <SupplyFieldsDiv supplyDetails={defaultDataView.supplierDetails} poType={poType}
                                        selectedSupplierAddresses={selectedSupplierAddresses} />
                                )}

                                {poType === 'parts-grn-view' && <SupplyFieldsDiv supplyDetails={defaultDataView.supplier_details} poType={poType} />}

                                {((poType === 'create' || poType === 'assets') && inputDisable && rightSectionItems.filter(item => item.variable_selected_type === 'Fixed').length > 0) && (
                                    <div className='horizontal-line-po'></div>
                                )}
                                {(poType === 'view' || poType === 'assets-view') && defaultDataView.fixed_variable_values && (
                                    <div className='horizontal-line-po'></div>
                                )}

                                {((poType === 'create' || poType === 'assets') && inputDisable) && <PoNamingForm rightSectionItems={rightSectionItems}
                                    selectedValues={selectedValues} setSelectedValues={setSelectedValues} poType={poType} />}
                                {((poType === 'view' || poType === 'assets-view') && defaultDataView.fixed_variable_values &&
                                    <PoNamingView data={defaultDataView.fixed_variable_values} />)}
                                {(poType === 'view' || poType === 'assets-view') && <PoNamingForm rightSectionItems={rightSectionItems}
                                    selectedValues={selectedValues} setSelectedValues={setSelectedValues} poType='view' />}

                                {/* {((poType === 'create' || poType === 'assets') && <div className='horizontal-line-po'></div>
                                )} */}
                                {/* {(poType === 'view' || poType === 'assets-view' || poType === 'parts-grn-view') && (
                                    <div className='horizontal-line-po'></div>
                                )} */}
                                {((poType === 'create' || poType === 'assets') && inputDisable) && <PoTemplateFields
                                    poType={poType}
                                    orgDetails={orgDetails}
                                    setSelectedAddress={setSelectedAddress}
                                    departmentList={departmentList}
                                    setSelectedDepartment={setSelectedDepartment}
                                    selectedDepartment={selectedDepartment}
                                    selectedAddresses={selectedAddresses}
                                    fieldErrors={fieldErrors}
                                    setFieldErrors={setFieldErrors}
                                    mandatoryFields={label}
                                    setIsDropdownOpen={setIsDropdownOpen}
                                    isDropdownOpen={isDropdownOpen}
                                    fieldsType='mandate'
                                    setSelectMembersCond={setSelectMembersCond}
                                 
                                    fieldsData={formData}
                                    setFields={setFormData}
                                    setData={setDefaultFields}
                                    inputDisable={inputDisable}
                                />}

                                {(poType === 'view' || poType === 'assets-view') &&
                                    <PoTemplateFieldsView fieldsType='mandate'
                                        setIsDropdownOpen={setIsDropdownOpen}
                                        editable={editable}
                                        departmentList={departmentList}
                                        setSelectedDepartment={setSelectedDepartment}
                                        selectedDepartment={selectedDepartment}
                                        isDropdownOpen={isDropdownOpen}
                                        orgDetails={orgDetails}
                                        setSelectedAddress={setSelectedAddress}
                                        selectedAddresses={selectedAddresses}
                                        setDisableUpdate={setDisableUpdate} setData={setEditedData} data={defaultDataView.defaultFields} />
                                }
                                {poType === 'parts-grn-view' &&
                                    <PoTemplateFieldsView setDisableUpdate={setDisableUpdate}
                                        setIsDropdownOpen={setIsDropdownOpen}
                                        isDropdownOpen={isDropdownOpen}
                                        departmentList={departmentList}
                                        orgDetails={orgDetails}
                                        editable={editable}
                                        setSelectedAddress={setSelectedAddress}
                                        selectedAddresses={selectedAddresses}
                                        setSelectedDepartment={setSelectedDepartment}
                                        selectedDepartment={selectedDepartment}
                                        fieldsType='mandate' setData={setEditedData} poType={poType} data={defaultDataView.po_default_fields} />}
                                {((poType === 'create' || poType === 'assets') && inputDisable && <div className='horizontal-line-po'></div>
                                )}
                                {(poType === 'view' || poType === 'assets-view' || poType === 'parts-grn-view') && (
                                    <div className='horizontal-line-po'></div>
                                )}
                                {((poType === 'create' || poType === 'assets') && inputDisable) &&
                                    <PoExcelSheet
                                        inputDisable={inputDisable}
                                        errors={errors}
                                        supplierId={supplierId}
                                        setErrors={setErrors}
                                        setDisableUpdate={setDisableUpdate} poType={poType} defaultFields={label} setTableDetails={setTableDetails} />
                                }
                                {(poType === 'view' || poType === 'assets-view') &&
                                    <PoExcellSheetView setDisableUpdate={setDisableUpdate}
                                    supplierId={supplierId}
                                    
                                        editable={editable} poType={poType} setEditedTabledData={setEditedTabledData}
                                        tableViewData={defaultDataView.tableDetails || []} />}
                                {poType === 'parts-grn-view' && <GRNexcellView tableData={defaultDataView.grn_items} />}

                                {/* {((poType === 'create' || poType === 'assets') && inputDisable && !defaultDataView.customDetails && <div className='horizontal-line-po'></div>
                                )} */}
                                {/* {(poType === 'view' || poType === 'assets-view' || poType === 'parts-grn-view') && !defaultDataView.customDetails && (
                                    <div className='horizontal-line-po'></div>
                                )} */}
                                {(poType === 'create' || poType === 'assets') && inputDisable && !defaultDataView.customDetails && (
                                    <PoTemplateFields
                                        setSelectedAddress={setSelectedAddress}
                                        selectedAddresses={selectedAddresses}
                                        inputDisable={inputDisable}
                                        orgDetails={orgDetails}
                                        poType={poType}
                                        fieldsType='custom'
                                        departmentList={departmentList}
                                        setSelectedDepartment={setSelectedDepartment}
                                        selectedDepartment={selectedDepartment}
                                        mandatoryFields={label}
                                        formData={formCustomData}
                                        setFormData={setCustomFormData}
                                        fieldErrors={fieldErrors}
                                        setFieldErrors={setFieldErrors}
                                        setData={setCustomDetals}
                                        setIsDropdownOpen={setIsDropdownOpen}
                                        isDropdownOpen={isDropdownOpen}
                                    />
                                )}

                                {((poType === 'view' || poType === 'assets-view') && defaultDataView.customDetails) && (
                                    <PoTemplateFieldsView
                                        setDisableUpdate={setDisableUpdate}
                                        orgDetails={orgDetails}
                                        setSelectedAddress={setSelectedAddress}
                                        editable={editable}
                                        selectedAddresses={selectedAddresses}
                                        setData={setCustomEditedData}
                                        fieldsType='custom'
                                        departmentList={departmentList}
                                        setSelectedDepartment={setSelectedDepartment}
                                        selectedDepartment={selectedDepartment}
                                        setIsDropdownOpen={setIsDropdownOpen}
                                        isDropdownOpen={isDropdownOpen}
                                        data={defaultDataView.customDetails}
                                        ecApprovalStatus={ecApprovalStatus}
                                    />
                                )}


                                {/* {(poType === 'view'&&(defaultDataView.customDetails!=={})) && <PoTemplateFieldsView setDisableUpdate={setDisableUpdate} setData={setCustomEditedData} fieldsType='custom' data={defaultDataView.customDetails} ecApprovalStatus={ecApprovalStatus} />} */}
                                {poType === 'parts-grn-view' && !defaultDataView.po_custom_fields && (
                                    <PoTemplateFieldsView
                                        setDisableUpdate={setDisableUpdate}
                                        poType={poType}
                                        fieldsType='custom'
                                        orgDetails={orgDetails}
                                        setSelectedAddress={setSelectedAddress}
                                        selectedAddresses={selectedAddresses}
                                        departmentList={departmentList}
                                        setSelectedDepartment={setSelectedDepartment}
                                        selectedDepartment={selectedDepartment}
                                        data={defaultDataView.po_custom_fields}
                                        setData={setCustomEditedData}
                                    />
                                )}

                                {poType === 'parts-grn-view' && <AttachmentsGRN ponumber={sequence_id.sequence_id} data={defaultDataView.attachments} fetchPartsGrnDetails={fetchPartsGrnDetails} />}
                                {((poType === 'create' || poType === 'assets') && inputDisable && <div className='horizontal-line-po'></div>
                                )}
                                {(poType === 'view' || poType === 'assets-view' || poType === 'parts-grn-view') && (
                                    <div className='horizontal-line-po'></div>
                                )}
                                {((poType === 'create' || poType === 'assets') && inputDisable) && <TermsAndConditions
                                    editable={editable}
                                    poType={poType} inputDisable={inputDisable} setEditiorText={setEditiorText} label={label} editorText={editorText} setEditedValue={setEditedValue} setDisableUpdate={setDisableUpdate} />}
                                {(poType === 'view' || poType === 'assets-view') && <TermsAndConditions inputDisable={true} poType={poType} setDisableUpdate={setDisableUpdate} setEditiorText={setEditiorText}
                                    editable={editable} editorText={defaultDataView.editor_text} setEditedValue={setEditedValue}
                                />}
                            </>


                            <div className="get-comments-box" style={{ marginTop: '20px' }}>
                                {showComment.map((comment, index) => (
                                    <div
                                        key={index}
                                        style={{ width: '92%' }}
                                        id={`comment-${index}`}
                                        onMouseEnter={() => handleMouseEnter(index, comment, comment.updatedAt)}
                                        onMouseLeave={() => handleMouseLeave(index)}
                                        className="comment-details-cont"
                                    >
                                        <div className="commented-member-image">
                                            <NameProfile userName={comment.memberName} memberPhoto={comment.memberPhoto} width="36px" fontweight='500' />
                                        </div>
                                        <div className="commented-details">
                                            <div className="commented-member-details">
                                                <div>
                                                    <span className="commenter-name">
                                                        {comment.memberName}
                                                    </span> &nbsp;
                                                    <span className="commented-time">
                                                        {new Date(comment.updatedAt).toLocaleString(
                                                            "en-US",
                                                            {
                                                                year: "numeric",
                                                                month: "short",
                                                                day: "numeric",
                                                                hour: "numeric",
                                                                minute: "numeric",
                                                                hour12: true,
                                                            }
                                                        )}
                                                    </span>
                                                </div>
                                                {(showEditButtons[index]) && (
                                                    <div className="commented-edit-delete-btns">
                                                        <img
                                                            src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                            alt=""
                                                            onClick={() => handleEditIconClick(index)}
                                                            style={{
                                                                cursor: "pointer",
                                                                width: "15px",
                                                                height: "15px",
                                                            }}
                                                        />
                                                        &nbsp;&nbsp;&nbsp;
                                                        {/* <EditOutlinedIcon onClick={editComments} style={{cursor:'pointer', width:'15px' ,height:'15px'}}/> */}
                                                        <img
                                                            src={`${ASSET_PREFIX_URL}delete_comment.png`}
                                                            alt=""
                                                            // onClick={() => deleteComment(comment._id)}
                                                            style={{
                                                                cursor: "pointer",
                                                                width: "15px",
                                                                height: "15px",
                                                            }}
                                                        />
                                                        {/* <CloseIcon  onClick={() =>deleteComment(comment._id)} style={{cursor:'pointer',width:'15px' ,height:'15px'}}/>         */}
                                                    </div>
                                                )}
                                            </div>
                                            {/* <textarea value={comment.comments} className="text-area-type"/> */}
                                            {editingIndex === index ? (
                                                <>
                                                    <textarea
                                                        value={comment.comments}
                                                        onChange={(e) => handleEditChange(e, index)}
                                                        className="text-area-type"
                                                    // onClick={handleSaveEditButtons}
                                                    />
                                                    {showEditSaveButtons && (
                                                        <div className="tckt-tmpt-btns">
                                                            <button
                                                                className="tckt-tmpt-btns-save"
                                                            // onClick={() =>
                                                            //     editComments(comment._id, comment.comments)
                                                            // }
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                className="tckt-tmpt-btns-cnl"
                                                                onClick={handleCancelEdit}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <span className="text-area-type">
                                                    {comment.comments}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            {openApproveList === 'approvelist' && <EcApproveList type={poType}
                approver={label}
                onclose={handleOpenApproveListClose}
                ticketId={PoId}
                setUpdatedData={setUpdatedData}
                sequence_id={sequence_id.sequence_id} />}
            {
                openApproveList === 'reject' && <RejectPopUp
                setUpdatedData={setUpdatedData}
                    onclose={handleOpenApproveListClose}
                    ticketId={PoId}
                    ticketName={sequence_id.sequence_id}
                    notifyIds={[]}
                    sequence_id={sequence_id.sequence_id} type={poType} />
            }
            {IsPopupOpen && <ChangeLogs onClose={handleClosePopup} ticketId={sequence_id.sequence_id} type={poType} />}
            {openCreateGRN && <CreateGRN handleClose={handleOpenCreateGRN} tableViewData={defaultDataView.tableDetails}
                sequence_id={sequence_id.sequence_id} />}
        </>


    );
}

export default PoCreate;
