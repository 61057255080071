import React, { useState, useEffect } from "react";
import "./Edit.css";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import axios from "axios";
import { BASE_URL, ASSET_PREFIX_URL, } from "../../../constants/config";

function EditDoc({ activeNode, setAction,setUpdatedData }) {
  const [department, setDepartment] = useState('');
  // uniqueInitial
  const [uniqueInitial, setUniqueInitial] = useState('');
  const [description, setDescription] = useState('');
  const [deptId, setDeptId] = useState('');
  const [close, setClose] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [departments, setDepartments] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setClose(true);
    setAction(false);
  };

  useEffect(() => {
   
      fetchDepartments();
   
  }, []);

  const fetchDepartments = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${BASE_URL}/v1/org/get-exist-department`, {
        headers: {
          'x-auth-token': Cookies.get('token'),
        },
        params: { departmentName: department },
      });
      if (response.data.meta.success) {
        setDepartments(response.data.data.filtered_departments);
      } else {
        setDepartments([]);
      }
      setLoading(false)
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleDepartmentChange = (e) => {
    setDeptId('');
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (regex.test(inputValue)) {
      setDepartment(inputValue);
      setErrorMessage('');
      clearTimeout(typingTimer); 
      setTypingTimer(setTimeout(() => {
        fetchDepartments();
        setOpenSearch(false)
      }, 500));
    } else {
      setErrorMessage('Special characters are not allowed.');
    }
  };
  

  const handleClickRender = (e, dept) => {
    setOpenSearch(true);
    e.stopPropagation();
    setDeptId(dept._id);
    setDepartment(dept.departmentName);
    setDescription(dept.description);
    setUniqueInitial(dept.unique_initial)
    setDepartments([]);
  };

  const HandleDepartment = async () => {
    try {
      if (!department) {
        setErrorMessage('Please enter the department name.');
        return;
      }

      let responseData;

      if (deptId) {
        responseData = await axios.post(
          `${BASE_URL}/v1/org/create-dept`,
          {
            departmentName: department,
            description: description,
            unique_initial:uniqueInitial,
            departId: deptId,
          },
          {
            headers: {
              'x-auth-token': Cookies.get('token'),
            },
          }
        );
      } else {
        responseData = await axios.post(
          `${BASE_URL}/v1/org/create-dept`,
          {
            departmentName: department,
            description: description,
           
          },
          {
            headers: {
              'x-auth-token': Cookies.get('token'),
            },
          }
        );
      }

      if (responseData.data.meta.success === true) {
        const entityId = responseData.data.data.id;

        if (activeNode && activeNode.entity_id) {
          const response = await axios.post(
            `${BASE_URL}/v1/org/update-hierarchy`,
            {
              entity_id: entityId,
              parent_entity_id: activeNode.entity_id,
              is_sibling: true,
              job_title: activeNode.jobTitle,
              entity_type: 'department', 
              action: 'add',
            },
            {
              headers: {
                'x-auth-token': Cookies.get('token'),
              },
            }
          );
        }
        setUpdatedData(department)
        setAction(false)
        // window.location.reload();
      } else if (responseData.data.meta.success === false) {
        setErrorMessage(responseData.data.meta.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="editdoc" style={{ display: close ? 'none' : 'block' }}>
      <div className="docTop">
        <div className="edit-dep">
          <span>Add department</span>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className="edit-name">
          <span>Department name</span>
          <div className="edit-name-loading">
          <input
            placeholder="Enter title"
            value={department}
            onChange={(e) => handleDepartmentChange(e)}
          />
          {loading&&<img className="load-img" src={`https://marathon-web-assets.s3.ap-south-1.amazonaws.com/load-gif.gif`}/>}
          
          </div>
         
          <div className="filtered-departments">
            {departments.map((dept) => (
              <div key={dept._id} className="filtered-departments-list" onClick={(e) => handleClickRender(e, dept)}>
                <span>{dept.departmentName}</span>
              </div>
            ))}
          </div>
          {errorMessage && (
            <div className="department-error">
              <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
              &nbsp;&nbsp;&nbsp;
              {errorMessage}
            </div>
          )}
        </div>

        <div className="edit-title">
          <span>Description (Optional)</span>
          <textarea
            placeholder="Your message..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
      </div>

      <div className="edit-btns">
        {(!department || errorMessage !== ''||loading) ? (
          <button onClick={() => HandleDepartment(false)} className="submit-edit-errorbutton">
            Save
          </button>
        ) : (
          <button onClick={() => HandleDepartment(false)} className="submit-edit-button">
            Save
          </button>
        )}

        <button onClick={handleClose} className="cancel-edit-button">Cancel</button>
      </div>
    </div>
  );
}

export default EditDoc;
