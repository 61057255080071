import React, { useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../../constants/config';
import '../../TicketComponent/TemplateConfirm/TemplateConfirmPopUp.css'

function DeleteConfirmation({ activeNode,setHasChildren,onclose,setUpdatedData }) {
    
    const handleDelete = async() => {
        // if(acti)
         await axios.delete(BASE_URL + "/v1/org/remove-role", {
          headers: {
            "x-auth-token": Cookies.get('token'),
          },
          data: {
            entity_id: activeNode.entity_id,
            // parent_id: activeNode.parent_entity_id,
            remove_type: (activeNode.entity_type === 'department') ? 
              activeNode.departmentName : activeNode.fullName
          },
        });

        // // Handle the response and other logic
           console.log((activeNode.entity_type === 'department')
              ? activeNode.departmentName : activeNode.fullName)
        setHasChildren(false);

        setUpdatedData((activeNode.entity_type === 'department')
        ? activeNode.departmentName : activeNode.fullName)
        onclose()
        // console.log(activeNode.entity_type)
    }

    const handleClose=()=>{
        onclose()
    }
    return (
        <div className='default-popup'>
            <div className='default-popup-container'>
                <div className='closing-div'>
                    <div className='default-closing' onClick={handleClose}>
                        <CloseIcon />
                    </div>
                </div>

                <div className='default-message'>
                    <span>Would you like to remove the <span style={{color:'#610bee'}}>{activeNode.entity_type === 'department'?activeNode.departmentName:activeNode.fullName}</span> from the organization?</span>


                </div>
                <div className='default-btns'>
                    <button onClick={handleClose}>No</button>
                    <button onClick={handleDelete} style={{background:'#610bee',color:'white'}}>Yes</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteConfirmation